// Customizable Area Start
import React from "react";
import {
    styled, Box, Grid, Typography, IconButton, Switch, Button
} from "@mui/material";
import { goBack, like, community } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { MailOutlined, ChatBubbleOutlineOutlined, SentimentSatisfiedOutlined, Share, PersonOutlineOutlined, StarBorderOutlined, NotificationsOutlined } from '@mui/icons-material';
import { handleNavigation, ResolveCondition } from "../../utilities/src/CustomHelpers";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";
const MIN_WINDOW_HEIGHT = 900

import SettingsNotificationsController, {
    Props
} from "./SettingsNotificationsController";

export default class SettingsNotifications extends SettingsNotificationsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        let emailNotifications = this.state.emailNotifications
        let inAppNotifications = this.state.inAppNotifications
        return (
            // Customizable Area Start
            <>
                    <StyledWrapperSetting>
                        <Box display="flex" alignItems="center" mb={3}>
                            <img onClick={this.handleGoBack} src={goBack} alt="go Back" style={{ marginBottom: '20px', marginLeft: "16%", width: "2%" }} />
                            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "1%", fontWeight: "bold", fontFamily: "Nexa-Heavy", fontSize: "30px" }}>
                                Settings - Notifications
                            </Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Box className="main-container">
                                <Box className="inner-container">
                                    <Typography style={{ color: "#fff", fontSize: "24px", lineHeight: '26px', fontFamily: 'Nexa-Heavy' }}>How do you want to be notified?</Typography>
                                    <Box display="flex">
                                        <Box className="left-box">
                                            <Box display="flex" style={{ marginTop: "7%", marginLeft: "2%" }}>
                                                <IconButton><MailOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Email Notifications</Typography>
                                                <Box flexGrow={0} />
                                                <Switch
                                                    checked={emailNotifications.notifications}
                                                    data-test-id="emailNotifications-notifications"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                notifications: !prevState.emailNotifications.notifications,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.notifications}
                                                    name="emailNotificatioins-notifications"
                                                    color="default"
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.notifications, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.notifications, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.notifications, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>

                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <img src={like} style={{ color: "#fff", width: "32px", height: "35px", marginTop: "2%", marginLeft: "2%" }} />
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px", marginLeft: "2%" }}>Likes</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={emailNotifications.likes}
                                                    data-test-id="emailNotifications-likes"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                likes: !prevState.emailNotifications.likes,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.likes}
                                                    name="emailNotifications-likes"
                                                    disabled={!emailNotifications.notifications}
                                                    color="default"
                                                    sx={{
                                                        marginLeft: "30%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.likes, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.likes, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.likes, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><ChatBubbleOutlineOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Comments</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={emailNotifications.comments}
                                                    data-test-id="emailNotifications-comments"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                comments: !prevState.emailNotifications.comments,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.comments}
                                                    name="emailNotifications-comments"
                                                    color="default"
                                                    disabled={!emailNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.comments, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.comments, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.comments, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><SentimentSatisfiedOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Mentions</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={emailNotifications.mentions}
                                                    data-test-id="emailNotifications-mentions"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                mentions: !prevState.emailNotifications.mentions,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.mentions}
                                                    name="emailNotifications-mentions"
                                                    color="default"
                                                    disabled={!emailNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.mentions, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.mentions, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.mentions, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><Share style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Shares</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={emailNotifications.shares}
                                                    data-test-id="emailNotifications-shares"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                shares: !prevState.emailNotifications.shares,
                                                            },
                                                        }));
                                                    }}
                                                    value={true}
                                                    name="emailNotifications-shares"
                                                    color="default"
                                                    disabled={!emailNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.shares, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.shares, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.shares, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><PersonOutlineOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Channel</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={emailNotifications.channel}
                                                    data-test-id="emailNotifications-channel"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                channel: !prevState.emailNotifications.channel,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.channel}
                                                    name="emailNotifications-channel"
                                                    color="default"
                                                    disabled={!emailNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.channel, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.channel, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.channel, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <img src={community} style={{ color: "#fff", width: "32px", height: "35px", marginLeft: "2%", marginTop: "2%" }} />
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px", marginLeft: "2%" }}>Community</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={emailNotifications.community}
                                                    data-test-id="emailNotifications-community"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                community: !prevState.emailNotifications.community,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.community}
                                                    name="emailNotifications-community"
                                                    color="default"
                                                    disabled={!emailNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.community, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.community, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.community, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(emailNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><StarBorderOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Recomendations</Typography>
                                                <Box flexGrow={0.5} />
                                                <Switch
                                                    checked={emailNotifications.recomendations}
                                                    data-test-id="emailNotifications-recomendations"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            emailNotifications: {
                                                                ...prevState.emailNotifications,
                                                                recomendations: !prevState.emailNotifications.recomendations,
                                                            },
                                                        }));
                                                    }}
                                                    value={emailNotifications.recomendations}
                                                    name="emailNotifications-recomendations"
                                                    color="default"
                                                    disabled={!emailNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "29%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(emailNotifications.recomendations, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(emailNotifications.recomendations, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!emailNotifications.recomendations, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box className="right-box">
                                            <Box display="flex" style={{ marginTop: "7%", marginLeft: "2%" }}>
                                                <IconButton><NotificationsOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px", whiteSpace: "nowrap" }}>In-App Notifications</Typography>
                                                <Box flexGrow={0} />
                                                <Switch
                                                    checked={inAppNotifications.notifications}
                                                    data-test-id="inAppNotifications-notifications"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                notifications: !prevState.inAppNotifications.notifications,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.notifications}
                                                    name="inAppNotifications-notifications"
                                                    color="default"
                                                    sx={{
                                                        marginLeft: "22%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.notifications, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.notifications, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.notifications, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <img src={like} style={{ color: "#fff", width: "32px", height: "35px", marginTop: "2%", marginLeft: "2%" }} />
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px", marginLeft: "2%" }}>Likes</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={inAppNotifications.likes}
                                                    data-test-id="inAppNotifications-likes"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                likes: !prevState.inAppNotifications.likes,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.likes}
                                                    name="inAppNotifications-likes"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "30%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.likes, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.likes, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.likes, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><ChatBubbleOutlineOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Comments</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={inAppNotifications.comments}
                                                    data-test-id="inAppNotifications-comments"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                comments: !prevState.inAppNotifications.comments,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.comments}
                                                    name="inAppNotifications-comments"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.comments, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.comments, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.comments, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><SentimentSatisfiedOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Mentions</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={inAppNotifications.mentions}
                                                    data-test-id="inAppNotifications-mentions"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                mentions: !prevState.inAppNotifications.mentions,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.mentions}
                                                    name="inAppNotifications-mentions"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.mentions, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.mentions, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.mentions, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><Share style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Shares</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={inAppNotifications.shares}
                                                    data-test-id="inAppNotifications-shares"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                shares: !prevState.inAppNotifications.shares,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.shares}
                                                    name="inAppNotifications-shares"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.shares, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.shares, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.shares, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><PersonOutlineOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Channel</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={inAppNotifications.channel}
                                                    data-test-id="inAppNotifications-channel"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                channel: !prevState.inAppNotifications.channel,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.channel}
                                                    name="inAppNotifications-channel"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.channel, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.channel, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.channel, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <img src={community} style={{ color: "#fff", width: "32px", height: "35px", marginLeft: "2%", marginTop: "2%" }} />
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px", marginLeft: "2%" }}>Community</Typography>
                                                <Box flexGrow={0.9} />
                                                <Switch
                                                    checked={inAppNotifications.community}
                                                    data-test-id="inAppNotifications-community"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                community: !prevState.inAppNotifications.community,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.community}
                                                    name="inAppNotifications-community"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "25%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.community, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.community, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.community, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box display="flex" style={{ marginTop: "1%", marginLeft: "2%", opacity: ResolveCondition(inAppNotifications.notifications, "1", "0.5") }}>
                                                <IconButton><StarBorderOutlined style={{ color: "#fff", width: "32px", height: "35px" }} /></IconButton>
                                                <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginTop: "4%", fontSize: "16px", lineHeight: "26px" }}>Recomendations</Typography>
                                                <Box flexGrow={0.5} />
                                                <Switch
                                                    checked={inAppNotifications.recomendations}
                                                    data-test-id="inAppNotifications-recomendations"
                                                    onChange={() => {
                                                        this.setState((prevState) => ({
                                                            inAppNotifications: {
                                                                ...prevState.inAppNotifications,
                                                                recomendations: !prevState.inAppNotifications.recomendations,
                                                            },
                                                        }));
                                                    }}
                                                    value={inAppNotifications.recomendations}
                                                    name="inAppNotifications-recomendations"
                                                    color="default"
                                                    disabled={!inAppNotifications.notifications}
                                                    sx={{
                                                        marginLeft: "29%",
                                                        width: "70px",
                                                        height: "50px",
                                                        marginTop: "2%",
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            transform: "translateX(16px)",
                                                            color: "#fff",
                                                        },
                                                        "& .MuiSwitch-switchBase": {
                                                            color: "#fff",
                                                            position: "absolute",
                                                            top: "6px",
                                                            left: ResolveCondition(inAppNotifications.recomendations, "8px", "8px"),
                                                        },
                                                        "& .MuiSwitch-thumb": {
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundColor: "#fff",
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: ResolveCondition(inAppNotifications.recomendations, "rgb(255, 8, 7)", "#ccc"),
                                                            opacity: ResolveCondition(!inAppNotifications.recomendations, "0.5 ", "1 !important"),
                                                            borderRadius: "16px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Button className="save-changes-btn" data-test-id="save-btn" onClick={() => { handleNavigation('Settings2', this.props) }}>Save Changes</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </StyledWrapperSetting>

                    <div style={{backgroundColor: "#030303"}}>
                        <Footer navigation={this.props.navigation} id={"1"} />
                    </div>
                
            </>
            // Customizable Area End
        );
    }
}

const StyledWrapperSetting = styled("div")({
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .container": {
        backgroundPosition: "center",
        width: "1412px",
        height: "87px",
        padding: "50px 0px 0px 0px",
        flexDirection: "row"
    },
    "& .main-container": {
        width: "72%",
        height: "auto",
        backgroundColor: "#44403C",
        marginLeft: "17%",
        paddingBottom: "2%"
    },
    "& .inner-container": {
        width: "862px",
        height: '608px',
        marginTop: '3%',
        marginBottom: "3%",
        marginLeft: "19%"
    },
    "& .left-box": {
        backgroundColor: "#312F2D",
        width: "380px",
        height: "525px",
        marginTop: "3%",
        borderRadius: "8px"
    },
    "& .right-box": {
        backgroundColor: "#312F2D",
        width: "380px",
        height: "525px",
        marginTop: "3%",
        borderRadius: "8px",
        marginLeft: "12%"
    }, "& .save-changes-btn": {
        backgroundColor: "red",
        color: "#fff",
        borderRadius: "8px",
        textTransform: "capitalize",
        width: "222px",
        height: "32px",
        fontFamily: "Nexa-Heavy",
        marginTop: "6%",
        marginLeft: "37%",
        marginBottom: "3%",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "red",
            color: "#fff",
        }
    }
})
// Customizable Area End
