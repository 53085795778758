import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActivityInterface } from "./dataInterface";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activities: Array<ActivityInterface>;
  fetchedPages: number;
  activityFilter: string;
  token: string;
  accountId: number;
  pickerModalVisibility: boolean;
  currentPage: number;
  currentCommentPage: number;
  currentFollowedPostsPage: number;
  activityFeedData: any;
  allCommentData: any;
  allfollowedPostsData: any;
  allSavedPostData: any;
  showAllDataOfBlock: 'Activity' | 'Comment' | 'Podcast' | 'SavedPosts' |'Allblock';
  showAllActivityFeed: boolean;
  showAllComments: boolean;
  anchorEl: null, 
  allSavedPostAnchorEl: null,
  commentAnchorEl: null, 
  followedPostsAnchorEl: null,
  selectedUser: null, 
  expandedCardId:  number[],
  allSavedPostexpandedCardId:  number[],
  expandedFollowedPostsCardId: number[],
  activeCommenBlockTab: number,
  activeFollowedPostsTab: number,
  activeMonthBlockTab: number,
  totalListeningTime: string;
  mostedListendTime: string;
  averageListeningTime: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ActivityFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActivityApiCallId: string = "";
  getActivityFeedApiCallId: string = "";
  activityTypes: string[] = [
    "BxBlockComments::Comment",
    "BxBlockChat::Chat",
    "BxBlockOrderManagement::Order",
    "BxBlockPosts::Post",
  ];
  itemsPerPage = 4;
  commentItemsPerPage = 2;
  FollowedPostsItemsPerPage = 2;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activities: [],
      fetchedPages: 1,
      activityFilter: "",
      token: "",
      accountId: -1,
      pickerModalVisibility: false,
      currentPage: 0, 
      currentCommentPage: 0,
      currentFollowedPostsPage: 0,
      activityFeedData : [],
      
      allCommentData: [
        {
          id: 1,
          avatar: "https://example.com/avatar1.jpg",
          name: "Johanna Higgins",
          description:
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
          date: "5 Days",
          likes: "1.3K",
          comments: "984",
          shares: "1.2K",
        },
        {
          id: 2,
          avatar: "https://example.com/avatar2.jpg",
          name: "Michael Lee",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          date: "2 Days",
          likes: "1.1K",
          comments: "512",
          shares: "890",
        },
        {
          id: 3,
          avatar: "https://example.com/avatar3.jpg",
          name: "Sophia Carter",
          description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
          date: "7 Days",
          likes: "700",
          comments: "300",
          shares: "500",
        },
        {
          id: 4,
          avatar: "https://example.com/avatar4.jpg",
          name: "James Smith",
          description:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          date: "3 Days",
          likes: "900",
          comments: "400",
          shares: "650",
        },
        {
          id: 5,
          avatar: "https://example.com/avatar1.jpg",
          name: "Johanna ",
          description:
            "Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
          date: "6 Days",
          likes: "1.1K",
          comments: "94",
          shares: "1K",
        },
        {
          id: 6,
          avatar: "https://example.com/avatar1.jpg",
          name: "ohanna ",
          description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
          date: "6 Days",
          likes: "1.1K",
          comments: "94",
          shares: "1K",
        },
      ],
      allfollowedPostsData: [
        {
          id: 3,
          name: "Sophia Carter",
          description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.",
          shares: "223",
          avatar: "https://example.com/avatar3.jpg",
          date: "1 Day",
          likes: "780",
          comments: "432",
        },
        {
          id: 4,
          avatar: "https://example.com/avatar4.jpg",
          description:
          "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur. Nulla facilisi.",
          name: "Michael Brown",
          likes: "320",
          comments: "120",
          shares: "45",
          date: "3 Hours",
        },
        {
          id: 5,
          name: "Olivia Anderson",
          avatar: "https://example.com/avatar5.jpg",
          comments: "890",
          description:
          "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec sollicitudin molestie malesuada.",
          date: "4 Days",
          likes: "1.1K",
          shares: "542",
        },
        {
          id: 6,
          name: "James Wilson",
          avatar: "https://example.com/avatar6.jpg",
          description:
          "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.",
          likes: "3.2K",
          comments: "2.1K",
          shares: "1.4K",
          date: "1 Week",
        },
        {
          id: 7,
          avatar: "https://example.com/avatar7.jpg",
          name: "Emily Martinez",
          description:
            "Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Pellentesque in ipsum id orci porta dapibus.",
          date: "2 Days",
          likes: "590",
          comments: "230",
          shares: "109",
        },
        {
          id: 8,
          avatar: "https://example.com/avatar8.jpg",
          name: "Daniel Taylor",
          description:
            "Aenean euismod elementum nisi, quis eleifend quam adipiscing vitae proin sagittis. Vivamus magna justo, lacinia eget consectetur sed.",
          date: "12 Hours",
          likes: "1.8K",
          comments: "990",
          shares: "1.2K",
        },
        {
          id: 9,
          avatar: "https://example.com/avatar9.jpg",
          name: "Isabella Harris",
          description:
            "Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Nulla porttitor accumsan tincidunt.",
          date: "5 Minutes",
          likes: "110",
          comments: "45",
          shares: "10",
        },
        {
          id: 10,
          avatar: "https://example.com/avatar10.jpg",
          name: "William Lee",
          description:
            "Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Curabitur aliquet quam id dui posuere blandit.",
          date: "3 Weeks",
          likes: "4.5K",
          comments: "3.3K",
          shares: "2.8K",
        },
        {
          id: 11,
          avatar: "https://example.com/avatar11.jpg",
          name: "Ava Johnson",
          description:
            "Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.",
          date: "2 Hours",
          likes: "880",
          comments: "610",
          shares: "430",
        },
        {
          id: 12,
          avatar: "https://example.com/avatar12.jpg",
          name: "Liam Roberts",
          description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Fusce at nisi eget tortor varius vehicula.",
          date: "6 Days",
          likes: "3.7K",
          comments: "2.9K",
          shares: "1.6K",
        }
      ],
      allSavedPostData: [
        {
          id: 1,
          name: "Carter",
          description:
          "Lorem ipsum dolor sit amet. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.",
          shares: "223",
          avatar: "https://example.com/avatar3.jpg",
          date: "1 Day",
          likes: "780",
          comments: "432",
        },
        {
          id: 2,
          avatar: "https://example.com/avatar4.jpg",
          description:
          "Quis autem vel eum iure reprehenderit qnihil molestiae consequatur. Nulla facilisi.",
          name: "Brown",
          likes: "320",
          comments: "120",
          shares: "45",
          date: "3 Hours",
        },
        {
          id: 3,
          name: "nderson",
          avatar: "https://example.com/avatar5.jpg",
          comments: "890",
          description:
          "Curaallis quis ac lectus. Donec sollicitudin molestie malesuada.",
          date: "4 Days",
          likes: "1.1K",
          shares: "542",
        },
        {
          id: 4,
          name: "Wilson",
          avatar: "https://example.com/avatar6.jpg",
          description:
          "Ut enim ad minima veniam, quisoriosam, nisi ut aliquid ex ea commodi consequatur.",
          likes: "3.2K",
          comments: "2.1K",
          shares: "1.4K",
          date: "1 Week",
        },
        {
          id: 5,
          avatar: "https://example.com/avatar7.jpg",
          name: "Martinez",
          description:
            "Elementum sed sit amet dui. Pellentesque in ipsum id orci porta dapibus.",
          date: "2 Days",
          likes: "590",
          comments: "230",
          shares: "109",
        },
        {
          id: 6,
          avatar: "https://example.com/avatar8.jpg",
          name: "Taylor",
          description:
            "Aenean euismod elementum nisis. Vivamus magna justo, lacinia eget consectetur sed.",
          date: "12 Hours",
          likes: "1.8K",
          comments: "990",
          shares: "1.2K",
        },
        {
          id: 7,
          avatar: "https://example.com/avatar9.jpg",
          name: "Harris",
          description:
            "Mauris blandit aliquet elit, ea. Nulla porttitor accumsan tincidunt.",
          date: "5 Minutes",
          likes: "110",
          comments: "45",
          shares: "10",
        },
        {
          id: 8,
          avatar: "https://example.com/avatar10.jpg",
          name: "Lee",
          description:
            "Donec rutrum congue leo eget malesuada. Curabitur aliquet quam id dui posuere blandit.",
          date: "3 Weeks",
          likes: "4.5K",
          comments: "3.3K",
          shares: "2.8K",
        },
      ],
      showAllDataOfBlock: 'Allblock',
      showAllActivityFeed: false,
      showAllComments: false,
      anchorEl: null,
      allSavedPostAnchorEl: null,
      commentAnchorEl: null,
      followedPostsAnchorEl: null,
      selectedUser: null, 
      expandedCardId: [], 
      allSavedPostexpandedCardId: [],
      expandedFollowedPostsCardId: [],
      activeCommenBlockTab: 0,
      activeFollowedPostsTab: 0,
      activeMonthBlockTab: 1,
      totalListeningTime: '86 hours',
      mostedListendTime: '12 hours',
      averageListeningTime: '11 minutes',
      // Customizable Area End
    };
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
    this.getActivityFeedApiCall();
  }

  setFilter(value: string) {
    this.setState(
      { activityFilter: value, fetchedPages: 1, activities: [] },
      () => {
        this.getAcitivityData();
      }
    );
  }

  setModalVisibility(value: boolean) {
    this.setState({ pickerModalVisibility: value });
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getAcitivityData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPoint +
        `?_page=${this.state.fetchedPages}&_limit=10${
          this.state.activityFilter
            ? "&trackable_type=" + this.state.activityFilter
            : ""
        }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleGetActivityFeedApiCallResponse(apiRequestCallId, responseJson)
  }

  getCreatedDate = (dateString:any) => {
    return moment(dateString).fromNow();
  };

  handleGetActivityFeedApiCallResponse = (apiRequestCallId: string, responseJson:any) => {
    if (this.getActivityFeedApiCallId === apiRequestCallId) {


      const activityFeedTransformedData = responseJson.data.map((item:any) => {
        const actionBy = item.attributes.action_by.data.attributes;
        const feedable = item.attributes.feedable.data.attributes;
    
        return {
          id: item.id,
          name: `${actionBy.first_name} ${actionBy.last_name}`,
          action: item.attributes.title,
          time: item.attributes.time,
          avatar: actionBy.profile_picture,
          isYou: true, 
          likes: feedable.liked_post.count,
          comments: feedable.comments.data.length,
          shares: feedable.share_post_count,
          date: this.getCreatedDate(feedable.created_at),
          postImage: feedable.photos[0]?.photot_link
        }
      })
      this.setState({ activityFeedData: activityFeedTransformedData });
    }
  }

  handleCardClick = (cardId: number) => {
    this.setState((prevState) => {
      const isExpanded = prevState.expandedCardId.includes(cardId);
      return {
        expandedCardId: isExpanded
          ? prevState.expandedCardId.filter((id:any) => id !== cardId) 
          : [...prevState.expandedCardId, cardId],
      };
    });
  };

  handleAllSavedCardClick = (savedPostCardId: number) => {
    this.setState((prevState) => {
      const isCardExpanded = prevState.allSavedPostexpandedCardId.includes(savedPostCardId);
      return {
        allSavedPostexpandedCardId: isCardExpanded
          ? prevState.allSavedPostexpandedCardId.filter((id: any) => id !== savedPostCardId)
          : [...prevState.allSavedPostexpandedCardId, savedPostCardId],
      };
    });
  };

  handleSliderNextActivityClick = () => {
    const { currentPage, activityFeedData } = this.state;
    const totalPages = Math.ceil(activityFeedData.length / this.itemsPerPage);
    if (currentPage < totalPages - 1) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handleSliderNextCommetClick = () => {
    const { currentCommentPage, allCommentData } = this.state;
    const totalCommentPages = Math.ceil(allCommentData.length / this.commentItemsPerPage);
    if (currentCommentPage < totalCommentPages - 1) {
      this.setState({ currentCommentPage: currentCommentPage + 1 });
    }
  };

  handleSliderNextFollowedPostsClick = () => {
    const { currentFollowedPostsPage, allfollowedPostsData } = this.state;
    const totalfollowedPostPagess = Math.ceil(allfollowedPostsData.length / this.FollowedPostsItemsPerPage);
    if (currentFollowedPostsPage < totalfollowedPostPagess - 1) {
      this.setState({ currentFollowedPostsPage: currentFollowedPostsPage + 1 });
    }
  };

  handleSliderPreviousActivityClick = () => {
    const { currentPage } = this.state;
    if (currentPage > 0) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleSliderPreviousCommentClick = () => {
    const { currentCommentPage } = this.state;
    if (currentCommentPage > 0) {
      this.setState({ currentCommentPage: currentCommentPage - 1 });
    }
  };

  handleSliderPreviousFollowedPostsClick = () => {
    const { currentFollowedPostsPage } = this.state;
    if (currentFollowedPostsPage > 0) {
      this.setState({ currentFollowedPostsPage: currentFollowedPostsPage - 1 });
    }
  };

  handleDotClick = (index:any) => {
    this.setState({ currentPage: index });
  };

  handleCommentSliderDotClick = (index:any) => {
    this.setState({ currentCommentPage: index });
  }

  handleFolloewdPostsSliderDotClick = (index:any) => {
    this.setState({ currentFollowedPostsPage: index });
  }

  handleShowAllDataOfBlock = (dataBlockName: 'Activity' | 'Comment' | 'Podcast' | 'Allblock') => {
    this.setState({ showAllDataOfBlock: dataBlockName, expandedCardId: [], expandedFollowedPostsCardId: [], allSavedPostexpandedCardId: [] });
  }

  handleShowMoreActivityClick = () => {
    this.setState({ showAllActivityFeed: !this.state.showAllActivityFeed });
  };

  handleShowMoreCommentClick = () => {
    this.setState({ showAllComments: !this.state.showAllComments });
  };

  handleMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ anchorEl: event.currentTarget});
  };
  
  handleAllSavedPostMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ allSavedPostAnchorEl: event.currentTarget});
  };

  handleCommentMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ commentAnchorEl: event.currentTarget});
  };

  handleFollowedPostsMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ followedPostsAnchorEl: event.currentTarget});
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null,});
  };

  handleAllSavedPostMenuClose = () => {
    this.setState({ allSavedPostAnchorEl: null,});
  };

  handleCommentMenuClose = () => {
    this.setState({ commentAnchorEl: null});
  };

  handleFollowedPostsMenuClose = () => {
    this.setState({ followedPostsAnchorEl: null});
  };

  handleOptionClick = () => {
    this.handleMenuClose();
  };

   currentActivityData = () => {
    const startIndex = this.state.currentPage * this.itemsPerPage;
    const activityData = this.state.showAllDataOfBlock==='Activity'
      ? this.state.activityFeedData
      : this.state.activityFeedData.slice(startIndex, startIndex + this.itemsPerPage);
    return activityData;
  }

  currentCommentData = () => {
    const startIndex = this.state.currentCommentPage * this.commentItemsPerPage;
    const commentData = this.state.showAllDataOfBlock==='Comment'
      ? this.state.allCommentData
      : this.state.allCommentData.slice(startIndex, startIndex + this.commentItemsPerPage);
    return commentData;
  }

  currentFollowedPostsData = () => {
    const startPodcastIndex = this.state.currentFollowedPostsPage * this.FollowedPostsItemsPerPage;
    const followedPostsData = this.state.showAllDataOfBlock==='Podcast'
      ? this.state.allfollowedPostsData
      : this.state.allfollowedPostsData.slice(startPodcastIndex, startPodcastIndex + this.FollowedPostsItemsPerPage);
    return followedPostsData;
  }

  handleCommentBlockTabChange = (_event:any ,newValue: any) => {
    this.setState({ activeCommenBlockTab: newValue });
  };

  handleFollowedPostsCardClick = (cardId: number) => {
    this.setState((prevState) => {
      const isExpanded = prevState.expandedFollowedPostsCardId.includes(cardId);
      return {
        expandedFollowedPostsCardId: isExpanded
          ? prevState.expandedFollowedPostsCardId.filter((id:any) => id !== cardId) 
          : [...prevState.expandedFollowedPostsCardId, cardId],
      };
    });
  };

  handleFollowedPostsBlockTabChange = (_event:any,newValue: any) => {
    this.setState({ 
      activeFollowedPostsTab: newValue 
    });
  };

  handleHoursTabChange = (_event:any ,newValue: any) => {
    this.setState({ activeMonthBlockTab: newValue });
  };

  handleSavedPostsBtnClick = () => {
    this.setState({ showAllDataOfBlock: 'SavedPosts' });
  };

  getActivityFeedApiCall = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getActivityFeedApiCallId = requestMessage?.messageId
  
      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getActivityFeedApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  // Customizable Area End
}
