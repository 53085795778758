// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Grid, Button, TextField, FormControl, Select, MenuItem, IconButton
} from "@mui/material";
import { Close } from '@mui/icons-material';
import HostSettingsController, {
    Props,
} from "./HostSettingsController";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { podcastCover, upload, image_group, image_group_1 } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";
import {RenderMenuItems} from "../../utilities/src/RenderMenuItems";
const MIN_WINDOW_HEIGHT = 900


export default class HostSettings extends HostSettingsController {
    constructor(props: Props) {
        super(props);
    }
    validationSchema = Yup.object().shape({
        podcastName: Yup.string().required('Required'),
        hostFirstName: Yup.string().required('Required'),
        hostLastName: Yup.string().required('Required'),
        hostEmail: Yup.string().email('Invalid email address').required('Required'),
        hostLocation: Yup.string().required('Required'),
        podcastLocation: Yup.string().required('Required'),
        podcastSubCategory: Yup.string().required('Required'),
        podcastCategory: Yup.string().required('Required'),
        hosts: Yup.mixed().when('selectedId', {
            is: 2,
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        first_name: Yup.string().required('First Name is required'),
                        last_name: Yup.string().required('Last Name is required'),
                        email_address: Yup.string().email('Invalid email').required('Email is required'),
                        location: Yup.string().required('Location is required'),
                    })
                )
                .max(4, 'Maximum of 4 hosts allowed'),
            otherwise: Yup.array().notRequired()
        })
    });
    render() {
        return (
            <>
                {/* <div style={webStyles.container}> */}
                    <StyleWrapperSetupPodcastProfile>
                        <Box className="mainTitle">Host Settings</Box>
                        <Box >
                            <Box className="redContainer"></Box>
                            <Grid className="container">
                                <Grid className="subContainer">
                                    <Formik
                                        initialValues={this.state.formValues}
                                        validationSchema={this.validationSchema}
                                        validateOnMount={true}
                                        enableReinitialize
                                        onSubmit={(values, FormikHelpers) => {
                                            this.handleSubmit(values, FormikHelpers)

                                        }}
                                    >
                                        {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Grid className="fields">
                                                    <Box style={{ display: "flex", gap: "8%", marginBottom: "-1%" }}>
                                                        <Box>
                                                            <label className="label" style={{ fontSize: "28px", lineHeight: "40px", fontFamily: 'Nexa-Heavy', whiteSpace: "nowrap" }}>Current Podcast Cover:</label>
                                                        </Box>
                                                        <Box>
                                                            <label className="label" style={{ fontSize: "28px", lineHeight: "40px", fontFamily: 'Nexa-Heavy', whiteSpace: "nowrap" }}>New Podcast Cover?</label>                                                         
                                                            {ConditionJsx(this.state.invalidFileMessage !== "",<label className="label" style={{ fontSize: "16px", lineHeight: "22px", whiteSpace: "nowrap", fontFamily: "Nexa-Regular", color: "#FF0807" }}>(Image size must be 3000x3000)</label>,<></>)}
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: "flex", justifyContent: "space-between", gap: "15%" }}>
                                                        <Box sx={{
                                                            marginBottom: "2%",
                                                            height: "229px",
                                                            padding: '20px',
                                                            backgroundColor: 'transparent',
                                                            marginLeft: "-2%"
                                                        }}>
                                                            <img src={values.podcastCover} style={{ width: "240px", height: "227px" }} />
                                                        </Box>
                                                        <Box
                                                            data-test-id="drop"
                                                            onDrop={(e) => { this.handleDrop(e, setFieldValue) }}
                                                            onDragOver={(e: any) => { e.preventDefault() }}
                                                            sx={{
                                                                margin: "auto",
                                                                marginTop: "2%",
                                                                marginBottom: "2%",
                                                                width: "541px",
                                                                height: "229px",
                                                                border: '2px dashed #ccc',
                                                                borderRadius: '10px',
                                                                padding: '20px',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                backgroundColor: 'transparent',
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center',
                                                            }}
                                                        >
                                                            {ConditionJsx(this.state.invalidFileMessage === "" && this.state.imageUrl !== "", <Box sx={{ position: 'relative', width: '100%' }}>
                                                                <img src={this.state.imageUrl} alt="Preview" style={{ width: '230px', height: '225px', borderRadius: '5px', padding: "10px", marginTop: "-4%" }} />
                                                                <IconButton
                                                                    onClick={() => this.setState({ imageUrl: "" })}
                                                                    sx={{ position: 'absolute', top: -15, right: -15, color: '#FFFFFF' }}
                                                                    data-test-id="logo-url"
                                                                >
                                                                    <Close />
                                                                </IconButton>
                                                            </Box>,  <><input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    multiple
                                                                    style={{ display: 'none' }}
                                                                    id="file-input"
                                                                    data-test-id="selectFile"
                                                                    onChange={(e) => { this.handleFileSelect(e, setFieldValue) }}
                                                                />
                                                                    <label htmlFor="file-input">
                                                                        <Box sx={{ marginTop: "10%", lineHeight: 2 }}>
                                                                            <img src={upload} alt="upload" />
                                                                            <Box className="title">
                                                                                Drag & Drop your file here <br />OR
                                                                            </Box>
                                                                            <Button className="btnBrowse" component="span">
                                                                                Browse file
                                                                            </Button>
                                                                        </Box>
                                                                    </label>
                                                                </>)}                                                          
                                                        </Box>
                                                    </Box>

                                                    <Box>
                                                        <label className="label">Podcast Name<span>*</span></label>
                                                    </Box>
                                                    <TextField
                                                        id="outlined-basic"
                                                        name="podcastName"
                                                        value={values.podcastName}
                                                        onChange={handleChange}
                                                        sx={{
                                                            width: "954px",
                                                            marginTop: "2%",
                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                            },
                                                            "& .MuiInputLabel-root": {
                                                                color: "#CBD5E1",
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                color: "#CBD5E1",
                                                                backgroundColor: "black",
                                                                "&:-webkit-autofill": {
                                                                    backgroundColor: "black !important",
                                                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                    WebkitTextFillColor: "#CBD5E1 !important",
                                                                },
                                                            },
                                                        }}
                                                    />                                                    
                                                    <>{console.log("Error-->", errors)}</>

                                                    <Box style={{ marginTop: "3%" }}>
                                                        <label className="label">Subtitle</label>
                                                    </Box>
                                                    <TextField
                                                        id="outlined-basic"
                                                        name="subtitle"
                                                        value={values.subtitle}
                                                        onChange={handleChange}
                                                        sx={{
                                                            width: "954px",
                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                            },
                                                            marginTop: "2%",
                                                            "& .MuiInputLabel-root": {
                                                                color: "#CBD5E1",
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                color: "#CBD5E1",
                                                                backgroundColor: "black",
                                                                "&:-webkit-autofill": {
                                                                    backgroundColor: "black !important",
                                                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                    WebkitTextFillColor: "#CBD5E1 !important",
                                                                },
                                                            },
                                                        }}
                                                    />

                                                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                                        <Box sx={{ width: "48%", marginTop: "3%" }}>
                                                            <label className="label">Host First Name<span>*</span></label>
                                                            <TextField
                                                                id="outlined-basic"
                                                                name="hostFirstName"
                                                                value={values.hostFirstName}
                                                                onChange={handleChange}
                                                                sx={{
                                                                    marginTop: "3%",
                                                                    width: "100%",
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#CBD5E1",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        height: "56px",
                                                                        "& fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        backgroundColor: "black",
                                                                        color: "#CBD5E1",
                                                                        "&:-webkit-autofill": {
                                                                            backgroundColor: "black !important",
                                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box sx={{
                                                            marginTop: "3%",
                                                            width: "48%",
                                                        }}>
                                                            <label className="label">Host Last Name<span>*</span></label>
                                                            <TextField
                                                                name="hostLastName"
                                                                id="outlined-basic"
                                                                value={values.hostLastName}
                                                                onChange={handleChange}
                                                                sx={{
                                                                    width: "100%",
                                                                    marginTop: "3%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        "& fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                        height: "56px",
                                                                        "&:hover fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        color: "#CBD5E1",
                                                                        backgroundColor: "black",
                                                                        "&:-webkit-autofill": {
                                                                            backgroundColor: "black !important",
                                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                                        },
                                                                    },
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#CBD5E1",
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                                        <Box sx={{ width: "48%", marginTop: "3%" }}>
                                                            <label className="label">Host Email Address<span>*</span></label>
                                                            <TextField
                                                                id="outlined-basic"
                                                                name="hostEmail"
                                                                value={values.hostEmail}
                                                                onChange={handleChange}
                                                                sx={{
                                                                    width: "100%",
                                                                    marginTop: "3%",
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#CBD5E1",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        height: "56px",
                                                                        "& fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderColor: "#CBD5E1",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        color: "#CBD5E1",
                                                                        backgroundColor: "black",
                                                                        "&:-webkit-autofill": {
                                                                            backgroundColor: "black !important",
                                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </Box>

                                                        <Box sx={{ width: "48%", marginTop: "3%" }}>
                                                            <label className="label">Host Location<span>*</span></label>
                                                            <FormControl fullWidth variant="outlined"
                                                                sx={{
                                                                    marginTop: "3%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        "& fieldset": {
                                                                            borderColor: "#FFFFFF",
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderColor: "#FFFFFF",
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderColor: "#FFFFFF",
                                                                        },
                                                                        "& .MuiSelect-select": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                    },
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "transparent",
                                                                    },
                                                                    "& .MuiSvgIcon-root": {
                                                                        color: "#FFFFFF",
                                                                    },
                                                                }}>
                                                                <Select
                                                                    name="hostLocation"
                                                                    onChange={(event) => {
                                                                        setFieldValue('hostLocation', event.target.value);
                                                                    }}
                                                                    data-test-id="locationOfHost"
                                                                    value={values.hostLocation}
                                                                    defaultValue=""
                                                                    displayEmpty
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            sx: {
                                                                                overflowY: 'auto',
                                                                                maxHeight: 250,
                                                                                backgroundColor: 'black',
                                                                                "& .Mui-selected": {
                                                                                    backgroundColor: "#333333 !important",
                                                                                },
                                                                                "& .MuiMenuItem-root": {
                                                                                    color: "#FFFFFF",
                                                                                },

                                                                            },
                                                                        },
                                                                    }}
                                                                    sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                                                    IconComponent={(props) => (
                                                                        <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                                    )}
                                                                >                                                                    
                                                                    {RenderMenuItems(this.state.countryDropdown,(item, index) => (
                                                                            <MenuItem value={item.toLowerCase()} key={index} >{item}</MenuItem>
                                                                        ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>

                                                    <Box style={{ marginTop: "3%" }}>
                                                        <label className="label">Host Bio</label>
                                                    </Box>
                                                    <TextField
                                                        id="outlined-basic"
                                                        name="hostBio"
                                                        value={values.hostBio}
                                                        onChange={handleChange}
                                                        multiline
                                                        sx={{
                                                            marginTop: "2%",
                                                            width: "100%",
                                                            height: "103px",
                                                            borderRadius: "8px",
                                                            "& .MuiInputBase-root": {
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "flex-start",
                                                            },
                                                            "& .MuiOutlinedInput-root": {
                                                                height: "103px",
                                                                backgroundColor: "black",
                                                                boxSizing: "border-box",
                                                                alignItems: "flex-start",
                                                                padding: "0",
                                                                "& fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                lineHeight: "1.5",
                                                                color: "#CBD5E1",
                                                                height: "100%",
                                                                maxHeight: "103px",
                                                                boxSizing: "border-box",
                                                                overflowY: "auto",
                                                                padding: "10px",
                                                                display: "block",
                                                                "&:-webkit-autofill": {
                                                                    backgroundColor: "black !important",
                                                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                    WebkitTextFillColor: "#CBD5E1 !important",
                                                                },
                                                            },
                                                        }}
                                                    />

                                                    <Box style={{ marginTop: "3%" }}>
                                                        <label className="label">Location of Podcast (used for episode publish time and data & analytics reporting)</label>
                                                    </Box>
                                                    <FormControl fullWidth variant="outlined"
                                                        sx={{
                                                            marginTop: "1.5%",
                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    borderColor: "#FFFFFF",
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: "#FFFFFF",
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#FFFFFF",
                                                                },
                                                                "& .MuiSelect-select": {
                                                                    color: "#FFFFFF",
                                                                },
                                                            },
                                                            "& .MuiInputLabel-root": {
                                                                color: "transparent",
                                                            },
                                                            "& .MuiSvgIcon-root": {
                                                                color: "#FFFFFF",
                                                            },
                                                        }}>
                                                        <Select
                                                            name="podcastLocation"
                                                            value={values.podcastLocation}
                                                            onChange={(event) => {
                                                                setFieldValue('podcastLocation', event.target.value);
                                                                this.setState({ selectedCountry: event.target.value })
                                                                this.statesApi();
                                                            }}

                                                            data-test-id="podcastlocation"
                                                            defaultValue=""
                                                            displayEmpty
                                                            IconComponent={(props) => (
                                                                <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        overflowY: 'auto',
                                                                        backgroundColor: 'black',
                                                                        maxHeight: 250,
                                                                        "& .MuiMenuItem-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                        "& .Mui-selected": {
                                                                            backgroundColor: "#333333 !important",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                                    >
                                                        {RenderMenuItems(this.state.countryDropdown, (item, index) => (

                                                            <MenuItem value={item.toLowerCase()} key={index} >{item}</MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                    <Grid className="fields">
                                                        <Grid container spacing={2} sx={{ marginTop: "4%" }}>
                                                            <Grid item xs={6}>
                                                                <Box sx={{ marginBottom: "8px" }}>
                                                                    <Typography variant="body2" className="label">State</Typography>
                                                                </Box>
                                                                <FormControl fullWidth variant="outlined"
                                                                    sx={{
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#FFFFFF",
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#FFFFFF",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#FFFFFF",
                                                                            },
                                                                            "& .MuiSelect-select": {
                                                                                color: "#FFFFFF",
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "transparent",
                                                                        },
                                                                        "& .MuiSvgIcon-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                    }}>
                                                                    <Select
                                                                        name="state"
                                                                        onChange={(event) => {
                                                                            setFieldValue('state', event.target.value);
                                                                        }}
                                                                        value={values.state}
                                                                        data-test-id="state"
                                                                        displayEmpty
                                                                        defaultValue=""
                                                                        IconComponent={(props) => (
                                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                                        )}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                sx: {
                                                                                    overflowY: 'auto',
                                                                                    maxHeight: 250,
                                                                                    backgroundColor: 'black',
                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "#333333 !important",
                                                                                    },
                                                                                    "& .MuiMenuItem-root": {
                                                                                        color: "#FFFFFF",
                                                                                    },

                                                                                },
                                                                            },
                                                                        }}
                                                                        sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                                                    >
                                                                    {RenderMenuItems(this.state.statesDropdown, (item, index) => (

                                                                        <MenuItem value={item} key={index} >{item}</MenuItem>
                                                                    ))}                                                                      
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box sx={{ marginBottom: "8px" }}>
                                                                    <Typography variant="body2" className="label">City</Typography>
                                                                </Box>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    name="city"
                                                                    value={values.city}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiOutlinedInput-root": {
                                                                            height: "56px",
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            color: "#CBD5E1",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                                backgroundColor: "black !important",
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid className="fields">
                                                        <Grid container spacing={2} sx={{ marginTop: "4%" }}>
                                                            <Grid item xs={6}>
                                                                <Box sx={{ marginBottom: "8px" }}>
                                                                    <Typography variant="body2" className="label">Podcast Category*</Typography>
                                                                </Box>
                                                                <FormControl fullWidth variant="outlined"

                                                                    sx={{
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#FFFFFF",
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#FFFFFF",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#FFFFFF",
                                                                            },
                                                                            "& .MuiSelect-select": {
                                                                                color: "#FFFFFF",
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "transparent",
                                                                        },
                                                                        "& .MuiSvgIcon-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                    }}>
                                                                    <Select
                                                                        name="podcastCategory"
                                                                        onChange={(event) => {
                                                                            const selectedCategory = this.state.categoryList.find(
                                                                                (item) => item.attributes.name === event.target.value
                                                                            );
                                                                            if (selectedCategory && selectedCategory.id) {
                                                                                setFieldValue('podcastCategory', selectedCategory.id);
                                                                                this.subCategoryApi();
                                                                            }
                                                                            this.setState({ selectedCategory });
                                                                        }}
                                                                        value={this.state.categoryList.find(
                                                                            (item) => item.id === values.podcastCategory
                                                                        )?.attributes.name || ''}

                                                                        data-test-id="category"
                                                                        displayEmpty
                                                                        defaultValue=""
                                                                        IconComponent={(props) => (
                                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                                        )}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                sx: {
                                                                                    overflowY: 'auto',
                                                                                    maxHeight: 250,
                                                                                    backgroundColor: 'black',
                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "#333333 !important",
                                                                                    },
                                                                                    "& .MuiMenuItem-root": {
                                                                                        color: "#FFFFFF",
                                                                                    },

                                                                                },
                                                                            },
                                                                        }}
                                                                        sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                                                    >
                                                                
                                                                        {
                                                                            this.state.categoryList && this.state.categoryList.map((item) => (

                                                                                <MenuItem value={item.attributes.name} key={item.id} >{item.attributes.name}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Box sx={{ marginBottom: "8px" }}>
                                                                    <Typography variant="body2" className="label">Podcast Sub-Category*</Typography>
                                                                </Box>
                                                                <FormControl fullWidth variant="outlined" sx={{
                                                                    "& .MuiOutlinedInput-root": {
                                                                        "& fieldset": {
                                                                            borderColor: "#FFFFFF",

                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderColor: "#FFFFFF",
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderColor: "#FFFFFF",
                                                                        },
                                                                        "& .MuiSelect-select": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                    },
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "transparent",
                                                                    },
                                                                    "& .MuiSvgIcon-root": {
                                                                        color: "#FFFFFF",
                                                                    },
                                                                }}>
                                                                    <Select
                                                                        name="podcastSubCategory"
                                                                        onChange={(e) => {
                                                                            const selectedSubCategory = this.state.subCategoryList.find(
                                                                                (item) => item.attributes.name === e.target.value
                                                                            )
                                                                            if (selectedSubCategory && selectedSubCategory.id) {
                                                                                setFieldValue('podcastSubCategory', selectedSubCategory.id)
                                                                            }
                                                                        }
                                                                        }
                                                                        value={this.state.subCategoryList.find(
                                                                            (item) => String(item.id) === String(values.podcastSubCategory)
                                                                        )?.attributes.name || ''}
                                                                        displayEmpty
                                                                        defaultValue=""
                                                                        data-test-id="subCategory"
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                sx: {
                                                                                    maxHeight: 250,
                                                                                    overflowY: 'auto',
                                                                                    backgroundColor: 'black',
                                                                                    "& .MuiMenuItem-root": {
                                                                                        color: "#FFFFFF",
                                                                                    },
                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "#333333 !important",
                                                                                    },
                                                                                },
                                                                            },
                                                                        }}
                                                                        sx={{ color: "#FFFFFF", backgroundColor: 'black', }}
                                                                        IconComponent={(props) => (
                                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                                        )}
                                                                    >
                                                                        {
                                                                            this.state.subCategoryList && this.state.subCategoryList.map((item) => (

                                                                                <MenuItem value={item.attributes.name} key={item.id}>{item.attributes.name}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid className="boxContainer" id="boxContainer">
                                                        <Grid onClick={
                                                            () => {
                                                                this.handleBoxClick(1)
                                                                setFieldValue('selectedId', 1)
                                                            }
                                                        } data-test-id="click1">
                                                            <Box className="boxContain" id="boxContain">
                                                                <Box className={`boxInside ${ResolveCondition(this.state.selectedId === 1, "boxSelected", "")}`} id="box-inside">
                                                                    <img src={image_group} className="img" alt="ONE-HOST" />
                                                                </Box>
                                                                <div className="label1" >ONE HOST</div>
                                                            </Box>
                                                        </Grid>

                                                        <Grid onClick={() => { this.handleBoxClick(2); setFieldValue('selectedId', 2) }} data-test-id="click2">
                                                            <Box className="boxContain" id="box-contain">
                                                                <Box className={`boxInside ${ResolveCondition(this.state.selectedId === 2, "boxSelected", "")}`} id="inside-box">
                                                                    <img src={image_group_1} className="img" alt="CO-HOSTED" />
                                                                </Box>
                                                                <div className="label1" >CO-HOSTED</div>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

{ConditionJsx(this.state.selectedId === 2,(
                                                        <FieldArray name="hosts" data-test-id="hosts-field">
                                                            {({ push, remove }) => (
                                                                <>
                                                                    {values.hosts.map((host, index) => (
                                                                        <Box key={index} mb={5} className={ResolveCondition(index >= 1, "newHostBg", "")}
                                                                            sx={{ position: 'relative', padding: '20px', width: ResolveCondition(index >= 1, "152%", "100%"), marginLeft: ResolveCondition(index >= 1, "-26%", ""), borderRadius: ResolveCondition(index >= 1, "8px", "") }}>

                                                                            <Box style={{ width: "100%", marginTop: ResolveCondition([0].includes(index), "-8%", "auto") }}>
                                                                                <Box className="heading" style={{ marginLeft: ResolveCondition([1, 2].includes(index), "19%", "0%"), marginBottom: ResolveCondition([1, 2].includes(index), "-3%", "-2%") }}>HOST #{index + 2}</Box>
                                                                                {
                                                                                    ConditionJsx(index > 0, (
                                                                                        <IconButton
                                                                                            data-test-id="iconButton"
                                                                                            onClick={() => {
                                                                                                if (host.id) {
                                                                                                    this.setState((prevState: any) => ({
                                                                                                        deletedHosts: [...prevState.deletedHosts, host.id],
                                                                                                    }));
                                                                                                }
                                                                                                remove(index);
                                                                                            }}
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: 0,
                                                                                                right: 0,
                                                                                                color: 'white',
                                                                                            }}
                                                                                        >
                                                                                            <Close />
                                                                                        </IconButton>
                                                                                    ), <></>)
                                                                                }                                                                              

                                                                                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                                                                    <Box sx={{ width: "48%", marginTop: "3%" }}>
                                                                                        <label className="label" style={{ marginLeft: ResolveCondition([1, 2].includes(index), "39%", "0%") }}>Host First Name<span>*</span></label>
                                                                                        <TextField
                                                                                            id="outlined-basic"
                                                                                            name={`hosts[${index}]first_name`}
                                                                                            onChange={handleChange}
                                                                                            value={host.first_name}
                                                                                            sx={{
                                                                                                marginTop: "2%",
                                                                                                width: ResolveCondition([1, 2].includes(index), "62%", "100%"),
                                                                                                marginLeft: ResolveCondition([1, 2].includes(index), "39%", "0%"),
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    height: "56px",
                                                                                                    "&:hover fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                    "&.Mui-focused fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                    "& fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiInputLabel-root": {
                                                                                                    color: "#CBD5E1",
                                                                                                },
                                                                                                "& .MuiInputBase-input": {
                                                                                                    "&:-webkit-autofill": {
                                                                                                        backgroundColor: "black !important",
                                                                                                        WebkitTextFillColor: "#CBD5E1 !important",
                                                                                                        WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                                    },
                                                                                                    color: "#CBD5E1",
                                                                                                    backgroundColor: "black",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Box>

                                                                                    <Box sx={{
                                                                                        width: "48%",
                                                                                        marginTop: "3%"
                                                                                    }}>
                                                                                        <label className="label">Host Last Name<span>*</span></label>
                                                                                        <TextField
                                                                                            id="outlined-basic"
                                                                                            name={`hosts[${index}]last_name`}
                                                                                            onChange={handleChange}
                                                                                            value={host.last_name}
                                                                                            sx={{
                                                                                                width: "100%",
                                                                                                marginTop: "2%",
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    "& fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                    height: "56px",
                                                                                                    width: ResolveCondition([1, 2].includes(index), "62%", "100%"),
                                                                                                    "&:hover fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                    "&.Mui-focused fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiInputBase-input": {
                                                                                                    color: "#CBD5E1",
                                                                                                    backgroundColor: "black",
                                                                                                    "&:-webkit-autofill": {
                                                                                                        backgroundColor: "black !important",
                                                                                                        WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                                        WebkitTextFillColor: "#CBD5E1 !important",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiInputLabel-root": {
                                                                                                    color: "#CBD5E1",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>

                                                                                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "1%" }}>
                                                                                    <Box sx={{ width: "48%", marginTop: ResolveCondition([0].includes(index), "4%", "2%") }}>
                                                                                        <label className="label" style={{ marginLeft: ResolveCondition([1, 2].includes(index), "39%", "0%") }}>Host Email Address<span>*</span></label>
                                                                                        <TextField
                                                                                            id="outlined-basic"
                                                                                            name={`hosts[${index}]email_address`}
                                                                                            onChange={handleChange}
                                                                                            value={host.email_address}
                                                                                            sx={{
                                                                                                width: ResolveCondition([1, 2].includes(index), "62%", "100%"),
                                                                                                marginLeft: ResolveCondition([1, 2].includes(index), "39%", "0%"),
                                                                                                marginTop: "2%",
                                                                                                "& .MuiInputLabel-root": {
                                                                                                    color: "#CBD5E1",
                                                                                                },
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    height: "56px",
                                                                                                    "& fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                    "&:hover fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                    "&.Mui-focused fieldset": {
                                                                                                        borderColor: "#CBD5E1",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiInputBase-input": {
                                                                                                    color: "#CBD5E1",
                                                                                                    backgroundColor: "black",
                                                                                                    "&:-webkit-autofill": {
                                                                                                        backgroundColor: "black !important",
                                                                                                        WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                                        WebkitTextFillColor: "#CBD5E1 !important",
                                                                                                    },
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Box>

                                                                                    <Box sx={{ width: "48%", marginTop: ResolveCondition([0].includes(index), "4%", "2%") }}>
                                                                                        <label className="label">Host Location<span>*</span></label>
                                                                                        <FormControl fullWidth variant="outlined"
                                                                                            sx={{
                                                                                                marginTop: ResolveCondition([0].includes(index), "2%", "5%"),
                                                                                                marginLeft: ResolveCondition([0].includes(index), "0%", "-16%"),
                                                                                                width: ResolveCondition([1, 2].includes(index), "62%", "100%"),
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    "& fieldset": {
                                                                                                        borderColor: "#FFFFFF",
                                                                                                    },
                                                                                                    "&:hover fieldset": {
                                                                                                        borderColor: "#FFFFFF",
                                                                                                    },
                                                                                                    "&.Mui-focused fieldset": {
                                                                                                        borderColor: "#FFFFFF",
                                                                                                    },
                                                                                                    "& .MuiSelect-select": {
                                                                                                        color: "#FFFFFF",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiInputLabel-root": {
                                                                                                    color: "transparent",
                                                                                                },
                                                                                                "& .MuiSvgIcon-root": {
                                                                                                    color: "#FFFFFF",
                                                                                                },
                                                                                            }}>
                                                                                            <Select
                                                                                                name={`hosts[${index}]location`}
                                                                                                onChange={(event) => {
                                                                                                    setFieldValue(`hosts[${index}]location`, event.target.value);
                                                                                                }}
                                                                                                value={host.location}
                                                                                                data-test-id={`hostslocation[${index}]`}
                                                                                                displayEmpty
                                                                                                defaultValue=""
                                                                                                IconComponent={(props) => (
                                                                                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                                                                )}
                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        sx: {
                                                                                                            overflowY: 'auto',
                                                                                                            maxHeight: 250,
                                                                                                            backgroundColor: 'black',
                                                                                                            "& .Mui-selected": {
                                                                                                                backgroundColor: "#333333 !important",
                                                                                                            },
                                                                                                            "& .MuiMenuItem-root": {
                                                                                                                color: "#FFFFFF",
                                                                                                            },

                                                                                                        },
                                                                                                    },
                                                                                                }}
                                                                                                sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                                                                            >
                                                                                                {
                                                                                                    RenderMenuItems(this.state.countryDropdown,(item, index) => (

                                                                                                        <MenuItem value={item} key={index} >{item}</MenuItem>
                                                                                                    ))
                                                                                                }                                                                                               
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Box>
                                                                                </Box>

                                                                                <Box style={{ marginTop: ResolveCondition([0].includes(index), "3%", "2%") }}>
                                                                                    <label className="label" style={{ marginLeft: ResolveCondition([1, 2].includes(index), "18.8%", "0%") }}>Host Bio</label>
                                                                                </Box>
                                                                                <TextField
                                                                                    id="outlined-basic"
                                                                                    name={`hosts[${index}]host_bio`}
                                                                                    onChange={handleChange}
                                                                                    value={host.host_bio}
                                                                                    multiline
                                                                                    sx={{
                                                                                        width: ResolveCondition([1, 2].includes(index), "63.2%", "100%"),
                                                                                        height: "103px",
                                                                                        marginTop: "1%",
                                                                                        marginBottom: "2%",
                                                                                        marginLeft: ResolveCondition([1, 2].includes(index), "18.8%", "0%"),
                                                                                        borderRadius: "8px",
                                                                                        "& .MuiOutlinedInput-root": {
                                                                                            height: "103px",
                                                                                            backgroundColor: "black",
                                                                                            boxSizing: "border-box",
                                                                                            alignItems: "flex-start",
                                                                                            padding: "0",
                                                                                            "& fieldset": {
                                                                                                borderColor: "#CBD5E1",
                                                                                            },
                                                                                            "&:hover fieldset": {
                                                                                                borderColor: "#CBD5E1",
                                                                                            },
                                                                                            "&.Mui-focused fieldset": {
                                                                                                borderColor: "#CBD5E1",
                                                                                            },
                                                                                        },
                                                                                        "& .MuiInputBase-root": {
                                                                                            height: "100%",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-start",
                                                                                        },
                                                                                        "& .MuiInputBase-input": {
                                                                                            color: "#CBD5E1",
                                                                                            padding: "10px",
                                                                                            lineHeight: "1.5",
                                                                                            height: "100%",
                                                                                            boxSizing: "border-box",
                                                                                            maxHeight: "103px",
                                                                                            overflowY: "auto",
                                                                                            display: "block",
                                                                                            "&:-webkit-autofill": {
                                                                                                backgroundColor: "black !important",
                                                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                <input type="hidden" name={`hosts[${index}].id`} value={host.id} />
                                                                            </Box>
                                                                        </Box>
                                                                    ))}

                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        data-test-id="addHost"
                                                                        fullWidth
                                                                        onClick={() => {
                                                                            push({ first_name: '', last_name: '', email_address: '', location: '' });
                                                                        }}
                                                                        className={ResolveCondition(values.hosts.length >= 3,"hostBtnDisabled","hostBtn")}
                                                                        disabled={values.hosts.length >= 3}
                                                                    >
                                                                        Add Another Host
                                                                    </Button>

                                                                    <Box style={{ textAlign: "center", color: "white", fontFamily: "Nexa-Heavy", marginTop: "-2%" }}>4 maximum total number of hosts</Box>

                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    ),<></>)}                                                  

                                                    <Grid className="fields">
                                                        <Grid item xs={6}>
                                                            <Button
                                                                style={{ color: "white", textTransform: "capitalize" }}
                                                                data-test-id="submit-btn"
                                                                className={ResolveCondition(Object.keys(errors).length > 0, "btnSignUp", "btnSelectedSignUp")}
                                                                disabled={Object.keys(errors).length > 0}
                                                                type="submit"
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>

                                </Grid>
                            </Grid>
                        </Box>
                    </StyleWrapperSetupPodcastProfile>
                    <Footer navigation={this.props.navigation} id={"1"} />
                {/* </div> */}
            </>

        );
    }

}
const StyleWrapperSetupPodcastProfile = styled('div')({
    height: "auto",
    padding:"20px 50px",
    background: "#1C1917",
    "& .subContain": {
        position: "relative",
        paddingRight: "40px",
        height: "47rem",
        overflow: "hidden",
        scrollbarWidth: "thin",
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
    },
    "& .btnSignUp": {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        height: "56px",
        background: "#B2B3B3",
        color: "white",
        margin: "auto",
        display: "flex",
        borderRadius: "8px",
        marginTop: "5%",
        marginBottom: "7%",
        fontWeight: 900,
        width: "578px",
        fontSize: "14px",
    },
    "& *": {
        boxSizing: "border-box",
    },
    "& .field": {
        width: "954px",
        height: "56px",
    },

    "& .btnSelectedSignUp": {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        height: "56px",
        color: "white",
        margin: "auto",
        display: "flex",
        borderRadius: "8px",
        marginTop: "5%",
        marginBottom: "7%",
        fontWeight: 900,
        width: "578px",
        fontSize: "14px",
        background: "#FF0807",
        "&:hover": {
            backgroundColor: "#FF0807",
        }
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textTransform: "capitalize",
        paddingTop: "1%"
    },
    "& .btnBrowse": {
        height: "26px",
        width: "110px",
        fontFamily: "Nexa-Heavy",
        background: "#FF0807",
        color: "white",
        textTransform: "capitalize",
        borderRadius: "8px",
        fontSize: "10px"
    },
    "& .subContainer": {
        display: "flex",
        justifyContent: "center",
        height: "auto",
        width: "64%",
        marginTop: "28px",
    },
    "& .redContainer": {
        height: "80px",
        margin: 0,
        borderRadius: "16px 16px 0px 0px",
        backgroundColor: "#FF0807",
    },
    "& .label": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "14px",
        fontWeight: 700,
    },
    "& .mainTitle": {
        margin: "auto",
        height: "40px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "30px",
        marginBottom: "21px"
    },
    "& .container": {
        height: "auto",
        backgroundColor: "#312F2D",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItens: "center",
        margin: "auto",
        borderRadius: "0px 0px 8px 8px"
    },
    "& .boxSelected": {
        background: "#FF0807 !important"
    },
    "& .boxContain": {
        background: "black",
        height: "289px",
        width: "320px",
        borderRadius: "16px",
        margin: "8px"
    },
    "& .boxContainer": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "6%",
        marginBottom: "8%"
    },
    "& .label1": {
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "18px",
        color: "white",
        textAlign: "center",
        padding: "6% 14%"
    },
    "& .boxInside": {
        background: "#B2B3B3",
        height: "223px",
        width: "320px",
        borderRadius: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .newHostBg": {
        backgroundColor: "#44403C"
    },
    "& .heading": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "24px",
        marginTop: "2%",
        marginBottom: "4%"
    },
    "& .hostBtn": {
        height: "56px",
        width: "392px",
        background: "black",
        color: "white",
        textTransform: "capitalize",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        margin: "auto",
        fontSize: "14px",
        display: " flex",
        marginTop: "15%",
        marginBottom: "3%",
        borderRadius: "8px",
        "&:hover": {
            background: "black",
            color: "white"
        },
    },
    "& .hostBtnDisabled": {
        backgroundColor: "#B2B3B3 !important",
        color: "white !important",
        height: "56px",
        width: "392px",
        margin: "auto",
        fontSize: "14px",
        display: " flex",
        marginTop: "15%",
        marginBottom: "3%",
        textTransform: "capitalize",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        borderRadius: "8px"
    },
})

const webStyles = {
    container: {
        height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
        overflowY: "scroll" as const,
        scrollbarWidth: "thin",
        scrollbarColor: "red gray",
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "red",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "gray",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-button": {
            display: "none",
        },
    } as const
}
// Customizable Area End
