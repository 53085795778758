import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  TextField,
  styled,
  Box,
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
  DialogActions
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { Reply, Delete, Send, CloseOutlined } from '@mui/icons-material';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { goBack, host_logo, user_logo } from "./assets";
// Customizable Area End
import ReplyToController, { Props } from "./ReplyToController";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ReplyTo extends ReplyToController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <StyledWrapperSetting>
          <Box display="flex" alignItems="center" mb={3}>
            <img onClick={this.handleGoBack} src={goBack} alt="Go Back" style={{ marginBottom: '20px', width: "2%", marginLeft: "6%" }} />
            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '15px', marginLeft: "15px", fontWeight: "bold" }}>
              My Inbox
            </Typography>
          </Box>
          <Box sx={{ backgroundColor: "#44403C", paddingTop: '2%', height: "auto", width: "90%", marginLeft: '96px', marginTop: "14px", borderRadius: "8px", paddingBottom: this.state.showReplyBox || this.state.isMessageSent ? "0px" : "20px", marginBottom: ResolveCondition(this.state.showReplyBox, "25%", "4%") }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', backgroundColor: "#312F2D", padding: '10px', borderRadius: "8px", height: "6rem" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6%' }}>
                <Avatar alt="Ana Canal" src={host_logo} sx={{ marginRight: '10px' }} />
                <Box>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="body2" sx={{ color: '#fff', marginBottom: '10px', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>{this.state.sender}</Typography>
                    <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', display: 'flex', alignItems: 'center', marginLeft: '8px', fontFamily: "Nexa-Regular", fontWeight: "bold" }}>
                      {this.state.subject}
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: '#fff', marginLeft: '0%', fontWeight: 'bold', fontFamily: "Nexa-Regular" }}>{this.state.time}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', marginRight: '5%' }}>
                <Button
                  variant="contained"
                  startIcon={<Reply />}
                  sx={{ backgroundColor: '#030303', width: "111px", height: "35px", borderRadius: "8px", marginLeft: "7%", textTransform: "capitalize", '&:hover': { backgroundColor: "#030303" }, fontFamily: "Nexa-Regular", fontWeight: "bold" }}
                  onClick={() => this.handleReply()}
                  data-test-id="reply-button"
                >
                  Reply
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Delete />}
                  onClick={() => this.handleOpenDialog(this.state.messageId)}
                  data-test-id="delete-button1"
                  sx={{ backgroundColor: '#FF0807', width: "111px", height: "35px", borderRadius: "8px", marginLeft: "7%", textTransform: "capitalize", '&:hover': { backgroundColor: "#FF0807" }, fontFamily: "Nexa-Regular", fontWeight: "bold" }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
            <Box sx={{ marginBottom: '20px', height: 'auto' }}>
              <Typography variant="body1" sx={{ color: '#bdbdbd', fontSize: "14px", lineHeight: "26px", margin: '3% 5% 3% 5%', fontFamily: "Nexa-Regular", fontWeight: "bold" }}>
                {this.state.snippet}
              </Typography>
            </Box>
            {this.state.showReplyBox && (
              <Box sx={{ padding: '10px', backgroundColor: "#312F2D", borderRadius: "8px", display: 'flex', flexDirection: 'column', height: "46/*  */%", marginBottom: "4%" }}>
                <Box display="flex" flexDirection="row" gap="8px" style={{ marginLeft: '6%' }}>
                  <Avatar alt="Ana Canal" src={user_logo} sx={{ marginRight: '10px', marginTop: "1%" }} />
                  <Box display="flex" flexDirection="row">
                    <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', marginTop: "-3%", display: 'flex', alignItems: 'center', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                      <IconButton sx={{ padding: '0', marginRight: '4px' }}>
                        <Reply style={{ color: "white" }} />
                      </IconButton>
                      Reply to
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', marginTop: "-3%", display: 'flex', alignItems: 'center', marginLeft: '15px', fontFamily: "Nexa-Regular" }}>
                      {this.state.subject}
                    </Typography>
                  </Box>
                  <Typography sx={{ color: "white", marginLeft: '-13%', marginTop: "3%", textTransform: 'capitalize', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                    {this.state.sender}
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  multiline
                  rows={8}
                  sx={{
                    backgroundColor: "#030303",
                    borderRadius: '8px',
                    flexGrow: 1,
                    margin: '1% 5% 3% 5%',
                    width: "80rem",
                    height: "10rem",
                    "& .MuiInputBase-inputMultiline": {
                      color: '#fff',
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      }
                    }
                  }}
                  onChange={this.handleMessageChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "-11px", paddingRight: "72px" }}>
                  <Button
                    variant="contained"
                    startIcon={<Send />}
                    sx={{
                      backgroundColor: '#FF0807',
                      height: "35px",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      margin: "2%",
                      marginBottom: "4%",
                      '&.Mui-disabled': {
                        backgroundColor: "#B2B3B3",
                        color: "#fff"
                      },
                      '&:hover': {
                        backgroundColor: "#FF0807"
                      },
                      fontFamily: "Nexa-Heavy",
                      fontWeight: "bold"
                    }}
                    disabled={this.state.message.trim() === ''}
                    onClick={() => this.handleSend()}
                    data-test-id="send-button"
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            )}
            {this.state.replies.length > 0 && (
              this.state.replies.map((reply, index) => (
                <Box sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#312F2D', borderRadius: '8px', position: 'relative' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box display="flex" flexDirection="row" gap="8px" sx={{ marginLeft: '6%' }}>
                      <Avatar alt="Ana Canal" src={user_logo} sx={{ marginRight: '10px' }} />
                      <Box>
                        <Box display="flex" flexDirection="row">
                          <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                            <IconButton sx={{ padding: '0', marginRight: '4px' }}>
                              <Reply style={{ color: "white" }} />
                            </IconButton>
                            Reply to
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', display: 'flex', alignItems: 'center', marginLeft: '8px', fontFamily: "Nexa-Regular" }}>
                            {this.state.subject}
                          </Typography>
                        </Box>
                        <Typography sx={{ color: "white", marginLeft: '12%', textTransform: 'capitalize', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                          {this.state.sender}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography variant="body1" sx={{ color: '#bdbdbd', fontWeight: "bold", fontSize: "14px", lineHeight: 1.6, margin: '3% 5%', fontFamily: "Nexa-Regular" }}>
                    {reply.attributes.description}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      position: 'absolute',
                      top: '20px',
                      right: '80px',
                      backgroundColor: '#FF0807',
                      color: '#fff',
                      textTransform: 'none',
                      width: "111px",
                      height: "35px",
                      borderRadius: "8px",
                      fontFamily: "Nexa-Heavy",
                      '&:hover': { backgroundColor: "#FF0807" }
                    }}
                    onClick={() => this.handleOpenDialog(JSON.parse(reply.id))}
                    startIcon={<Delete />}
                    data-test-id={`delete-btn-${index}`}
                  >
                    Delete
                  </Button>
                </Box>
              ))
            )}
          </Box>
        </StyledWrapperSetting>
        <Footer navigation={this.props.navigation} id={"1"} />
        <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} PaperProps={{
          style: {
            borderBottomRightRadius: '30px',
            width: '27%',
            height: '27%',
            margin: '0 auto',
          },
        }}>
          <IconButton
            onClick={this.handleCloseDialog}
            style={{
              color: '#fff',
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            data-test-id="first-dialog-close-icon"
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C" }}>
            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', marginTop: "2%" }} gutterBottom>
              Are you sure you want to delete this message?
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'right', backgroundColor: "#44403C", height: '18%', padding: "5%" }}>
            <Button onClick={this.handleCloseDialog} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="nevermind">
              Nevermind
            </Button>
            <Button onClick={this.handleDelete} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="yes-delete">
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  paddingLeft: "calc(2% + 115px)",
  paddingRight: "5%",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
});

// Customizable Area End
