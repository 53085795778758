import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { micImg, telephoneImg } from "./assets";
import { next, prev } from "../../dashboard/src/assets";

export interface Props {
    navigation: any;
    id: string;
}

interface SS {
    id: any;
}
  
interface S {
  playlistData: Array<{
    imageUrl: string;
    title: string;
    subtitle: string;
    duration: string;
}>;
selectedPlaylist: number | null;
openPlaylistModal: boolean;
selectedPlaylistData: {
  imageUrl: string;
  title: string;
  description: string;
  playListData: Array<{
    imageUrl: string;
    title: string;
    description: string;
    duration: string;
    added: boolean
  }>;
} | null
}

interface ArrowProps {
  onClick: () => void;
  
}

export default class AddPlayListController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
        playlistData: [],
        selectedPlaylist: null,
        openPlaylistModal: false,
        selectedPlaylistData: null
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    // Replace `dataList` with your actual API call or data source
    const dataList = [
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
    ];

    // Update the state with fetched data
    this.setState({ playlistData: dataList });

    // mockdata
    const selectedPlaylistMockData = {
      imageUrl: micImg,
      title: "The True Podcast",
      description: "Cost of success",
      playListData: [
        {
          imageUrl: micImg,
          title: "Calm Waters",
          description: "A soothing instrumental track.",
          duration: "3:45",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Evening Glow",
          description: "Perfect music for an evening stroll.",
          duration: "4:20",
          added: false,
        },
        {
          imageUrl: micImg,
          title: "Midnight Blues",
          description: "A jazzy track for late nights.",
          duration: "5:05",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: false,
        },
      ],
    };

    this.setState({selectedPlaylistData: selectedPlaylistMockData})
    
  }


  getSearchSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
        ]
       }
    )
  }

  prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-6.5%" : "-5.5%",
          top: innerWidth > 1440 ? "55%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

  nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        src={next} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-11.5%" : "-5.5%",
          top: innerWidth > 1440 ? "55%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }

  handlePlaylistRowClick = (index: number) => {
    this.setState({ selectedPlaylist: index });
    this.setState({openPlaylistModal: true})
  }

  handlePlaylistClose = () => {
    this.setState({openPlaylistModal: false})
  }


}
