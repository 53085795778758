// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  responseData: any;
  sponsorIdSelected: any;
  featureSponsor: string[]
}

export interface SS {
  id: any;
}

export default class HostMonetizationSponsoredContentPartnershipController extends BlockComponent<
  Props,
  S,
  SS
> {

  hostSponsoredFeatureApiCallId = "";
  getmemoizationDataApiCallId = "";
  updateSponsoredPartnerMonitizationCallId = "";
  deleteMonetizeCallId = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      responseData: [],
      sponsorIdSelected: [1,2],
      featureSponsor: ["SPONSOR/RECOMMEND CONTENT/CHANNELS ON YOUR CHANNEL", "SPONSOR POSTS WITHIN YOUR COMMUNITY IN YOUR CHANNEL"]
    };
  }

  async receive(from: string, message: Message) {
    if (this.hostSponsoredFeatureApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForFetchSponsoredApi(message);
    }
    if (this.updateSponsoredPartnerMonitizationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateSponsoredApi(message);
    }
    if (this.getmemoizationDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationData(message);
    }
    if (this.deleteMonetizeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationData(message);
    }
  }

  async componentDidMount() {
    this.getmemoizationData()
    this.hostSponsoredFeature()
  }


  getmemoizationData = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationDataApiCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };

  deleteMonetization = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizeCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };

  updateSponsoredPartnerMonitization = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    let monetization_id = await getStorageData("monetization_id")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body = {
      "monetization": {
        "id": monetization_id,
        "podcast_id": prodcastId,
        "sponser_feature_ids": this.state.sponsorIdSelected
      }
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateSponsoredPartnerMonitizationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateMonitization}/${monetization_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  hostSponsoredFeature = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hostSponsoredFeatureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sponserFeature
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
      this.setState({
        sponsorIdSelected :  responseJson.data.attributes.sponser_feature_ids || [],        
       });           
    }
  }; 

  handleResForDeleteMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
      this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };
  handleResForFetchSponsoredApi = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.setState({ responseData: responseJson.data });
    }
  };
  handleResForUpdateSponsoredApi = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      const routeData = this.state.responseData.filter((item:any) => this.state.sponsorIdSelected.map(String).includes(item.id)).map((item:any)=> item.attributes.name)
      setStorageData("sponsorData",JSON.stringify(routeData))
      this.props.handleCommonPath("HostMonetizationTemplate")
    }
  };
 

  handleItemClick = (item: any) => {
    const { sponsorIdSelected } = this.state;
    const itemId = String(item?.id);  
    if (sponsorIdSelected.map(String).includes(itemId)) {       
      this.setState({          
        sponsorIdSelected: sponsorIdSelected.filter(
              (id:any) => String(id) !== itemId
          ),
      });
  } else {
      this.setState({
        sponsorIdSelected: [...sponsorIdSelected, itemId],
      });
  }
  };

}
// Customizable Area End
