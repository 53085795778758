import React, { Component } from "react";
import { Switch, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SwitchProps } from "@mui/material/Switch"; // Import the type for Switch component

interface SwitchButtonProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  theme?: any;
}

class SwitchButton extends Component<SwitchButtonProps> {
  // Explicitly typing IOSSwitch as a styled version of the Switch component
  IOSSwitch: React.ComponentType<SwitchProps> = styled(Switch)<SwitchProps>(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 1,
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "white",
        "& + .MuiSwitch-track": {
          backgroundColor: "red",
          opacity: 1,
          border: 0,
        },
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
    },
  }));

  render() {
    const { checked, onChange } = this.props; // Destructure props
    return (
      <Box
        sx={{
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          padding: "5px 8px",
          
        }}
      >
        {/* Use the styled IOSSwitch component */}
        <this.IOSSwitch
          checked={checked}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
    );
  }
}

export default SwitchButton;
