import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import  { SelectChangeEvent } from '@mui/material/Select';
import { podcastImage1, podcastImage2 } from "./assets";

export interface Props {
  navigation: any;
  screenId: string;
}

interface PodcastList {
    imageUrl: string;
    songName: string;
    artist: string;
    following: string
  }

interface S {
  podcastName: string;
  followingPodCasts: PodcastList[];
}

interface SS {
  screenId: string;
}

export default class PodCastsFollowingController extends BlockComponent<
  Props,
  S,
  SS
> {
    podcastFollowingId : string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      podcastName: "Podcasts I'm Following",
      followingPodCasts : [
        {
          songName: 'TOXIC',
          imageUrl: podcastImage1, 
          artist: "Richard Donner",
          following: "following"
        },
        {
          songName: 'TOXIC',
          imageUrl: podcastImage2, 
          artist: "Richard Donner",
          following: "following"
        },
        {
          songName: 'TOXIC',
          imageUrl: podcastImage2, 
          following: "following",
          artist: "Richard Donner",
        },
        {
          following: "following",
          imageUrl: podcastImage1, 
          songName: 'TOXIC',
          artist: "Richard Donner",
        },
        {
          songName: 'TOXIC',
          imageUrl: podcastImage2, 
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage1, 
          artist: "Richard Donner",
          songName: 'TOXIC',
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          artist: "Richard Donner",
          songName: 'TOXIC',
          following: "following"
        },
        {
          imageUrl: podcastImage1, 
          artist: "Richard Donner",
          songName: 'TOXIC',
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          artist: "Richard Donner",
          songName: 'TOXIC',
          following: "following"
        },
        {
          imageUrl: podcastImage1, 
          artist: "Richard Donner",
          songName: 'TOXIC',
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          artist: "Richard Donner",
          songName: 'TOXIC',
          following: "following"
        }
      ],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleChange = (event: SelectChangeEvent<string | number>) => {
    this.setState({ podcastName: event.target.value as string });
  };

}