import React, { Component } from 'react';
import {
  Box,
  Card,
  Typography,
  Avatar,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  Collapse,
} from "@mui/material";


import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BlockIcon from '@mui/icons-material/Block';
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

interface ActivityFeedCardProps {
  currentActivityData: any[];
  expandedCardId: number[];
  anchorEl: HTMLElement | null;
  handleCardClick: (id: number) => void;
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
}

class ActivityFeedCard extends Component<ActivityFeedCardProps> {
  constructor(props: ActivityFeedCardProps) {
    super(props);
  }
  
  render(): JSX.Element  {
    const { currentActivityData, expandedCardId, anchorEl, handleCardClick, handleMenuOpen, handleMenuClose } = this.props;

    return (
      <Box width={"100%"}>
      {currentActivityData.map((activity, index) => (
        <React.Fragment key={activity.id}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: index % 2 === 0 ? "#44403C" : "#312F2D",
              marginBottom: 1,
              borderRadius: "20px",
              padding: 1,
              height: 100,
              justifyContent: "space-between",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => handleCardClick(activity.id)}
          >
            <Box display={"flex"} justifyContent={"start"} sx={{ width: "100%" }} alignItems={"center"}>
              <Avatar
                src={activity.avatar}
                alt={activity.name}
                style={{
                  marginLeft: "3%",
                  marginRight: "3%",
                  width: "56px",
                  height: "56px",
                }}
              />
              <Typography
                variant="body1"
                fontWeight={900}
                style={{
                  marginRight: "3%",
                  maxWidth: "200px",
                  width: "100%",
                  fontSize: "14px",
                  fontFamily: "Nexa-Heavy",
                  color: "#fff",
                }}
              >
                {activity.name}
              </Typography>
              <Box sx={{ width: "100%", textAlign: "left" }}>
                <Typography
                  variant="body2"
                  color="gray"
                  textAlign={"left"}
                  fontSize={"14px"}
                  fontFamily={"Nexa-Regular"}
                  fontWeight={100}
                >
                  {activity.action}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"end"} sx={{ width: "100%" }} alignItems={"center"}>
              <Typography
                variant="caption"
                color="gray"
                sx={{
                  marginRight: 2,
                  fontFamily: "Nexa-Regular",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#fff",
                }}
              >
                {activity.time}
              </Typography>
              <IconButton size="small" sx={{ color: "#fff" }} onClick={(e) => handleMenuOpen(e)}>
                <MoreHorizIcon />
              </IconButton>
            </Box>
          </Card>

          <Collapse
            in={expandedCardId.includes(activity.id)}
            timeout="auto"
            unmountOnExit
            sx={{ mt: "-22px", mb: "12px" }}
          >
            <Box
              sx={{
                justifyContent: "center",
                backgroundColor: index % 2 === 0 ? "#44403C" : "#312F2D",
                display: "flex",
                flexDirection: "column",
                padding: 2,
              }}
            >
            <Box sx={{textAlign: "center"}}>
              <img
                src={activity.postImage}
                style={{
                  width: "729.83px",
                  height: "308px",
                  borderRadius: "43px",
                }}
              />
            </Box>

              <Box
                style={style.typographyText}
                alignItems={"center"}
                margin={"50px 0 20px 50px"}
                gap={"40px"}
                display={"flex"}
              >
                <Typography style={style.typographyText} variant="body2" >{activity.date}</Typography>
                <Box sx={{display: "flex",alignItems: "center"}}>
                  <ThumbUpAltOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="body2" style={style.typographyText}>{activity.likes}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ChatBubbleOutlineOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="body2" style={style.typographyText}>{activity.comments}</Typography>
                </Box>
                <Box sx={{ alignItems: "center" , display: "flex", }}>
                  <ShareOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography variant="body2" style={style.typographyText}>{activity.shares}</Typography>
                </Box>
              </Box>
            </Box>
          </Collapse>

          <Menu
            onClose={handleMenuClose}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#44403C",
                color: "#FFFFFF",
                border: "1px solid #ffffff",
                width: "200px",
              },
            }}
          >
            <Box sx={{
              "&:hover": {
                backgroundColor: "#FF0807",
              },
              marginTop: "-8px",
            }}>
              <MenuItem
                sx={{ height: "60px" }}
                onClick={handleMenuClose}
              >
                <ListItemIcon>
                  <VisibilityOffIcon style={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                <span style={{ fontSize: "14px", marginLeft: "8px"}}> Hide </span>
              </MenuItem>
            </Box>
            <Divider sx={{ borderColor: "#ffffff" }} />
            <Box sx={{
              "&:hover": {
                backgroundColor: "#FF0807",
              },
              marginBottom: "-8px",
            }}>
              <MenuItem
                onClick={handleMenuClose}
                sx={{ height: "60px" }}
              >
                <ListItemIcon>
                  <BlockIcon style={{ color: "#FFFFFF", fontSize: "36px" }} />
                </ListItemIcon>
                <span style={{  fontSize: "14px", marginLeft: "8px" }}>Block User</span>
              </MenuItem>
            </Box>
          </Menu>
        </React.Fragment>
      ))}
    </Box>
    );
  }
}

const style = {
  typographyText: {
      fontWeight: 900,
      fontSize: "14px",
      fontFamily: "Nexa-Heavy",
      color: "#fff",
      cursor: "pointer"
  }
}

export default ActivityFeedCard;
