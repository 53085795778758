Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Playlist";
exports.labelBodyText = "Playlist Body";
exports.btnExampleTitle = "CLICK ME";
exports.apiContentType="application/json";
exports.apiPostMethod="POST";
exports.apiGetMethod="GET";
exports.apiDeleteMethod="DELETE";
exports.getswaglistApiEndPoint="bx_block_livestreaming/swag_shots";
exports.createlike="bx_block_likeapost/likes";
exports.deletelike="bx_block_likeapost/likes/";
exports.createfollow="bx_block_followers/follows";
exports.unfollow="bx_block_followers/follows/";
exports.sendmessage="bx_block_comments/comments";
exports.save="bx_block_livestreaming/saved_swag_shots/";
exports.userlist="accounts/search_for_share_swagshot";

exports.share="/bx_block_share/shared_medias";
exports.report="bx_block_settings/reports";
exports.hashtagApiEndPoint="bx_block_hashtags/hashtags/search_hashtags";
exports.tagsApiEndPoint= "accounts/search";
exports.cancelApiEndPoint="/bx_block_upload_media/delete_file_from_aws";
exports.songListApiEndPoint="bx_block_playlist/audio_play_lists";
exports.addPlayListApiEndPoint="bx_block_playlist/audio_play_lists?";
exports.playListCreatedListApiEndPoint="/bx_block_playlist/audio_play_lists/display_all_play_list";
exports.getSongCreatedListApiEndPoint="/bx_block_playlist/audio_play/play_created_list?id";
exports.deleteSongApiEndPoint="/bx_block_playlist/audio_play_lists/";
exports.updateSongApiEndPoint="bx_block_playlist/audio_play_lists/";
exports.deleteMethodtype="DELETE"
exports.updateMethodType="PUT"
exports.token="token"
exports.viewText="View"
exports.editText="Edit"
exports.deleteText="delete"
exports.showPlaylistDetails="/bx_block_playlist/audio_play_lists/display_play_list_details?id"
exports.updateType="PATCH"
exports.savePlayListBtn="Save playlist"
exports.updatePlayListBtn="Update playlist"
exports.cancelBtn="Cancel"
exports.viewPlayListBtn="View playlist"
exports.editPlayListBtn="Edit playlist"
exports.listPodCastEpisodeEndPoint="/bx_block_login/episodes"
exports.addSong="bx_block_playlist/audio_play_lists/add_song"

exports.playlist={
  create:'Playlist Created Successfully',
  update:"Playlist Updated Successfully",
  delete:"Playlist Deleted Successfully"
}
// Customizable Area End