import React from "react";
// Customizable Area Start
import { Box, Typography, LinearProgress, styled } from "@mui/material";
import Slider from "react-slick";
import { addBtn, addedBtn, heart, selectedBtn } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";

const MIN_WINDOW_HEIGHT = 900

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start    

    return (
      //Merge Engine DefaultContainer
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />           
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
