import React, { useState, useRef } from "react";
import { Typography, Box, Card } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import { addBtn, heart } from "../assets";

interface EpisodePlayerCardProps {
  cover_image: string | null;
  name: string;
  description: string;
  episode_content: string | null;
  liked: boolean;
  saved: boolean;
}

const EpisodePlayerCard: React.FC<EpisodePlayerCardProps> = ({
  cover_image,
  name,
  description,
  episode_content,
  liked,
  saved,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isVideoContent = (url: string): boolean => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = url.split(".").pop()?.toLowerCase();
    return videoExtensions.includes(extension || "");
  };

  const isAudioContent = (url: string): boolean => {
    const audioExtensions = ["mp3", "wav", "aac"];
    const extension = url.split(".").pop()?.toLowerCase();
    return audioExtensions.includes(extension || "");
  };

  const togglePlayPause = () => {
    if (isVideoContent(episode_content || "")) {
      if (isPlaying) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play();
      }
    } else if (isAudioContent(episode_content || "")) {
      if (isPlaying) {
        audioRef.current?.pause();
      } else {
        audioRef.current?.play();
      }
    }
    setIsPlaying(!isPlaying);
  };

  const renderMediaContent = () => {
    if (!episode_content) {
      return (
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <Typography sx={{ color: "white", textAlign: "center" }}>
            No media available
          </Typography>
        </Box>
      );
    }

    if (isVideoContent(episode_content)) {
      return (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: "16px 16px 0 0",
            overflow: "hidden",
          }}
        >
          <video
            ref={videoRef}
            src={episode_content || ""}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Box
            onClick={togglePlayPause}
            data-test-id="video-play-pause-button"
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              padding: "8px",
              borderRadius: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {isPlaying ? (
              <PauseIcon data-test-id="video-pause-icon" sx={{ fontSize: "40px", color: "white" }} />
            ) : (
              <PlayCircleOutlineIcon data-test-id="video-play-icon" sx={{ fontSize: "40px", color: "white" }} />
            )}
          </Box>
        </Box>
      );
    } else if (isAudioContent(episode_content)) {
      return (
        <Box sx={{ position: "relative" }}>
          <img
            src={cover_image || "default-cover-image.png"} // Default cover image if null
            alt="Audio Cover"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "16px 16px 0 0"
            }}
          />
          <audio ref={audioRef} src={episode_content || ""} />
          <Box
            onClick={togglePlayPause}
            data-test-id="play-pause-button"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "8px",
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isPlaying ? (
              <PauseIcon data-test-id = "pauseIcon" sx={{ fontSize: "40px", color: "white" }} />
            ) : (
              <PlayCircleOutlineIcon data-test-id = "playIcon" sx={{ fontSize: "40px", color: "white" }} />
            )}
          </Box>
        </Box>
      );
    }

    return null;
  };

  return (
    <Card sx={{ ...EpisodePlayerCardStyles.cardContainer }}>
      <Box sx={EpisodePlayerCardStyles.imageContainer}>
        {renderMediaContent()}
        <Box sx={EpisodePlayerCardStyles.actionsContainer}>
          <img data-test-id = "like-button" src={liked ? heart : addBtn} alt={liked ? "Liked" : "Add"} />
          <img data-test-id= "save-button" src={saved ? heart : addBtn} alt={saved ? "Saved" : "Add"} />
        </Box>
      </Box>
      <Box sx={EpisodePlayerCardStyles.detailsContainer}>
        <Typography sx={EpisodePlayerCardStyles.nameText}>{name}</Typography>
        <Typography sx={EpisodePlayerCardStyles.descriptionText}>
          {description}
        </Typography>
      </Box>
    </Card>
  );
};

export default EpisodePlayerCard;

export const EpisodePlayerCardStyles = {
  cardContainer: {
    width: "238px",
    height: "307px",
    display: "flex",
    flexDirection: "column",
    gap: "0.2rem",
    borderRadius: "16px",
    backgroundColor: "#44403C",
    overflow: "hidden",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "231px",
  },
  actionsContainer: {
    position: "absolute",
    top: "15px",
    right: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  detailsContainer: {
    padding: "8px",
    backgroundColor: "#333",
    height: "76px",
  },
  nameText: {
    fontFamily: "Nexa-Heavy",
    fontSize: "12px",
    color: "white",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descriptionText: {
    fontFamily: "Nexa-Heavy",
    fontSize: "10px",
    color: "white",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};
