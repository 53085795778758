export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const imageArt = require("../assets/image_art.png").default;
export const viewAllCategories = require("../assets/viewAllCategory.png").default;
export const dividerImg = require("../assets/dividerImg.png").default;
export const business = require("../assets/business1.png").default;