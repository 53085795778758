// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostSetupMethodController, {
    Props,
} from "./HostSetupMethodController";
import { checkbox, checkboxTicked, hostMethod3, leftImage, rightImage } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
const privacyPolicyLink = "https://www.podcasttown.net/privacy-policy"

export default class HostSetupMethod extends HostSetupMethodController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                    <StyledWrapperHostSetup>
                        <Box className="container">
                            <Box className="upper"></Box>
                            <Box className="title">
                                <Box className="title1">Let's get Started!</Box>
                                <Box className="title1">Choose Your Setup Method Below</Box>
                            </Box>
                            <Box className="tickCheckBox">
                                <img src={ResolveCondition(this.state.checkTick, checkboxTicked, checkbox)} onClick={this.handleTickMark} data-test-id="tickmark" alt="checkbox" />
                                <Box className="checkboxContent">
                                    <Box>
                                        You have to agree with&nbsp;
                                        <span
                                            style={{ color: "#007BFF", cursor: "pointer" }}                                            
                                            data-test-id="privacyPolicy"
                                            onClick={() => this.openLink(privacyPolicyLink)}>
                                            Privacy policy&nbsp;
                                            <span style={{ color: "#fff" }}>and&nbsp;</span>
                                            <br />
                                        </span>
                                        <span
                                            data-test-id="termsConditions"
                                            style={{ color: "#007BFF", cursor: "pointer" }}
                                            onClick={() => {
                                                this.props.handleCommonPath("TermsConditions")
                                            }}>
                                            Terms and conditions&nbsp;
                                        </span>
                                        to Sign up
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="mainContainer">
                                <Box className="container1">
                                    <Box className="image">
                                        <img src={leftImage} alt="leftImage" className="image" />
                                    </Box>
                                    <Box className="desc">
                                        I don’t have an RSS feed, so I want<br /> to start a new podcast
                                    </Box>
                                </Box>
                                <Box className="container1">
                                    <Box className="image">
                                        <img src={rightImage} alt="middleImage" className="image" />
                                    </Box>
                                    <Box className="desc">I  already have a podcast with an <br />RSS Feed. I would like to keep my <br />current hosting platform.</Box>
                                </Box>
                                <Box className="container1">
                                    <Box className="image">
                                        <img src={hostMethod3} alt="rightImage" className="image" />
                                    </Box>
                                    <Box className="desc">I already have a podcast with an<br /> RSS Feed. I would like to host on <br />PodcastTown.</Box>
                                </Box>
                            </Box>
                            <Box className="buttons">
                                <Button
                                    onClick={() => {
                                        this.props.handleCommonPath("SetupPodcastProfileNoRssFeed")
                                    }}
                                    data-test-id="navigationBtn"
                                    className={ResolveCondition(this.state.checkTick, "btn1color", "btn1")}
                                    disabled={(this.state.checkTick ? false : true)}>Create New Podcast</Button>
                                <Button
                                    className={ResolveCondition(this.state.checkTick, "btn1color", "btn1")}
                                    data-test-id="navigation1"
                                    onClick={() => {
                                        this.props.handleCommonPath("HostSetupRssFeed")
                                    }}
                                    disabled={(this.state.checkTick ? false : true)}
                                >Import Existing RSS Feed</Button>
                            </Box>
                        </Box>
                    </StyledWrapperHostSetup>
                    <Footer navigation={this.props.navigation} id={"1"} />
            </>

        );
    }

}
const StyledWrapperHostSetup = styled("div")({
    height: "auto",
    padding:"20px 50px",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .checkboxContent": {
        color: "white",
        fontSize: "14px",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        marginLeft: "1%",
        textTransform: "capitalize",
        borderLeft: "4px solid #FF0807",
        height: "70%",
        paddingLeft: "1%",
        display: "flex",
        borderRadius: "4px",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    "& .TermsCondition": {
        color: "#1D4ED8",
    },
    "& .tickCheckBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0%",
        marginTop: "2%",
        marginBottom: "2%",
        height: "20%",
        width: "100%"
    },
    "& .buttons": {
        width: "753px",
        height: "90px",
        margin: "auto",
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "1%",
    },
    "& .btn1": {
        height: "56px",
        width: "360px",
        background: "#B2B3B3",
        color: "white !important",
        borderRadius: "8px",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        "&:hover": {
            background: "#FF0807",
        },
        cursor: "pointer"
    },
    "& .btn1color": {
        height: "56px",
        width: "360px",
        background: "#FF0807",
        color: "white",
        borderRadius: "8px",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        "&:hover": {
            background: "#FF0807",
        },
        cursor: "pointer"
    },
    "& .desc": {
        background: "#312F2D",
        height: "95px",
        width: "360px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        marginTop: "2%"
    },
    "& .image": {
        height: "223px",
        width: "358px",
    },
    "& .container1": {
        height: "100%",
        width: "368px"
    },
    "& .mainContainer": {
        height: "340px",
        width: "1150px",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",        
        gap:"24px"
    },

    "& .title": {
        height: "14%",
        width: "50%",
        margin: "auto",
        marginTop: "2%",
        justifyContent: "space-between"
    },
    "& .title1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "30px",
    },
    "& .container": {
        borderRadius: "8px",
        color: "white",
        height: "auto",
        width: "90%",
        margin: "auto",
        background: "#44403C",
        marginBottom: "3%"
    },
    "& .upper": {
        height: "15%",
        background: "#FF0807",
        borderRadius: "8px",
    }
})
// Customizable Area End
