import React from "react";
// Customizable Area Start
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
  TextField,
  Stack,
  IconButton,
  styled,
  Box,
  Checkbox,
  InputAdornment,
  Dialog,
  DialogContent,
  Divider,
  DialogActions
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { MailOutlined, Reply, DeleteOutlineRounded, Search, CloseOutlined } from '@mui/icons-material';
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { goBack } from "./assets";
// Customizable Area End
import MyInboxController, { Props } from "./MyInboxController";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
const MIN_WINDOW_HEIGHT = 900
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class MyInbox extends MyInboxController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={MyInboxwebStyles.container}>
          <StyledWrapperSetting>
            <Box display="flex" alignItems="center" mb={3}>
              <img onClick={this.handleGoBack} src={goBack} alt="Go Back" style={{ marginBottom: '20px', width: "2%" }} />
              <Typography variant="h4" sx={{ color: '#fff', marginBottom: '15px', marginLeft: "15px", fontWeight: "bold" }}>
                My Inbox
              </Typography>
            </Box>
            <Box className="main-container">
              <TableContainer>
                <Box display="flex" alignItems="center" style={{marginLeft: ResolveCondition((this.state.selected.length > 0 ), "1%", "0%")}}>
                  <TextField
                    variant="outlined"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ color: '#A5A1C6' }} />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '98px',
                        color: '#fff',
                        width: '500px',
                        height: "44px",
                        marginBottom: '7%',
                        border: "1px solid white",
                        marginLeft: "13%"
                      }
                    }}
                  />
                  {this.state.selected.length > 0 && (<Stack direction="row" sx={{ marginLeft: "10%", marginBottom: "2%" }}>
                    <Button variant="contained" startIcon={<MailOutlined />} onClick = {() => this.bulkMarkAsReadApi(this.state.selected)} sx={{ backgroundColor: '#030303', width: "165px", height: "35px", borderRadius: "8px", padding: "16px", marginLeft: "20px", textTransform: "capitalize", '&:hover': { backgroundColor: "#030303" }, fontFamily: "Nexa-Regular", fontWeight: "bold" }} data-test-id="mark-as-read-button">Mark as Read</Button>
                    <Button variant="contained" startIcon={<Reply />} sx={{ backgroundColor: '#030303', width: "111px", height: "35px", borderRadius: "8px", padding: "16px", marginLeft: "20px", textTransform: "capitalize", '&:hover': { backgroundColor: "#030303" }, '&.Mui-disabled': { backgroundColor: "#B2B3B3", color: "#fff" }, fontFamily: "Nexa-Regular", fontWeight: "bold" }} onClick={() => this.handleReplyClick(this.state.selected[0])} disabled={this.state.selected.length > 1 ? true : false} data-test-id="reply-button">Reply</Button>
                    <Button variant="contained" startIcon={<DeleteOutlineRounded />} sx={{ backgroundColor: '#FF0807', width: "111px", height: "35px", borderRadius: "8px", padding: "16px", marginLeft: "20px", textTransform: "capitalize", '&:hover': { backgroundColor: "#FF0807" }, fontFamily: "Nexa-Regular", fontWeight: "bold" }} onClick={() => this.handleOpenDialog(this.state.selected, null)} data-test-id="delete-button">Delete</Button>
                  </Stack>
                  )}
                  <Box style={{marginRight: '3%', marginTop: "-3%", marginLeft: ResolveCondition(this.state.selected.length > 0, "0%", "42%")}}>{this.renderPagination()}</Box>
                </Box>
                <Table sx={{ backgroundColor: '#44403C', borderRadius: 2, width: "92%", marginLeft: "5%" }}>
                  <TableBody>
                    {this.getPaginatedData().map((message, index) => {
                      const isItemSelected = this.state.selected.includes(message.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const backgroundColor = ResolveCondition(isItemSelected, 'red', ResolveCondition((index + 1) % 2 === 0, '#312F2D', '#44403C'));
                      const opacity = message.isRead!== true ? 0.5 : 1;
                      return (
                        <React.Fragment key={message.id}>
                          <TableRow
                            data-test-id={message.id}
                            tabIndex={-1}
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            sx={{
                              height: "109px", cursor: 'pointer', backgroundColor: backgroundColor, opacity: opacity,
                              '&:hover': {
                                backgroundColor: backgroundColor,
                              },
                              '&.Mui-selected': {
                                backgroundColor: "red",
                                '&:hover': {
                                  backgroundColor: 'red',
                                },
                              },
                              clipPath: "xywh(0 0 100% 100% round 1.5em)",
                              paddingnBottom: "2%",
                            }}
                          >
                            <TableCell sx={{ borderBottom: "0px" }} padding="checkbox">
                              <Checkbox
                                data-test-id="checkbox"
                                onClick={(event) => this.handleClick(event, message.id)}
                                checked={isItemSelected}
                                sx={{
                                  color: '#FFFFFF',
                                  borderRadius: "6px",
                                  "&.Mui-checked": {
                                    color: "#030303"
                                  },
                                }}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ borderBottom: "0px" }} >
                              <IconButton onClick={() => this.handleReplyClick(message.id)} sx={{ color: '#fff' }} data-test-id="reply-icon">
                                <Reply />
                              </IconButton>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "0px" }} data-test-id="delete-icon" onClick={() => this.handleOpenDialog(null, message.id)}>
                              <IconButton sx={{ color: '#fff' }} >
                                <DeleteOutlineRounded />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              id={labelId}
                              scope="row"
                              sx={{ overflow: "hidden", color: '#fff', borderBottom: "0px", fontFamily: "Nexa-Heavy", textTransform: "uppercase" }}
                              component="th"
                            >
                              {message.sender}
                            </TableCell>
                            <TableCell sx={{ overflow: "hidden", color: '#fff', borderBottom: "0px", fontFamily: "Nexa-Regular", fontWeight: "bold" }}>{message.subject}</TableCell>
                            <TableCell sx={{ color: '#bbb', borderBottom: "0px", overflow: "hidden", fontFamily: "Nexa-Regular", fontWeight: "bold" }}>{message.snippet}</TableCell>
                            <TableCell sx={{
                              color: '#bbb', overflow: "hidden", borderBottom: "0px", fontWeight: "bold", fontFamily: "Nexa-Regular"
                            }}>{message.time}</TableCell>
                          </TableRow>
                          <TableRow sx={{ height: "8px" }}>
                            <TableCell sx={{ padding: 0, borderBottom: "0px" }} colSpan={7} />
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </StyledWrapperSetting>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
        <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} PaperProps={{
          style: {
            margin: '0 auto',
            borderBottomRightRadius: '30px',
            width: '27%',
            height: '27%'
          },
        }}>
          <IconButton
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#fff'
            }}
            data-test-id="first-dialog-close-icon"
            onClick={this.handleCloseDialog}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent sx={{ backgroundColor: "#44403C", textAlign: "center" }}>
            <Typography align="center" variant="h6" style={{ color: "#fff", marginTop: "2%", fontFamily: 'Nexa-Heavy' }} gutterBottom>
              Are you sure you want to delete this message?
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'right', backgroundColor: "#44403C", height: '18%', padding: "5%" }}>
            <Button onClick={this.handleCloseDialog} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="no">
              No
            </Button>
            <Button onClick={this.handleDelete} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="yes">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  padding: "20px 50px",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .main-container": {
    backgroundColor: "#44403C",
    padding: '20px',
    height: "auto",
    marginTop: "2%",
    borderRadius: "8px",
    width: "97%"
  }
});
const MyInboxwebStyles = {
  container: {
  },
}
// Customizable Area End
