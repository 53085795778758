Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarApiMethodPostType = "POST";
exports.dashboarApiMethodDeleteType = "DELETE";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.lastPlayed = "bx_block_audiomusic2/audio_players/last_played";
exports.suggestedForYou = "bx_block_categories/trending_views/trending_podcast";
exports.saveEpisode = "bx_block_catalogue/followers";
exports.unSaveEpisode = "bx_block_catalogue/followers";
exports.continueListening = "bx_block_audiomusic2/audio_players";
exports.userDetails ="account_block/accounts/profile";
// Customizable Area End