import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { logo, podcastImage } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  totalStars: number;
  reviewCount: number;
  fullStars: number;
  hasHalfStar: boolean;
  emptyStars: number;
  totalReviews: number;
  podcastName: string;
  followButtonText: string;
  selectedTab: string;
  openEditLogoModal: boolean;
  selectedFile: File | null;
  imagePreviewUrl: string;
  tempLogoUrl: string;
  logo: File | null;
  anchorEl: null;
  expandedCardId:  number[];
  hostCommunityPostData: any[];
  hostChannelInfoData: any[];
  hostEpisodesData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostCatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getHostEpisodesDataApiCallId: string = "";
  getHostCommunityDataApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      totalStars: 5,
      reviewCount: 3.5,
      fullStars: 0,
      hasHalfStar: false,
      emptyStars: 0,
      totalReviews: 1.5,
      podcastName: "Cost of Winning",
      followButtonText: "Follow",
      selectedTab: "Episodes",
      openEditLogoModal: false,
      selectedFile: null,
      imagePreviewUrl: "",
      tempLogoUrl: "",
      logo: logo,
      anchorEl: null,
      expandedCardId:  [],
      hostEpisodesData: [],
      hostCommunityPostData: [],
      hostChannelInfoData:  [
        {
          id: 1,
          image: "https://via.placeholder.com/250x223?text=Podcast+1",
          title: "THE ATLAS OBSCURA",
          subtitle: "The True Podcast",
        },
        {
          id: 2,
          image: "https://via.placeholder.com/250x223?text=Podcast+2",
          title: "MYSTERY UNVEILED",
          subtitle: "The Hidden Stories",
        },
        {
          id: 3,
          image: "https://via.placeholder.com/250x223?text=Podcast+3",
          title: "EXPLORING THE UNKNOWN",
          subtitle: "Journeys Beyond",
        },
        {
          id: 4,
          image: "https://via.placeholder.com/250x223?text=Podcast+4",
          title: "SCIENCE TALKS",
          subtitle: "Discover New Horizons",
        },
        {
          id: 5,
          image: "https://via.placeholder.com/250x223?text=Podcast+5",
          title: "HISTORY REVISITED",
          subtitle: "Lessons from the Past",
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getHostEpisodesDataApiCall()
    this.calculateStars(this.state.reviewCount);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleGetHostEpisodesDataApiRespomse(from, message);
    this.handleGetHostCommunityDataApiResponse(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  calculateStars = (reviewCount: number) => {
    const fullStars = Math.floor(reviewCount);
    const hasHalfStar = reviewCount % 1 !== 0 ? true : false;
    const emptyStars = this.state.totalStars - fullStars - (hasHalfStar ? 1 : 0)
    this.setState({ fullStars, hasHalfStar, emptyStars });
  }
  handleFollowButton = () => {
    if (this.state.followButtonText === "Follow") {
      this.setState({ followButtonText: "Followed" });
    }
    else if (this.state.followButtonText === "Followed") {
      this.setState({ followButtonText: "Follow" });
    }
  }
  handleTabChanges = (tab: string) => {
    const newTab = tab;
    this.setState({ selectedTab: newTab });
  };
  openEditLogoModal = () => {
    this.setState({ openEditLogoModal: true, tempLogoUrl: "" })
  };
  handleCloseEditLogoDialog = () => {
    this.setState({ openEditLogoModal: false })
  }
  handleDoneClick = () => {
    let imageUrl = this.state.selectedFile ? URL.createObjectURL(this.state.selectedFile) : '';
    this.setState({ imagePreviewUrl: imageUrl, openEditLogoModal: false, logo: this.state.selectedFile });
  }
  handleCancelClick = () => {
    this.setState({ selectedFile: null, imagePreviewUrl: "", tempLogoUrl: "" })
    this.setState({ openEditLogoModal: false });
  }
  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ selectedFile: file, tempLogoUrl: imageUrl });
    };
  };

  handleMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ anchorEl: event.currentTarget});
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null,});
  };

  handleCardClick = (cardId: number) => {
    this.setState((prevState) => {
      const isExpanded = prevState.expandedCardId.includes(cardId);
      return {
        expandedCardId: isExpanded
          ? prevState.expandedCardId.filter((id:any) => id !== cardId) 
          : [...prevState.expandedCardId, cardId],
      };
    });
  };
  handleGetHostEpisodesDataApiRespomse = (from: string, message: Message) => {
    if (this.getHostEpisodesDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
      if (responseJson?.podcast.data.attributes.episodes) {
        this.setState({ hostEpisodesData: responseJson.podcast.data.attributes.episodes})
        this.getHostCommunityDataApiCall(responseJson.podcast?.data.id);
      }
    }
  }

  getHostEpisodesDataApiCall = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHostEpisodesDataApiCallId = requestMessage?.messageId

    const token = await getStorageData('token');

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEpisodeDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPostDays = (dateString:any) => {
    return moment(dateString).fromNow();
  };

  handleGetHostCommunityDataApiResponse = (from: string, message: Message) => {
    if (this.getHostCommunityDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.data) {
        const extractedHostCommunityPostData = responseJson.data.map((post:any) => {
          const account = post.attributes.account.attributes;
          const postData = post.attributes;
          return {
            id: postData.id,
            name: `${account.first_name} ${account.last_name}`,
            body: postData.body,
            date: postData.created_at,
            avatar: account.profile_picture,
            likes: postData.liked_post.count,
            comments: postData.comments.data.length,
            shares: postData.share_post_count,
            days: this.getPostDays(postData.created_at),
          }
        })
        this.setState({ hostCommunityPostData: extractedHostCommunityPostData })
      }
    }
  }

  getHostCommunityDataApiCall = async (podcastId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHostCommunityDataApiCallId = requestMessage?.messageId

    const token = await getStorageData('token');

    let getToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCommunityEndPoint}?podcast_id=${podcastId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: getToken
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
