// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

interface Host {
  first_name: string;
  last_name: string;
  email_address: string;
  location: string;
}


export interface S {
  hosts: Host[];
}

export interface SS {
  id: any;
}
interface PodcastThirdScreenFormValues {
  hosts: {
    first_name: string,
    last_name: string,
    email_address: string,
    location: string
  }[];
}
export default class SetUpProfileThirdScreenControllerNoRss extends BlockComponent<
  Props,
  S,
  SS
> {
  

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = { 
      hosts: [{ first_name: '', last_name: '', email_address: '', location: '' }],
    };
  }
  handleSubmitThirdScreen = async (values: PodcastThirdScreenFormValues) => {
    let storageData = await getStorageData('noRssData');
    let noRssData = storageData ? JSON.parse(storageData) : {};
    noRssData = {...noRssData, ...values};
    setStorageData("noRssData", JSON.stringify(noRssData));
    setStorageData("feedName", 'noRss');
    this.props.handleCommonPath("ChooseTemplate");
  }
}
// Customizable Area End