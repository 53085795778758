Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.accountsApiType = "GET";
exports.accountsApiEndpoint = "account_info";
exports.getCategoryDropdown = "bx_block_categories/categories";

exports.btnExampleTitle = "CLICK ME";
exports.resetPassword = "account_block/accounts/change_password";
exports.getCategoryDropdown = "bx_block_categories/categories";
exports.getSubCategoryDropdown = "bx_block_categories/sub_categories";
exports.countryApiEndPoint = "bx_block_stripe_integration/all_countries";
exports.stateApiEndPoint = "bx_block_stripe_integration/payments";
exports.editProfileApiType = "PATCH";
exports.editProfileEndpoint = "account_block/accounts";
exports.getPodcastApiEndPoint = "bx_block_login/podcasts";
exports.updateHostSettings = "bx_block_settings/settings";
exports.putMethod = "PUT";
// Customizable Area End