// Customizable Area Start
import React from "react";
import { styled, Box, Grid, Typography, Button, Avatar, FormControl, TextField, Select, MenuItem, Chip } from "@mui/material";
import { Close, ExpandMore } from '@mui/icons-material';
import { topImageEditprofileack } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ConditionalRender } from "../../utilities/src/ConditionalRender";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";


import AccountSettingsController, {
  CategoryStructure,
  Props
} from "./AccountSettingsController";

const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
export default class EditProfile extends AccountSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  validationSchema = Yup.object().shape({
    emailAddress: Yup.string().matches(emailRegex, "Please Enter A Valid Email Address"),
    firstName: Yup.string().required("Please Enter Your First Name"),
    lastName: Yup.string().required("Please Enter Your Last Name"),
    gender: Yup.string().required("Please Select Your Gender"),
    race: Yup.string().required("Please Select Your Race")
  });

  render() {
    return (
      // Customizable Area Start
      <>
          <StyledWrapperSetting>
            <Box display="flex" alignItems="center" mb={3}>
              <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "6.5%", fontWeight: "bold", fontFamily: "Nexa-Heavy", fontSize: "30px" }}>
                Edit Profile
              </Typography>
            </Box>
            <Box className="account-settings-container-1">
              <Box className="outer-box-container">
                <img src={topImageEditprofileack} className="container-one-top-image" />
                <Avatar alt="User Image" src={this.state.profilePicture} className="profile-avatar" onClick={this.handleAvatarClick} data-test-id="avatar" />
                <input type="file" ref={this.fileInputRef} onChange={this.handleFileChange} style={{ display: "none" }} data-test-id="file-for-profile" />
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    emailAddress: this.state.userEmail || '',
                    bio: this.state.bio || '',
                    firstName: this.state.firstName || '',
                    lastName: this.state.lastName || '',
                    dob: this.state.dob,
                    location: this.state.location,
                    gender: ResolveCondition(this.state.gender !== null, this.state.gender, ""),
                    race: ResolveCondition(this.state.race !== null, this.state.race, ""),
                    facebook: this.state.socialMedia.facebook,
                    linkedIn: this.state.socialMedia.linkedIn,
                    x: this.state.socialMedia.x,
                    otherOptions: this.state.socialMedia.instagram,
                    favouritePodcastCategories: this.state.favoritePodcasts
                  }}
                  data-test-id="formik-wrapper"
                  validationSchema={this.validationSchema}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    const updatedValues = {
                      ...values,
                      gender: values.gender || '',
                      race: values.race || ''
                    };
                    this.handleEditAccountSubmit(updatedValues);
                  }}
                >
                  {({ handleChange, handleSubmit, values, errors, touched, setFieldValue, handleReset, dirty }) => (
                    <Form onSubmit={handleSubmit} onChange={this.handleFormChange}>
                      <Grid container spacing={2}>
                        <Grid container justifyContent="center" alignItems="center">
                          <Box sx={{ textAlign: "center", width: "70%" }}>
                            <Typography variant="body1" sx={{ color: "#CBD5E1", marginBottom: "8px", fontFamily: "Nexa-heavy", lineHeight: "26px", fontSize: "14px", marginLeft: "5%", textAlign: "left" }}>
                              Email Address
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              name="emailAddress"
                              value={values.emailAddress}
                              onChange={handleChange}
                              InputLabelProps={{ style: { color: '#fff' } }}
                              InputProps={{ style: { color: '#fff', fontFamily: "Nexa-heavy" } }}
                              sx={{
                                width: "92%",
                                padding: "10px 8px 10px 8px",
                                borderRadius: "8px",
                                "& .MuiInputLabel-root": {
                                  color: "#CBD5E1",
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "56px",
                                  "& fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                  "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                    height: "3.438em"
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  color: "#CBD5E1",
                                  backgroundColor: "#030303",
                                  "&:-webkit-autofill": {
                                    backgroundColor: "black !important",
                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                    WebkitTextFillColor: "#CBD5E1 !important",
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                        {ConditionalRender(errors, touched, 'emailAddress', <div className="errors" style={{ marginLeft: "19%" }}>{errors.emailAddress}</div>)}

                        <Grid container justifyContent="center" alignItems="center">
                          <Box sx={{ textAlign: "left", width: "70%", marginTop: "1%" }}>
                            <Typography variant="body1" sx={{ color: "#CBD5E1", fontFamily: "Nexa-heavy", lineHeight: "26px", fontSize: "14px", marginLeft: "5.5%", textAlign: "left", marginBottom: "8px" }}>
                              BIO
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              name="bio"
                              data-test-id= "bio-field"
                              multiline
                              rows={4}
                              value={values.bio}
                              onChange={(e) => {
                                if (e.target.value.length <= 200) {
                                  handleChange(e); 
                                }
                              }}
                              InputLabelProps={{ style: { color: '#fff' } }}
                              InputProps={{ style: { color: '#fff', fontFamily: "Nexa-heavy" } }}
                              sx={{
                                width: "90%",
                                borderRadius: "8px",
                                marginTop: "1%",
                                marginLeft: "5%",
                                "& .MuiInputLabel-root": {
                                  color: "#CBD5E1",
                                },  
                                "& .MuiOutlinedInput-root": {
                                  height: "101px",
                                  padding: "0px",
                                  "& fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  color: "#CBD5E1",
                                  backgroundColor: "#030303",
                                  padding: "6px",
                                  "&:-webkit-autofill": {
                                    backgroundColor: "black !important",
                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                    WebkitTextFillColor: "#CBD5E1 !important",
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "4%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  fontFamily: "Nexa-heavy",
                                  lineHeight: "26px",
                                  fontSize: "14px",
                                  marginLeft: "38%",
                                  textAlign: "left",
                                }}
                              >
                                First Name<span>*</span>
                              </Typography>
                              <TextField
                                id="firstName"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: "#fff" } }}
                                InputProps={{ style: { color: "#fff", fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  borderRadius: "8px",
                                  padding: "10px 8px",
                                  marginLeft: "34%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                      height: "3.438em",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                            </Box>
                            {ConditionalRender(errors, touched, 'firstName', <div className="errors" style={{ marginLeft: "38%" }}>{errors.firstName}</div>)}
                          </Grid>

                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "4%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  fontFamily: "Nexa-heavy",
                                  lineHeight: "26px",
                                  fontSize: "14px",
                                  marginLeft: "4%",
                                  marginBottom: "8px",
                                  textAlign: "left",
                                }}
                              >
                                Last Name<span>*</span>
                              </Typography>
                              <TextField
                                id="lastName"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: '#fff' } }}
                                InputProps={{ style: { color: '#fff', fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  padding: "10px 8px",
                                  borderRadius: "8px",
                                  marginRight: "34%",
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                            </Box>
                            {ConditionalRender(errors, touched, 'lastName', <div className="errors" style={{ marginLeft: "4%" }}>{errors.lastName}</div>)}
                          </Grid>

                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "4%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  lineHeight: "26px",
                                  fontFamily: "Nexa-heavy",
                                  fontSize: "14px",
                                  marginLeft: "38%",
                                  textAlign: "left",
                                }}
                              >
                                Date of Birth<span>*</span>
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                name="dob"
                                type="date"
                                value={values.dob}
                                InputLabelProps={{ style: { color: '#fff' } }}
                                InputProps={{ style: { color: '#fff', fontFamily: "Nexa-heavy" } }}
                                inputProps={{ readOnly: true }}
                                sx={{
                                  width: "60%",
                                  padding: "10px 8px",
                                  borderRadius: "8px",
                                  marginLeft: "34%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#78716C",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px #78716C inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "4%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  fontFamily: "Nexa-heavy",
                                  lineHeight: "26px",
                                  fontSize: "14px",
                                  marginLeft: "4%",
                                  textAlign: "left",
                                }}
                              >
                                Location
                              </Typography>
                              <TextField
                                id="location"
                                name="location"
                                value={values.location}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: "#fff" } }}
                                InputProps={{ style: { color: "#fff", fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  padding: "10px 8px",
                                  borderRadius: "8px",
                                  marginRight: "34%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                      height: "3.438em",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ textAlign: 'center', width: '100%', marginTop: '6%' }}>
                            <FormControl fullWidth variant="filled">
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: '14px',
                                  fontFamily: 'Nexa-heavy',
                                  color: '#CBD5E1',
                                  marginBottom: '8px',
                                  lineHeight: '26px',
                                  marginLeft: '36%',
                                  textAlign: 'left',
                                }}
                              >
                                Gender<span>*</span>
                              </Typography>
                              <Select
                                name="gender"
                                value={values.gender}
                                data-test-id="gender-field-Id"
                                onChange={(event) => { setFieldValue("gender", event.target.value) }}
                                sx={{
                                  color: '#fff',
                                  width: '59%',
                                  padding: '10px 8px',
                                  borderRadius: '8px',
                                  marginLeft: '36%',
                                  backgroundColor: '#030303',
                                  border: '1px solid white',
                                  height: "56px",
                                  '.MuiSelect-icon': {
                                    color: 'white',
                                  },
                                  '& .MuiSelect-select': {
                                    textAlign: 'left',
                                    fontFamily: "Nexa-heavy",
                                    padding: "5px"
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent !important',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent !important',
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent !important',
                                  },
                                  '&:hover': {
                                    backgroundColor: '#030303 !important',
                                  },
                                  '&.Mui-focused': {
                                    backgroundColor: '#030303 !important',
                                  },
                                }}
                                IconComponent={(props) => (
                                  <ExpandMore {...props} style={{ color: 'white' }} />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      backgroundColor: '#000',
                                      color: '#fff',
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="Non-binary">Non-binary</MenuItem>
                                <MenuItem value="Transgender">Transgender</MenuItem>
                                <MenuItem value="Genderqueer">Genderqueer</MenuItem>
                                <MenuItem value="Agender">Agender</MenuItem>
                                <MenuItem value="Genderfluid">Genderfluid</MenuItem>
                                <MenuItem value="Two-Spirit">Two-Spirit</MenuItem>
                                <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {ConditionalRender(errors, touched, 'gender', <div className="errors" style={{ marginLeft: "36%" }}>{errors.gender}</div>)}
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ textAlign: 'center', width: '100%', marginTop: '6%' }}>
                            <FormControl fullWidth variant="filled">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: '#CBD5E1',
                                  marginBottom: '8px',
                                  fontFamily: 'Nexa-heavy',
                                  lineHeight: '26px',
                                  fontSize: '14px',
                                  marginLeft: '3%',
                                  textAlign: 'left',
                                }}
                              >
                                Race<span>*</span>
                              </Typography>
                              <Select
                                id="race"
                                name="race"
                                value={values.race}
                                data-test-id="race-field"
                                onChange={(event) => { setFieldValue("race", event.target.value) }}
                                sx={{
                                  color: '#fff',
                                  width: '59%',
                                  padding: '10px 8px',
                                  borderRadius: '8px',
                                  marginLeft: '3%',
                                  height: "56px",
                                  backgroundColor: '#030303',
                                  border: '1px solid white',
                                  '.MuiSelect-icon': {
                                    color: 'white',
                                  },
                                  '& .MuiSelect-select': {
                                    textAlign: 'left',
                                    fontFamily: "Nexa-heavy",
                                    padding: "5px"
                                  },
                                  '&.Mui-focused': {
                                    backgroundColor: '#030303 !important',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent !important',
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent !important',
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent !important',
                                  },
                                  '&:hover': {
                                    backgroundColor: '#030303 !important',
                                  },
                                }}
                                IconComponent={(props) => (
                                  <ExpandMore {...props} style={{ color: 'white' }} />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      backgroundColor: '#000',
                                      color: '#fff',
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="white or caucasian">White or Caucasian</MenuItem>
                                <MenuItem value="black or african american">Black or African American</MenuItem>
                                <MenuItem value="asian">Asian</MenuItem>
                                <MenuItem value="Native American or Alaska Native">Native American or Alaska Native</MenuItem>
                                <MenuItem value="Native Hawaiian or Pacific Islander">Native Hawaiian or Pacific Islander</MenuItem>
                                <MenuItem value="Hispanic or Latino">Hispanic or Latino</MenuItem>
                                <MenuItem value="Multiracial or Biracial">Multiracial or Biracial</MenuItem>
                                <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                                <MenuItem value="other">other</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {ConditionalRender(errors, touched, 'race', <div className="errors" style={{ marginLeft: "3%" }}>{errors.race}</div>)}
                        </Grid>

                        <Typography style={{ color: "#fff", fontFamily: "Nexa-Heavy", marginLeft: "19%", marginTop: "5%" }}>SOCIAL MEDIA LINKS</Typography>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  fontFamily: "Nexa-heavy",
                                  lineHeight: "26px",
                                  fontSize: "14px",
                                  marginLeft: "38%",
                                  textAlign: "left",
                                }}
                              >
                                Facebook
                              </Typography>
                              <TextField
                                id="facebook"
                                name="facebook"
                                value={values.facebook}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: "#fff" } }}
                                InputProps={{ style: { color: "#fff", fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  padding: "10px 8px",
                                  borderRadius: "8px",
                                  marginLeft: "34%",
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                      height: "3.438em",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  fontFamily: "Nexa-heavy",
                                  lineHeight: "26px",
                                  marginLeft: "4%",
                                  fontSize: "14px",
                                  textAlign: "left",
                                }}
                              >
                                LinkedIn
                              </Typography>
                              <TextField
                                id="linkedIn"
                                name="linkedIn"
                                value={values.linkedIn}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: '#fff' } }}
                                InputProps={{ style: { color: '#fff', fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  borderRadius: "8px",
                                  marginRight: "34%",
                                  padding: "10px 8px",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  fontFamily: "Nexa-heavy",
                                  lineHeight: "26px",
                                  fontSize: "14px",
                                  marginLeft: "38%",
                                  textAlign: "left",
                                }}
                              >
                                X
                              </Typography>
                              <TextField
                                id="x"
                                name="x"
                                value={values.x}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: "#fff" } }}
                                InputProps={{ style: { color: "#fff", fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  padding: "10px 8px",
                                  borderRadius: "8px",
                                  marginLeft: "34%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                      height: "3.438em",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ textAlign: "center", width: "100%", marginTop: "1%" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#CBD5E1",
                                  marginBottom: "8px",
                                  fontFamily: "Nexa-heavy",
                                  textAlign: "left",
                                  lineHeight: "26px",
                                  fontSize: "14px",
                                  marginLeft: "4%",
                                }}
                              >
                                Other Options
                              </Typography>
                              <TextField
                                id="otherOptions"
                                name="otherOptions"
                                value={values.otherOptions}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: '#fff' } }}
                                InputProps={{ style: { color: '#fff', fontFamily: "Nexa-heavy" } }}
                                sx={{
                                  width: "60%",
                                  padding: "10px 8px",
                                  borderRadius: "8px",
                                  marginRight: "34%",
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "#030303",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ textAlign: "center", width: "70%", marginTop: "1%" }}>
                            <FormControl fullWidth variant="filled">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: '#CBD5E1',
                                  marginBottom: '8px',
                                  fontFamily: 'Nexa-heavy',
                                  lineHeight: '26px',
                                  fontSize: '14px',
                                  marginLeft: '26%',
                                  textAlign: 'left',
                                }}
                              >
                                Favourite Podcast Categories
                              </Typography>
                              <Select
                                name="favouritePodcastCategories"
                                multiple
                                variant="filled"
                                value={values.favouritePodcastCategories}
                                data-test-id="fav-podcast"
                                onChange={(event) => {
                                  const selectedValues = event.target.value as string[];
                                  if (selectedValues.length <= 5) {
                                    setFieldValue('favouritePodcastCategories', selectedValues);
                                  }
                                }}
                                style={{
                                  color: '#fff',
                                  width: '90%',
                                  height: "72px",
                                  padding: '10px 8px',
                                  borderRadius: '8px',
                                  marginLeft: '26%',
                                  backgroundColor: ResolveCondition((values.favouritePodcastCategories.length >= 5), '#78716C', '#030303'),
                                  border: "1px solid #fff"
                                }}
                                IconComponent={(props) => (
                                  <ExpandMore {...props} style={{ color: 'white' }} />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      backgroundColor: '#000',
                                      color: '#fff',
                                    },
                                  },
                                }}
                                renderValue={(selected: string[]) => (
                                  <Box sx={{ display: 'flex', marginRight: "29%", marginLeft: "-1%" }}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        data-test-id={`chip-${value}`}
                                        label={
                                          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: "#44403C", padding: "3px", width: "100%" }}>
                                            <Close
                                              sx={{ color: '#fff', marginRight: '8px', cursor: 'pointer' }}
                                              onMouseDown={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                const newValues = selected.filter((v) => v !== value);
                                                setFieldValue('favouritePodcastCategories', newValues);
                                              }}
                                            />
                                            {value.charAt(0).toUpperCase() + value.slice(1)}
                                          </Box>
                                        }
                                        sx={{
                                          backgroundColor: '#44403C',
                                          color: 'white',
                                          marginBottom: "2%",
                                          '&:not(:first-of-type)': {
                                            marginLeft: '3%',
                                          },
                                          '& .MuiSelect-select': {
                                            fontFamily: "Nexa-heavy"
                                          },
                                        }}
                                      />
                                    ))}
                                  </Box>
                                )}
                              >
                                {this.state.categories && this.state.categories.map((item: CategoryStructure) => (
                                  <MenuItem value={item.attributes.name} key={item.id}>{item.attributes.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center" textAlign="center">
                          <Box style={{ marginTop: "6%", marginBottom: "6%" }}>
                            <Button variant="contained"
                              style={{ backgroundColor: '#030303', color: '#fff', textTransform: "capitalize", width: "392px", height: "56px", fontFamily: "Nexa-Heavy" }}
                              type="reset"
                              data-test-id= "reset-form"
                              onClick={() => { this.resetFormChanges() }}
                              onReset={handleReset}
                            >
                              Cancel
                            </Button>
                            <Button type="submit" variant="contained"
                              data-test-id="submitBtn"
                              disabled={(!this.state.profileUpdated && !dirty) || this.state.isSaved}
                              sx={{
                                backgroundColor: "#FF0807",
                                color: "#fff",
                                textTransform: "capitalize",
                                width: "392px", height: "56px",
                                fontFamily: "Nexa-Heavy",
                                marginLeft: "65px",
                                '&.Mui-disabled': {
                                  backgroundColor: '#B2B3B3',
                                  color: "#fff"
                                },
                                '&:hover': {
                                  backgroundColor: '#FF0807'
                                }
                              }}
                            >
                              Save Changes
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <Footer navigation={this.props.navigation} id={"1"} />
      </>
      // Customizable Area End
    );
  }
}

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  background: "black",
  padding:"20px 50px",
  "& *": {
    boxSizing: "border-box",
  },
  "& .outer-heading": {
    color: "#fff",
    fontWeight: "900",
    fontSize: "30px",
    marginLeft: "5px",
  },
  "& .account-settings-container-1": {
    backgroundColor: "#312F2D",
    height: "auto",
    marginLeft: "6%",
    borderRadius: "8px",
    width: "95%"
  },
  "& .outer-box-container": {
    margin: "0 auto",
    position: "relative",
    borderRadius: "8px",
  },
  "& .container-one-top-image": {
    width: "100%",
    objectFit: "cover",
    borderRadius: "8px 8px 0px 0px",
  },
  "& .profile-avatar": {
    width: "266px",
    height: "266px",
    border: "2px solid black",
    marginBottom: "68px",
    position: "relative",
    marginTop: "-158px",
    zIndex: "1",
    marginLeft: "40%",
    cursor: "pointer"
  },
  "& .label": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
  },
  "& .errors": {
    color: "red",
    fontFamily: "Nexa-Heavy",
    fontWeight: 400,
    fontSize: "12px",
    marginTop: "6px"
  },
});




// Customizable Area End