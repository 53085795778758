// export const imgPasswordVisible = require("../assets/ic_password_visible.png");
// export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const micImg = require("../assets/mic.png").default
export const telephoneImg = require("../assets/telephone.png").default
export const podcastImg = require("../assets/podcast.png").default
export const datsImg = require("../assets/dats.png").default
export const kiddingImg = require("../assets/kidding.png").default
export const japaneseImg = require("../assets/japanese.png").default
export const coupleImg = require("../assets/couple.png").default
export const beautyImg = require("../assets/beauty.png").default
