// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  templateSelected: boolean;
}

export interface SS {
  id: any;
}

export default class HostMonetizationTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteMonetizeTemplateCallId = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      templateSelected: true
    }

  }
  handleResForDeleteMonetizationTemplateData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
      handleNavigation("HostMonetizationSetup", this.props)
    }
  };

  deleteMonetizationTemplate = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizeTemplateCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };
  async receive(from: string, message: Message) {

    if (this.deleteMonetizeTemplateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationTemplateData(message);
    }
  }


}
// Customizable Area End
