import React from "react";
import { Box, Grid, IconButton } from "@mui/material";
import Footer from "../../blocks/navigationmenu/src/Footer.web";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DiscoverController, {
    Props,
} from "../../blocks/categoriessubcategories/src/components/DiscoverController";
import { handleNavigation } from "../../blocks/utilities/src/CustomHelpers"
const MIN_WINDOW_HEIGHT = 900


export default class AllCategories extends DiscoverController {
    constructor(props: Props) {
        super(props);

    }
    render() {


        return (

            <>
                <div style={webStyles.container}>
                    <Box className="activity-feed-section" sx={{ display: "flex", }}>
                        <IconButton
                            sx={{
                                width: "40px",
                                height: "40px",
                                paddingLeft: "15px",
                                borderRadius: "8px",
                                color: "#F5F5F5",
                                background: "#44403C",
                                "&:hover": {
                                    background: "#44403C",
                                },
                                marginRight: "-30px"
                            }}
                            onClick={() => this.props.handleCommonPath("Discover")}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Box sx={webStyles.containerTitle}>Categories</Box>
                    </Box>
                    <Grid sx={webStyles.boxContainer}>
                        {
                            this.state.allCategories.map((item: any) => (
                                <Grid key={item.id} data-test-id="boxSelectedimg">
                                    <Box>
                                        <Box sx={[webStyles.boxInside]} onClick={() => this.props.handleCommonPath("SubCategories")}
                                        >
                                            <img src={item.src} alt={item.alt} className="img" />
                                        </Box>
                                        <div style={{ ...webStyles.label, textAlign: "center" }}>{item.name.toUpperCase()}</div>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Footer navigation={this.props.navigation} id={"1"} />
                </div>
                
            </>

        );
    }
}

const webStyles = {
    container: {
        padding: "20px 50px",
      
    } as const,

    boxContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start"
    },
    img: {
        height: "40%",
        width: "40%"
    },
    label: {
        fontFamily: "Nexa-Heavy",
        fontSize: "18px",
        fontWeight: 900,
        padding: "6% 14%",
        color: "white"
    },
    boxInside: {
        background: "#44403C",
        height: "223px",
        width: "276px",
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin: "8px"
    },
    boxSelected: {
        background: "#FF0807"
    },
    boxContain: {
        background: "#312F2D",
        height: "289px",
        width: "239px",
        borderRadius: "16px",
        margin: "8px"
    },
    containerTitle: {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        marginBottom: "24px",
        fontFamily: "Nexa-Heavy",
        marginLeft: "50px"
    },

};

