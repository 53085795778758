Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.postCommunityEndPoint= "bx_block_communityforum/posts"
exports.getEpisodeDataEndPoint = "bx_block_settings/settings/channel";
exports.postContactHost= "bx_block_contact_us/messages";
exports.exampleAPiMethod="POST"
exports.aboutText = "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Vulputate, Nulla Lacinia Accumsan Placerat, Ex Augue Hendrerit Ligula, Id Cursus Massa Lorem Sit Amet Ante. Ut Lobortis Tempor Semper. Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Vulputate, Nulla Lacinia Accumsan Placerat, Ex Augue Hendrerit Ligula, Id Cursus Massa Lorem Sit Amet Ante. Ut Lobortis Tempor Semper."
exports.aboutTitle = "About"
exports.channelInfoTitle = "The True Podcast"
exports.channelInfodescription = "COST OF WINNING"
exports.channelInfoLocation = "London, UK"
exports.otherShows= "Others Shows From This Host"
exports.host= "Main Host :"
exports.hostNameOther= "THE ATLAS OBSCURA"
exports.hostName = "Ana James"
exports.cancelText= "Cancel"
exports.closeText = "Close"
exports.saveText= "Send"
exports.hostDescription = "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit."
// Customizable Area End