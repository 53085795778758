// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import {getStorageData, setStorageData} from "../../../framework/src/Utilities"


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
  hanldeParamState: () => void;
}

export interface S { 
  isModalOpen : boolean
}

export interface SS {
  id: any;
}

export default class HostPaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  getRoleApiCallId = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {      
      isModalOpen : false
    };
  }
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
};

async receive(from: string, message: Message) {
  if (this.getRoleApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    this.handleResForgetRoleApi(message);
  }
}
getRole =async () => {     
  let token = await getStorageData("token");
  const header = {
    token: JSON.parse(token),
  };
     
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getRoleApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getRole}?role=host`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );   
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.loginAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleResForgetRoleApi = async (message: Message) => {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if (responseJson.data) {
   setStorageData("currentRoles",JSON.stringify(["resident","host"]))
   let role = await getStorageData("currentRoles",true)
   console.log(role,"rolerole")  
   this.props.handleCommonPath("HostSetupMethod")
   this.props.hanldeParamState()
  }
};
}
// Customizable Area End
