import React  from 'react'
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { handleNavigation } from "../../../utilities/src/CustomHelpers";
import { imageArt, business } from "../assets"
import { prev, next, sponsored1, suggested1, suggested2 } from "../../../dashboard/src/assets";
interface ArrowProps {
    onClick: () => void;
  }
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sponsoredPodcasts: any,
  suggestedPodcasts: any,
  trendingPodcasts: any,
  favorateCategories: any,
  allCategories: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DiscoverController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
  getProductApiCallId: any;
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    this.state = {
    // Customizable Area Start
    sponsoredPodcasts: [],
    suggestedPodcasts: [],
    trendingPodcasts: [],
    favorateCategories: [],
    allCategories: []
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    
    this.setState({
      favorateCategories: [
        {id: 1, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 2, src: imageArt, alt: "image2", name: "Arts"},
            {id: 3, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 4, src: imageArt, alt: "image2", name: "Arts"},
            {id: 5, src: imageArt, alt: "image1", name: "Arts"}, 
      ],
        trendingPodcasts: [
            {id: 1, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
            {id: 2, src: business, alt: "image2", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Following"},
            {id: 3, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
            {id: 4, src: business, alt: "image2", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Following"},
            {id: 5, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
          ],
        sponsoredPodcasts: [
          {id: 1, src: sponsored1, alt: "image1", episode: "Episode 12", name: "The true"}, 
          {id: 2, src: sponsored1, alt: "image2", episode: "Episode 12", name: "The true"},
          {id: 3, src: sponsored1, alt: "image3", episode: "Episode 12", name: "The true"},
          {id: 4, src: sponsored1, alt: "image4", episode: "Episode 12", name: "The true"},
          {id: 5, src: sponsored1, alt: "image5", episode: "Episode 12", name: "The true"},
          {id: 6, src: sponsored1, alt: "image6", episode: "Episode 12", name: "The true"},
          {id: 7, src: sponsored1, alt: "image7", episode: "Episode 12", name: "The true"},
        ],
      suggestedPodcasts: [
        {id: 1, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false}, 
        {id: 2, src: suggested2, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 3, src: suggested2, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
        {id: 4, src: suggested1, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 5, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
      ],

      allCategories: [
          {id: 1, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 2, src: imageArt, alt: "image2", name: "Arts"},
            {id: 3, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 4, src: imageArt, alt: "image2", name: "Arts"},
            {id: 5, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 6, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 7, src: imageArt, alt: "image2", name: "Arts"},
            {id: 8, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 9, src: imageArt, alt: "image2", name: "Arts"},
            {id: 10, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 11, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 12, src: imageArt, alt: "image2", name: "Arts"},
            {id: 13, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 14, src: imageArt, alt: "image2", name: "Arts"},
            {id: 15, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 16, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 17, src: imageArt, alt: "image2", name: "Arts"},
            {id: 18, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 19, src: imageArt, alt: "image2", name: "Arts"},
            {id: 20, src: imageArt, alt: "image1", name: "Arts"}, 
      ]
       
      })
}

// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  getSponsoredSlickSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => {}, innerWidth }),
    };
  }

 
  prevSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props
  
    return (
      <img
        src={prev}
        onClick={onClick}
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-5%" : "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }}
        alt="Previous Arrow"
      />
    );
  };

  nextSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return (
      <img
        src={next} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-4%" : "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Next Arrow"
      />
    );
  }

  getSuggestedSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
        ]
       }
    )
  }
  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        data-test-id="nextSuggestedArrow"
        src={next} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-3.5%" : "-5.5%",
          top: innerWidth > 1440 ? "45%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }
  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        data-test-id="preSuggestedArrow"
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-4.5%" : "-5.5%",
          top: innerWidth > 1440 ? "45%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

 
  // Customizable Area End
}
