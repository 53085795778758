// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    TextField,
    Button,
    FormControl,
    Typography,
    Select,
    MenuItem,
    InputAdornment,
    IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";
import { checkbox, logoSvg, signUp_background, visibility_icon, visibility_cross, checkBoxTicked } from "./assets";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";
import { ConditionalRender } from "../../utilities/src/ConditionalRender";
import { setStorageData } from "../../../framework/src/Utilities";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const privacyPolicyLink = "https://www.podcasttown.net/privacy-policy"
const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
        first_name: Yup.string().required("Please Enter Your First Name").test(
            "no-spaces",
            "First Name should not contain spaces",
            (value) => !/\s/.test(value)
        ),
        last_name: Yup.string().required("Please Enter Your Last Name").test(
            "no-spaces",
            "Last Name should not contain spaces",
            (value) => !/\s/.test(value)
        ),
        username: Yup.string().required("Please Enter Your UserName"),
        day: Yup.string().required("Please Enter Your Date Of Birth"),
        month: Yup.string().required("Please Enter Your Date Of Birth"),
        year: Yup.string().required("Please Enter Your Date Of Birth"),
        location: Yup.string().required("Please Enter Your Location"),
        email_address: Yup.string().required("Please Enter A Valid Email Address").matches(emailRegex, "Please Enter A Valid Email Address"),
        contact_no: Yup.string().required("Please Enter A Valid Contact Number"),
        password: Yup.string()
            .required("Password is required")
            .matches(/[A-Z]/, "Must contain at least one uppercase letter")
            .matches(/[a-z]/, "Must contain at least one lowercase letter")
            .matches(/\d/, "Must contain at least one number")
            .min(8, "Minimum character length is 8"),
        confirm_password: Yup.string()
            .required("Please confirm your password")
    });
    render() {
        const { firstName, lastName, location, username, day, year, month, password, reTypePassword,
            email, gender, race, fullNumber, countryCode, contactNo } = this.state
        return (
            <StyledWrapperSignUp>
                <Grid className="container">
                    <Grid className="leftContainer">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                first_name: this.state.firstName,
                                last_name: this.state.lastName,
                                username: this.state.username,
                                day,
                                month,
                                year,
                                password,
                                confirm_password: reTypePassword,
                                location,
                                email_address: email,
                                contact_number: "",
                                gender,
                                race,
                                full_number: fullNumber,
                                country_code: countryCode,
                                contact_no: contactNo,
                            }}
                            data-test-id="formikWrapper"
                            validationSchema={this.validationSchema}
                            onSubmit={(values, { setErrors }) => {
                                setStorageData("Registration", JSON.stringify(values))
                                this.setState({ apiError: "" })
                                this.postUsernameApi(values, setErrors);
                            }}
                        >

                            {({ handleChange, handleSubmit, values, errors, setFieldValue, touched, setErrors }) => {
                                return (

                                    <Form onSubmit={handleSubmit}>
                                        <Box className="logoImg">
                                            <img src={logoSvg} alt="logo" />
                                        </Box>
                                        <Box className="title">Sign Up</Box>
                                        <Grid className="fields">
                                            <Box><label className="label">First Name<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                value={values.first_name}
                                                name="first_name"
                                                onChange={handleChange}
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: "#CBD5E1",
                                                        backgroundColor: "black",
                                                        "&:-webkit-autofill": {
                                                            backgroundColor: "black !important",
                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                        },
                                                    },
                                                }}
                                            />

                                        </Grid>
                                        {ConditionalRender(errors, touched, 'first_name', <div className="errors">{errors.first_name}</div>)}
                                        <Grid className="fields">
                                            <Box><label className="label">Last Name<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                value={values.last_name}
                                                name="last_name"
                                                onChange={handleChange}
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: "#CBD5E1",
                                                        backgroundColor: "black",
                                                        "&:-webkit-autofill": {
                                                            backgroundColor: "black !important",
                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                        },
                                                    },
                                                }}
                                            />

                                        </Grid>
                                        {ConditionalRender(errors, touched, 'last_name', <div className="errors">{errors.last_name}</div>)}
                                        <Grid className="fields">
                                            <Box><label className="label">Username<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                value={values.username}
                                                name="username"
                                                onChange={handleChange}
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: "#CBD5E1",
                                                        backgroundColor: "black",
                                                        "&:-webkit-autofill": {
                                                            backgroundColor: "black !important",
                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {ConditionalRender(errors, touched, 'username',
                                            <div className="errors">{errors.username}</div>
                                        )}
                                        <div className="errors">{this.state.apiError}</div>
                                        <Grid className="fields">
                                            <Box><label className="label">Date Of Birth<span>*</span></label></Box>
                                            <Grid container spacing={2} sx={{ marginTop: "4%" }}>
                                                <Grid item xs={4}>
                                                    <Box sx={{ marginBottom: "8px" }}>
                                                        <Typography variant="body2" className="label">Day</Typography>
                                                    </Box>
                                                    <FormControl fullWidth variant="outlined"

                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    borderColor: "#FFFFFF",
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: "#FFFFFF",
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#FFFFFF",
                                                                },
                                                                "& .MuiSelect-select": {
                                                                    color: "#FFFFFF",
                                                                },
                                                            },
                                                            "& .MuiInputLabel-root": {
                                                                color: "transparent",
                                                            },
                                                            "& .MuiSvgIcon-root": {
                                                                color: "#FFFFFF",
                                                            },
                                                        }}>
                                                        <Select
                                                            value={values.day}
                                                            name="day"
                                                            onChange={handleChange}
                                                            displayEmpty
                                                            defaultValue=""
                                                            data-test-id="dayBtn"
                                                            IconComponent={(props) => (
                                                                <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        maxHeight: 250,
                                                                        overflowY: 'auto',
                                                                        backgroundColor: '#44403C',
                                                                        "& .MuiMenuItem-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                        "& .Mui-selected": {
                                                                            backgroundColor: "#333333 !important",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            sx={{ color: "#FFFFFF" }}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                DD
                                                            </MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={2}>2</MenuItem>
                                                            <MenuItem value={3}>3</MenuItem>
                                                            <MenuItem value={4}>4</MenuItem>
                                                            <MenuItem value={5}>5</MenuItem>
                                                            <MenuItem value={6}>6</MenuItem>
                                                            <MenuItem value={7}>7</MenuItem>
                                                            <MenuItem value={8}>8</MenuItem>
                                                            <MenuItem value={9}>9</MenuItem>
                                                            <MenuItem value={10}>10</MenuItem>
                                                            <MenuItem value={11}>11</MenuItem>
                                                            <MenuItem value={12}>12</MenuItem>
                                                            <MenuItem value={13}>13</MenuItem>
                                                            <MenuItem value={14}>14</MenuItem>
                                                            <MenuItem value={15}>15</MenuItem>
                                                            <MenuItem value={16}>16</MenuItem>
                                                            <MenuItem value={17}>17</MenuItem>
                                                            <MenuItem value={18}>18</MenuItem>
                                                            <MenuItem value={19}>19</MenuItem>
                                                            <MenuItem value={20}>20</MenuItem>
                                                            <MenuItem value={21}>21</MenuItem>
                                                            <MenuItem value={22}>22</MenuItem>
                                                            <MenuItem value={23}>23</MenuItem>
                                                            <MenuItem value={24}>24</MenuItem>
                                                            <MenuItem value={25}>25</MenuItem>
                                                            <MenuItem value={26}>26</MenuItem>
                                                            <MenuItem value={27}>27</MenuItem>
                                                            <MenuItem value={28}>28</MenuItem>
                                                            <MenuItem value={29}>29</MenuItem>
                                                            <MenuItem value={30}>30</MenuItem>
                                                            <MenuItem value={31}>31</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Box sx={{ marginBottom: "8px" }}>
                                                        <Typography variant="body2" className="label">Month</Typography>
                                                    </Box>
                                                    <FormControl fullWidth variant="outlined" sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#FFFFFF",
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "transparent",
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            color: "#FFFFFF",
                                                        },
                                                    }}>
                                                        <Select
                                                            value={values.month}
                                                            name="month"
                                                            onChange={handleChange}
                                                            displayEmpty
                                                            defaultValue=""
                                                            data-test-id="monthBtn"
                                                            IconComponent={(props) => (
                                                                <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        maxHeight: 250,
                                                                        overflowY: 'auto',
                                                                        backgroundColor: '#44403C',
                                                                        "& .MuiMenuItem-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                        "& .Mui-selected": {
                                                                            backgroundColor: "#333333 !important",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            sx={{ color: "#FFFFFF" }}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                MM
                                                            </MenuItem>
                                                            <MenuItem value={1}>January</MenuItem>
                                                            <MenuItem value={2}>February</MenuItem>
                                                            <MenuItem value={3}>March</MenuItem>

                                                            <MenuItem value={4}>April</MenuItem>
                                                            <MenuItem value={5}>May</MenuItem>
                                                            <MenuItem value={6}>June</MenuItem>

                                                            <MenuItem value={7}>July</MenuItem>
                                                            <MenuItem value={8}>August</MenuItem>
                                                            <MenuItem value={9}>September</MenuItem>

                                                            <MenuItem value={10}>October</MenuItem>
                                                            <MenuItem value={11}>November</MenuItem>
                                                            <MenuItem value={12}>December</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box sx={{ marginBottom: "8px" }}>
                                                        <Typography variant="body2" className="label">Year</Typography>
                                                    </Box>
                                                    <FormControl fullWidth variant="outlined" sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#FFFFFF",
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "transparent",
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            color: "#FFFFFF",
                                                        },
                                                    }}>
                                                        <Select
                                                            value={values.year}
                                                            name="year"
                                                            onChange={handleChange}
                                                            displayEmpty
                                                            defaultValue=""
                                                            data-test-id="yearBtn"
                                                            IconComponent={(props) => (
                                                                <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        maxHeight: 250,
                                                                        overflowY: 'auto',
                                                                        backgroundColor: '#44403C',
                                                                        "& .MuiMenuItem-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                        "& .Mui-selected": {
                                                                            backgroundColor: "#333333 !important",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            sx={{ color: "#FFFFFF" }}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                YYYY
                                                            </MenuItem>
                                                            <MenuItem value={2024}>2024</MenuItem>
                                                            <MenuItem value={2023}>2023</MenuItem>
                                                            <MenuItem value={2022}>2022</MenuItem>
                                                            <MenuItem value={2021}>2021</MenuItem>
                                                            <MenuItem value={2020}>2020</MenuItem>
                                                            <MenuItem value={2019}>2019</MenuItem>
                                                            <MenuItem value={2018}>2018</MenuItem>
                                                            <MenuItem value={2017}>2017</MenuItem>
                                                            <MenuItem value={2016}>2016</MenuItem>
                                                            <MenuItem value={2015}>2015</MenuItem>
                                                            <MenuItem value={2014}>2014</MenuItem>
                                                            <MenuItem value={2013}>2013</MenuItem>
                                                            <MenuItem value={2012}>2012</MenuItem>
                                                            <MenuItem value={2011}>2011</MenuItem>
                                                            <MenuItem value={2010}>2010</MenuItem>
                                                            <MenuItem value={2009}>2009</MenuItem>
                                                            <MenuItem value={2008}>2008</MenuItem>
                                                            <MenuItem value={2007}>2007</MenuItem>
                                                            <MenuItem value={2006}>2006</MenuItem>
                                                            <MenuItem value={2005}>2005</MenuItem>
                                                            <MenuItem value={2004}>2004</MenuItem>
                                                            <MenuItem value={2003}>2003</MenuItem>
                                                            <MenuItem value={2002}>2002</MenuItem>
                                                            <MenuItem value={2001}>2001</MenuItem>
                                                            <MenuItem value={2000}>2000</MenuItem>
                                                            <MenuItem value={1999}>1999</MenuItem>
                                                            <MenuItem value={1998}>1998</MenuItem>
                                                            <MenuItem value={1997}>1997</MenuItem>
                                                            <MenuItem value={1996}>1996</MenuItem>
                                                            <MenuItem value={1995}>1995</MenuItem>
                                                            <MenuItem value={1994}>1994</MenuItem>
                                                            <MenuItem value={1993}>1993</MenuItem>
                                                            <MenuItem value={1992}>1992</MenuItem>
                                                            <MenuItem value={1991}>1991</MenuItem>
                                                            <MenuItem value={1990}>1990</MenuItem>
                                                            <MenuItem value={1989}>1989</MenuItem>
                                                            <MenuItem value={1988}>1988</MenuItem>
                                                            <MenuItem value={1987}>1987</MenuItem>
                                                            <MenuItem value={1986}>1986</MenuItem>
                                                            <MenuItem value={1985}>1985</MenuItem>
                                                            <MenuItem value={1984}>1984</MenuItem>
                                                            <MenuItem value={1983}>1983</MenuItem>
                                                            <MenuItem value={1982}>1982</MenuItem>
                                                            <MenuItem value={1981}>1981</MenuItem>
                                                            <MenuItem value={1980}>1980</MenuItem>
                                                            <MenuItem value={1979}>1979</MenuItem>
                                                            <MenuItem value={1978}>1978</MenuItem>
                                                            <MenuItem value={1977}>1977</MenuItem>
                                                            <MenuItem value={1976}>1976</MenuItem>
                                                            <MenuItem value={1975}>1975</MenuItem>
                                                            <MenuItem value={1974}>1974</MenuItem>
                                                            <MenuItem value={1973}>1973</MenuItem>
                                                            <MenuItem value={1972}>1972</MenuItem>
                                                            <MenuItem value={1971}>1971</MenuItem>
                                                            <MenuItem value={1970}>1970</MenuItem>
                                                            <MenuItem value={1969}>1969</MenuItem>
                                                            <MenuItem value={1968}>1968</MenuItem>
                                                            <MenuItem value={1967}>1967</MenuItem>
                                                            <MenuItem value={1966}>1966</MenuItem>
                                                            <MenuItem value={1965}>1965</MenuItem>
                                                            <MenuItem value={1964}>1964</MenuItem>
                                                            <MenuItem value={1963}>1963</MenuItem>
                                                            <MenuItem value={1962}>1962</MenuItem>
                                                            <MenuItem value={1961}>1961</MenuItem>
                                                            <MenuItem value={1960}>1960</MenuItem>
                                                            <MenuItem value={1959}>1959</MenuItem>
                                                            <MenuItem value={1958}>1958</MenuItem>
                                                            <MenuItem value={1957}>1957</MenuItem>
                                                            <MenuItem value={1956}>1956</MenuItem>
                                                            <MenuItem value={1955}>1955</MenuItem>
                                                            <MenuItem value={1954}>1954</MenuItem>
                                                            <MenuItem value={1953}>1953</MenuItem>
                                                            <MenuItem value={1952}>1952</MenuItem>
                                                            <MenuItem value={1951}>1951</MenuItem>
                                                            <MenuItem value={1950}>1950</MenuItem>
                                                        </Select>

                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {ConditionalRender(errors, touched, 'day', <div className="errors">{errors.day}</div>)}

                                        <Grid className="fields">
                                            <Box><label className="label">Password<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                type={ResolveCondition(this.state.enablePasswordField, 'password', 'text')}
                                                value={values.password}
                                                name="password"
                                                onChange={handleChange}
                                                data-test-id="txtInputPassword"
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: "#CBD5E1",
                                                        backgroundColor: "black",
                                                        "&:-webkit-autofill": {
                                                            backgroundColor: "black !important",
                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                        },
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                                edge="end"
                                                                sx={{ color: "#CBD5E1" }}
                                                                data-test-id="clickshowPassword"
                                                            >
                                                                <img
                                                                    src={ResolveCondition(this.state.enablePasswordField, visibility_icon, visibility_cross)}
                                                                    alt="toggle visibility"
                                                                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        {ConditionalRender(errors, touched, 'password', <div className="errors">{errors.password}</div>)}
                                        <Box className="password-validation">
                                            <ul style={{ listStylePosition: 'inside', padding: 0, color: "white", fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                                                <li style={{
                                                    listStyleType: 'disc',
                                                    color: "white"
                                                }}>Your Password Must Contain : </li>
                                                <li
                                                    style={{
                                                        listStyleType: /[A-Z]/.test(values.password) ? 'disc' : '"X"',
                                                        color: /[A-Z]/.test(values.password) ? "green" : "red",
                                                    }}
                                                >
                                                    {" "} At least one capital letter
                                                </li>
                                                <li
                                                    style={{
                                                        listStyleType: /[a-z]/.test(values.password) ? 'disc' : '"X"',
                                                        color: /[a-z]/.test(values.password) ? "green" : "red",
                                                    }}
                                                >
                                                    {" "} At least one lowercase letter
                                                </li>
                                                <li
                                                    style={{
                                                        listStyleType: /\d/.test(values.password) ? 'disc' : '"X"',
                                                        color: /\d/.test(values.password) ? "green" : "red",
                                                    }}
                                                >
                                                    {" "} At least one number
                                                </li>
                                                <li
                                                    style={{
                                                        listStyleType: values.password.length >= 8 ? 'disc' : '"X"',
                                                        color: values.password.length >= 8 ? "green" : "red",
                                                    }}
                                                >
                                                    {" "}  Minimum character length is 8
                                                </li>
                                            </ul>
                                        </Box>


                                    <Grid className="fields">
                                        <Box><label className="label">Confirm Password<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                            value={values.confirm_password}
                                            name="confirm_password"
                                            type={ResolveCondition(this.state.enableConfirmPasswordField,"password","text")}
                                            onChange={(e) => {this.handleConfirmPasswordChange(e,setFieldValue,values)}}
                                            data-test-id="txtInputConfirmPassword"
                                            sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputBase-input": {
                                                    backgroundColor: "black", 
                                                    color: "#CBD5E1",
                                                    "&:-webkit-autofill": {
                                                      backgroundColor: "black !important", 
                                                      WebkitTextFillColor: "#CBD5E1 !important", 
                                                      WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                    },
                                                  },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" id="Registration">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowConfirmPassword}
                                                            edge="end"
                                                            sx={{ color: "#CBD5E1" }}
                                                            data-test-id="confirmClickPassword"
                                                        >
                                                            <img
                                                                src={ResolveCondition(this.state.enableConfirmPasswordField,visibility_icon,visibility_cross)} 
                                                                alt="toggle visibility"
                                                                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </Grid>
                                    {this.state.passwordMismatch && values.confirm_password && (
                                            <div className="errors">Password Do Not Match</div>
                                        )}
                                    {ConditionalRender(errors, touched,'confirm_password',<div className="errors">{errors.confirm_password}</div>)}  
                                    <Grid className="fields">
                                        <Box><label className="label">Location<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                            value={values.location}                                            
                                            onChange={handleChange}
                                            name="location"
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                    backgroundColor: "black", 
                                                    "&:-webkit-autofill": {
                                                      backgroundColor: "black !important", 
                                                      WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                      WebkitTextFillColor: "#CBD5E1 !important", 
                                                    },
                                                  },
                                            }}
                                        />

                                        </Grid>
                                        {ConditionalRender(errors, touched, 'location', <div className="errors">{errors.location}</div>)}

                                        <Grid className="fields">
                                            <Box><label className="label">Email Address<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                value={values.email_address}
                                                onChange={handleChange}
                                                name="email_address"
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: "#CBD5E1",
                                                        backgroundColor: "black",
                                                        "&:-webkit-autofill": {
                                                            backgroundColor: "black !important",
                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                        },
                                                    },
                                                }}
                                            />

                                        </Grid>
                                        {ConditionalRender(errors, touched, 'email_address', <div className="errors">{errors.email_address}</div>)}

                                        <Grid className="fields">
                                            <Box><label className="label">Contact Number<span>*</span></label></Box>
                                            <PhoneInputWrapper>
                                                <PhoneInput
                                                    data-test-id="secondary_mobile_number"
                                                    country={'us'}
                                                    placeholder=""
                                                    value={values.full_number}
                                                    onChange={(value: string, data: any) => {
                                                        const countryCode = data.dialCode;
                                                        const phoneNumberWithoutCode = value.replace(countryCode, '');
                                                        setFieldValue('full_number', value);
                                                        setFieldValue('country_code', data.dialCode);
                                                        setFieldValue('contact_no', phoneNumberWithoutCode)
                                                    }}
                                                    containerStyle={{
                                                        width: "100%",
                                                        marginTop: "4%",
                                                        borderColor: "#CBD5E1",
                                                    }}
                                                    inputStyle={{
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                        borderColor: "#CBD5E1",
                                                        color: "#CBD5E1",
                                                    }}
                                                    buttonStyle={{
                                                        borderColor: "#CBD5E1",
                                                    }}
                                                />
                                            </PhoneInputWrapper>

                                        </Grid>
                                        {ConditionalRender(errors, touched, 'contact_no', <div className="errors">{errors.contact_no}</div>)}

                                        <Grid className="fields">
                                            <Box>
                                                <label className="label">Gender</label>
                                            </Box>
                                            <FormControl id="gender" fullWidth variant="outlined" sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "& .MuiSelect-select": {
                                                        color: "#CBD5E1",
                                                        "&::placeholder": {
                                                            color: "#B0B0B0",
                                                        },
                                                    },
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    "&:-webkit-autofill": {
                                                        backgroundColor: "black !important",
                                                        WebkitBoxShadow: "0 0 0 1000px black inset",
                                                        WebkitTextFillColor: "#CBD5E1 !important",
                                                    },
                                                    backgroundColor: "black",
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                            }}>
                                                <Select
                                                    value={values.gender}
                                                    onChange={handleChange}
                                                    name="gender"
                                                    id="gender"
                                                    data-test-id="gender"
                                                    displayEmpty
                                                    IconComponent={(props) => (
                                                        <KeyboardArrowDownIcon {...props} sx={{ color: "#CBD5E1" }} />
                                                    )}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 250,
                                                                overflowY: 'auto',
                                                                backgroundColor: '#44403C',
                                                                "& .MuiMenuItem-root": {
                                                                    color: "#FFFFFF",
                                                                },
                                                                "& .Mui-selected": {
                                                                    backgroundColor: "#333333 !important",
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    sx={{ color: "#CBD5E1" }}
                                                >
                                                    <MenuItem value="male">Male</MenuItem>
                                                    <MenuItem value="female">Female</MenuItem>
                                                    <MenuItem value="non-binary">Non-binary</MenuItem>
                                                    <MenuItem value="transgender">Transgender</MenuItem>
                                                    <MenuItem value="genderqueer">Genderqueer</MenuItem>
                                                    <MenuItem value="agender">Agender</MenuItem>
                                                    <MenuItem value="genderfluid">Genderfluid</MenuItem>
                                                    <MenuItem value="two-spirit">Two-Spirit</MenuItem>
                                                    <MenuItem value="prefer_not_to_say">Prefer not to say</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid className="fields">
                                            <Box>
                                                <label className="label">Race</label>
                                            </Box>
                                            <FormControl id="race" fullWidth sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "& .MuiSelect-select": {
                                                        color: "#CBD5E1",
                                                        "&::placeholder": {
                                                            color: "#B0B0B0",
                                                        },
                                                    },
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                    backgroundColor: "black",
                                                    "&:-webkit-autofill": {
                                                        backgroundColor: "black !important",
                                                        WebkitBoxShadow: "0 0 0 1000px black inset",
                                                        WebkitTextFillColor: "#CBD5E1 !important",
                                                    },
                                                },
                                            }}>
                                                <Select
                                                    value={values.race}
                                                    onChange={handleChange}
                                                    data-test-id="race"
                                                    name="race"
                                                    displayEmpty
                                                    id="race"
                                                    IconComponent={(props) => (
                                                        <KeyboardArrowDownIcon {...props} sx={{ color: "#CBD5E1" }} />
                                                    )}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 250,
                                                                overflowY: 'auto',
                                                                backgroundColor: '#44403C',
                                                                "& .MuiMenuItem-root": {
                                                                    color: "#FFFFFF",
                                                                },
                                                                "& .Mui-selected": {
                                                                    backgroundColor: "#333333 !important",
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    sx={{ color: "#CBD5E1" }}
                                                >
                                                    <MenuItem value="White or Caucasian">White or Caucasian</MenuItem>
                                                    <MenuItem value="Black or African American">Black or African American</MenuItem>
                                                    <MenuItem value="Asian">Asian</MenuItem>
                                                    <MenuItem value="Native American or Alaska Native">Native American or Alaska Native</MenuItem>
                                                    <MenuItem value="Native Hawaiian or Pacific Islander">Native Hawaiian or Pacific Islander</MenuItem>
                                                    <MenuItem value="Hispanic or Latino">Hispanic or Latino</MenuItem>
                                                    <MenuItem value="Multiracial or Biracial">Multiracial or Biracial</MenuItem>
                                                    <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                                                    <MenuItem value="other">other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>


                                        <Box className="checkBox">
                                            <img src={ResolveCondition(this.state.checkTick, checkbox, checkBoxTicked)} onClick={this.handleTick} data-test-id="tickmark" alt="checkbox" />
                                            <Box className="checkboxContent">
                                                <Box>
                                                    You have to agree with&nbsp;
                                                    <span
                                                        data-test-id="privacyPolicy"
                                                        style={{ color: "#007BFF", cursor: "pointer" }}
                                                        onClick={() => this.openLink(privacyPolicyLink)}>
                                                        Privacy policy&nbsp;
                                                        <span style={{ color: "#fff" }}>and&nbsp;</span>
                                                        <br />
                                                    </span>
                                                    <span
                                                        data-test-id="termsConditions"
                                                        style={{ color: "#007BFF", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setStorageData("Registration", JSON.stringify(values))
                                                            handleNavigation("TermsConditions", this.props)
                                                        }}>
                                                        Terms and conditions&nbsp;
                                                    </span>
                                                    to Sign up
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Button className={this.state.checkTick ? "btnContinue disabled" : "btnContinue"} data-test-id="submitBtn" type="submit"
                                            disabled={this.state.checkTick}><b>Continue</b></Button>
                                        <Box className="login"><b>Already have An Account? <a href="/" style={{ textDecoration: "none", color: "#1D4ED8", cursor: "pointer" }}>Log in</a></b></Box>

                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                    <Grid className="rightContainer">
                        <img src={signUp_background} alt="signup_bg" className="imageSignUp" />
                    </Grid>
                </Grid>
            </StyledWrapperSignUp>
        );
    }

}
const StyledWrapperSignUp = styled("div")({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .errors": {
        color: "red",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        fontSize: "12px",
        marginTop: "6px"
    },
    "& .login": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        fontSize: "12px",
        textAlign: "center",
        marginTop: "4%",
    },
    "& .btnContinue": {
        height: "4%",
        width: "100%",
        background: "#FF0807",
        color: "white !important",
        borderRadius: "8px",
        fontFamily: "Nexa-Heavy",
        fontSize: "18px",
        fontWeight: 900,
        textTransform: "capitalize",
        marginTop: "3%",
        "&:hover": {
            background: "#FF0807",
            color: "white"
        },
    },
    "& .btnContinue.disabled": {
        color: "#FFFFFF",
        background: "#B2B3B3",
    },
    "& .checkBox": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0%",
        marginTop: "7%",
        height: "6%",
        width: "20%"
    },
    "& .checkboxContent": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        fontWeight: 900,
        borderLeft: "4px solid #FF0807",
        marginLeft: "15%",
        paddingLeft: "10%",
        height: "70%",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    "& .TermsCondition": {
        color: "#1D4ED8",
    },
    "& .title": {
        fontSize: "20px",
        fontWeight: 900,
        color: "white",
        textAlign: "center",
        fontFamily: "Nexa-Heavy",
        marginTop: "7%",
        marginBottom: "4%"
    },
    "& .logoImg": {
        display: "flex",
        justifyContent: "center",
    },
    "& .fields": {
        marginTop: "5%"
    },
    "& .label": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        fontWeight: 900
    },
    "& .container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "auto",
        marginBottom: "10%",
    },
    "& .leftContainer": {
        width: "45%",
        padding: "3% 10%",
    },
    "& .rightContainer": {
        width: "55%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: "5%",
    },
    "& .imageSignUp": {
        width: "100%",
        height: "auto",
    },
})
const PhoneInputWrapper = styled(Box)({
    "& .form-control": {
        width: "100%",
        height: "48px",
        paddingLeft: "55px",
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        background: "black",
    },
    "& .selected-flag": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "50px",
        paddingRight: "15px",
        background: "black",
        border: "1px solid #fff",
        borderRight: "none"
    },
    "& .flag-dropdown": {
        border: "none",
        "&:hover .selected-flag": {
            background: "#333",
        },
    },
    "& .selected-flag:hover": {
        background: "#333",
        cursor: "pointer",
    },

    "& .suggestion-item": {
        background: "black",
        color: "#fff",
        "&:hover": {
            background: "#333",
        }
    },
})


// Customizable Area End
