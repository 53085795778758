Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.done = "Done";
exports.box2Image = "box";
exports.getActivityFeedApiEndPoint = "bx_block_activity_feed/activity_feeds";
// Customizable Area End
