// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Search = require("../assets/Search.png");
export const close = require("../assets/close.png");
export const check = require("../assets/check.png");
export const check_img = require("../assets/check.png");
export const close_img = require("../assets/close.png");
export const left_img = require("../assets/left.png");
export const pdf_img = require("../assets/pdf.png");
export const search_img = require("../assets/Search.png");
export const  upload = require("../assets/upload.png").default;
export const podcastCover = require("../assets/podcastCover.png").default;
export const image_group = require("../assets/image_group.png").default;
export const image_group_1 = require("../assets/image_group_1.png").default;
export const goBack = require("../assets/goBack.svg").default;
export const tickIcon = require("../assets/button.png").default;

// Customizable Area End
