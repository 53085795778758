import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { carouselNext, carouselPrev, headPhone, playBtn, podcastImage1 } from "./assets";
import { AppContext } from "./context/AppContext";
import React from "react";
import { getStorageData } from "framework/src/Utilities";
export type MediaList = Media[]

export interface Media {
  itmid: string
  type: string
  attributes: { file_info: FileInfo[] }
}

export interface FileInfo {
  title: string
  description: string
  focus_areas: string[]
  urldoc: string
  content_type: string
  text_file_to_str: string
  file_content: string
}

interface Song {
  songTitle: string;
  songSubTitle: string;
  image: string; 
}

interface Playlist {
  bgcolor: string;
  playIcon: string; 
  title: string;
  playLists: Song[];
}

interface PodcastList {
  id?: string,
  imageUrl: string;
  songName: string;
  artist: string;
  following: string
}

interface SavedEpisode {
  imageUrl: string;
  selected: boolean;
  added: boolean;
  songName: string;
  artist: string;
}
interface ArrowProps {
  onClick: () => void;
}

interface ChannelLibraryData {
  name: string;
  description: string;
  cover_image: string | null;
  episode_content: string | null;
  liked: boolean;
  saved: boolean;
}

interface ChannelLibraryApiResponse {
  data: {
      id: string;
      type: string;
      attributes: {
          name: string;
          description: string;
          cover_image: string | null;
          episode_content: string | null;
          save_episode: { saved: boolean };
          liked_episode: { liked: boolean };
      };
  }[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  mediaList: MediaList;
  header: string;
  loading: boolean;
  documentModal: boolean;
  docUrl: string;
  videoModal: boolean;
  videoUrl: string;
  media: {
    nowPlaying: string;
    duration: number;
    progress: number;
  };
  mediaType: string;
  pageNo: number;
  limit: number;
  totalCount: number;
  currentPageNo: number;
  audioList: MediaList;
  continueListen: Playlist[];
  followingPodCasts: PodcastList[];
  savedEpisode: SavedEpisode[];
  channelLibrary: ChannelLibraryData[]
  followingPodcastCurrentPage: number;
  savedPodcastCurrentPage: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

export default class AudioLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaApiCallId: string = "";
  getSavedPoscastApiCallId: string = "";
  getChannelLibraryApiCallId: string = "";
  getSavedEpisodeApiCallId: string = "";
  followingPodcastitemsPerPage = 6;
  savedPodcastItemsPerPage = 6;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ChannelLibraryResponse)
    ];
    // Customizable Area End
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      mediaList: [],
      audioList: [],
      header: "Lets listen to some audios to make yourself feel better.",
      loading: false,
      documentModal: false,
      docUrl: "",
      videoModal: false,
      videoUrl: "",
      media: {
        nowPlaying: "",
        duration: 0,
        progress: 0
      },
      mediaType: 'audio',
      pageNo: 0,
      limit: 7,
      totalCount: 0,
      currentPageNo: 0,
      continueListen: [
        {
          bgcolor: "red",
          playIcon: playBtn,
          title: "Main playList",
          playLists: [
            { songTitle: "The True", songSubTitle: "Cost of success Ep.9", image: podcastImage1 },
            { songTitle: "This American Life", songSubTitle: "Love your era Ep.8", image: playBtn },
            { songTitle: "Scam Goddess", songSubTitle: "Catalina appears Ep.6", image: playBtn},
          ],
        },
        {
          bgcolor: "white",
          playIcon: headPhone,
          title: "Saved Episodes",
          playLists: [
            { songTitle: "Ep 3 - Its Jhonnie" , songSubTitle: "The Edge of Sleep", image: playBtn },
            { songTitle: "Ep 3 - Oh captain my captain" , songSubTitle: "Philosophize This!", image: playBtn},
            { songTitle: "Ep 12 - Its Britney B*'tch!" , songSubTitle: "Toxic Britney Spears story", image: playBtn},
          ],
        },
      ],
      followingPodCasts : [],
      savedEpisode: [],
      channelLibrary: [],
      followingPodcastCurrentPage: 0,
      savedPodcastCurrentPage: 0
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      if (apiRequestCallId === this.getMediaApiCallId) {
        if (responseJson && responseJson?.data) {
          this.setState(prev => ({
            totalCount: responseJson?.data[0].attributes?.count,
            audioList: responseJson?.data,
            mediaList: (prev.pageNo === 0) ?
              responseJson?.data :
              [...this.state.mediaList, ...responseJson?.data] || [],
            loading: false
          }));
        }
      }

    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson: ChannelLibraryApiResponse  = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.getChannelLibraryApiCallId) {
        const episodes = responseJson.data.map((item) => {
          const attributes = item.attributes;
          return {
              name: attributes.name,
              description: attributes.description,
              cover_image: attributes.cover_image,
              episode_content: attributes.episode_content,
              liked: attributes.liked_episode.liked,
              saved: attributes.save_episode.saved,
          };
      });
      this.setState({channelLibrary: episodes})
      }
      
      if(apiRequestCallId === this.getSavedPoscastApiCallId) {
        const transformedData = responseJson.data.map((podcast:any) => {
          const id = podcast.id;
          const attributes = podcast.attributes.followable.data.attributes;
          const categoryImage = attributes?.cover_image?.image_link || "";
          const host = attributes.hosts[0];
          const following = attributes.follower.following;
          return {
            id: id,
            imageUrl: categoryImage,
            songName: `${host.first_name} ${host.last_name}`,
            artist: attributes.name,
            following: following,
          };
        });

        this.setState({ followingPodCasts: transformedData });
      }

      if(apiRequestCallId === this.getSavedEpisodeApiCallId) {
        const formattedData = responseJson.data.map((item:any) => {
          const saveable = item.attributes?.saveable?.data?.attributes;
          return {
            imageUrl: saveable.cover_image,
            added: saveable.added?.song_added,
            songName: saveable.name,
            selected: saveable.save_episode?.saved,
            artist: `${saveable.account?.first_name || ''} ${saveable.account?.last_name || ''}`.trim()
          };
        });
      
        this.setState({ savedEpisode: formattedData });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType? = AppContext;

  handleSavedEpisode = () => {
    this.navigateTo("SavedEpisode")
  }

  handlePodCastFollowing = () => {
    this.navigateTo("PodCastFollowing")
  }

  handleChannelLibrary = () => {
    this.navigateTo("ChannelLibrary")
  }

  navigateTo(screen:string){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
  }

  getSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
              dots: true
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 4,
              dots: true
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
        ]
       }
    )
  }

  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        src={carouselNext} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: "1%",
          top: innerWidth > 1440 ? "45%" : "50%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }

  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={carouselPrev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-4.5%" : "-5.5%",
          top: innerWidth > 1440 ? "45%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }


  async componentDidMount(): Promise<void> {
    this.getSavedPodCastList();
    this.getSavedEpisode();
    this.getMedia();
    this.getPodCastList();


    const msag: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msag);
  }

  handlePagination = (event: Object, value: number) => {
    this.setState({ currentPageNo: value });
    this.getMedia(value);
  }

  networkRequest = ({
    endPoint,
    method,
    headers,
    body,
    newState
  }: {
    endPoint: string;
    headers?: Record<string, string>;
    body?: Record<string, string>;
    method: "GET" | "POST" | "PUT" | "DELETE";
    newState?: Partial<S>;
  }) => {
    if (typeof newState === "object") {
      this.setState(prev => ({ ...prev, ...newState }));
    }
    const defaultHeaders = {
      'Content-Type': configJSON.exampleApiContentType,
      token: (this.context as any).state?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers || defaultHeaders)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getMedia = (currentPageNo?: number) => {
    let strBody = {
      limit: `${this.state.limit}`,
      page: `${currentPageNo || this.state.pageNo}`
    }
    let urlParams = new URLSearchParams(strBody).toString();
    this.getMediaApiCallId = this.networkRequest({
      endPoint: `audio_list?${urlParams}`,
      method: "POST",
      newState: { loading: false }
    });
  }

  fetchMoreData = () => {
    if (
      (this.state.pageNo >= this.state.totalCount) ||
      this.state.loading
    ) {
      return;
    }
    this.setState(prevState => ({
      pageNo: prevState.pageNo + 1
    }), () => this.getMedia());
  }

  getPodCastList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChannelLibraryApiCallId = requestMessage?.messageId

    let podcast_id = await getStorageData("podcast_id");

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    // let podcast_id = 83;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/episodes?podcast_id=${podcast_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyMediaURlPresent = (channelData: ChannelLibraryData[]): boolean => {
    return channelData.every((item: ChannelLibraryData) => item.episode_content !== null);
  };

  goBackcalling = () => {
    this.props.navigation.goBack()
  }

  getSavedPodCastList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedPoscastApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/followers?type=podcast`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSavedEpisode = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedEpisodeApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/saved_episodes?type=episode`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFollowingPodCastData = () => {
    const startIndex = this.state.followingPodcastCurrentPage * this.followingPodcastitemsPerPage;
    const followingPodCastData =  this.state.followingPodCasts.slice(startIndex, startIndex + this.followingPodcastitemsPerPage);
    return followingPodCastData;
  }

  handleDotClick = (index:any) => {
    this.setState({ followingPodcastCurrentPage: index });
  };

  handleSliderNextPodcastCardClick = () => {
    const { followingPodcastCurrentPage, followingPodCasts } = this.state;
    const totalPages = Math.ceil(followingPodCasts.length / this.followingPodcastitemsPerPage);
    if (followingPodcastCurrentPage < totalPages - 1) {
      this.setState({ followingPodcastCurrentPage: followingPodcastCurrentPage + 1 });
    }
  };

  handleSliderPreviousPodcastCardClick = () => {
    const { followingPodcastCurrentPage } = this.state;
    if (followingPodcastCurrentPage > 0) {
      this.setState({ followingPodcastCurrentPage: followingPodcastCurrentPage - 1 });
    }
  };

  handleSavedPostDotClick = (index:any) => {
    this.setState({ 
      savedPodcastCurrentPage: index 
    });
  };

  handleSliderNextSavedPostClick = () => {
    const { savedPodcastCurrentPage, savedEpisode } = this.state;
    const totalPages = Math.ceil(savedEpisode.length / this.savedPodcastItemsPerPage);
    if (savedPodcastCurrentPage < totalPages - 1) {
      this.setState({ savedPodcastCurrentPage: savedPodcastCurrentPage + 1 });
    }
  };

  handleSliderPreviousSavedPostClick = () => {
    const { savedPodcastCurrentPage } = this.state;
    if (savedPodcastCurrentPage > 0) {
      this.setState({ savedPodcastCurrentPage: savedPodcastCurrentPage - 1 });
    }
  };

  getSavedEpisodeData = () => {
    const startIndex = this.state.savedPodcastCurrentPage * this.savedPodcastItemsPerPage;
    const savedEpisode =  this.state.savedEpisode.slice(startIndex, startIndex + this.savedPodcastItemsPerPage);
    return savedEpisode;
  }

  totalPodcastPages = () => {
    const data = Math.ceil(this.state.followingPodCasts.length / this.followingPodcastitemsPerPage) - 1
    return this.state.followingPodcastCurrentPage === data;
  }

  totalSavedEpisodePages = () => {
    const data = Math.ceil(this.state.savedEpisode.length / this.savedPodcastItemsPerPage) - 1;
    return this.state.savedPodcastCurrentPage === data
  }

  // Customizable Area End
}
