
import PlaylistCard from "./components/PlaylistCard.web";
import PlayListScreenController, {Props} from "./PlayListScreenController.web";
import { Box, Typography, Button, Dialog, TextField, styled } from "@mui/material";
import { telephoneImg } from "./assets";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import { PlaylistItem } from "./components/PlaylistItem.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { upload } from "../../../blocks/portfoliomanagement/src/assets";
import Divider from '@mui/material/Divider'
import Slider from "react-slick";


const MIN_WINDOW_HEIGHT = 900


export default class PlayListScreen extends PlayListScreenController {
    constructor(props: Props) {
      super(props);
    }
  
    render() {
        console.log(this.state.playlistItem, "playlistItem")
        console.log(this.state.selectedPlaylistInfo.title, "selectedPlaylistINfo")
        return (
            <>
            <div style={webStyles.container}>
            <Typography style={webStyles.title} padding={"16px 0px"}>Playlist</Typography>
            
            <Box display={"flex"} gap={2}> 
                {/* <PlaylistCard
                    borderColor={"#00000033"}
                    imageUrl={telephoneImg}
                    overlayColor="#00000033"
                    title="Add Playlist"
                    icon="control"
                    onClick={this.handleCreatePlaylist}
                /> */}

            </Box>
            {
                this.state.playlistItem.length > 6 ? (
                    <StyledSlider {...this.getPlaylistSlickSettings()}>
                    <PlaylistCard
                        data-test-id="createPlaylistCard"
                        borderColor={"#00000033"}
                        imageUrl={telephoneImg}
                        overlayColor="#00000033"
                        title="Add Playlist"
                        icon="control"
                        onClick={ () => this.handleCreatePlaylist("create")}
                    />
                    {this.state.playlistItem.map((data) => (
                        <PlaylistCard
                        key={data.id} // Always provide a unique key
                        imageUrl={data.image}
                        borderColor={ data.id === this.state.selectedPlaylist ? "#FF0807" : ""}
                        overlayColor={ data.id === this.state.selectedPlaylist ? "rgba(255, 8, 7, 0.7)" : "#00000033"}
                        title={data.title}
                        subtitle={data.type}
                        onClick={() => this.handleCreatePlaylist(data.id)}
                        icon={"play"}
                        />
                    ))}
                    </StyledSlider>
                ) : (
                    <Box display={"flex"} gap={2}> 
                    <PlaylistCard
                        borderColor={"#00000033"}
                        imageUrl={telephoneImg}
                        overlayColor="#00000033"
                        title="Add Playlist"
                        icon="control"
                        onClick={ () => this.handleCreatePlaylist("create")}
                    />
                    {this.state.playlistItem.map((data) => (
                        <PlaylistCard
                        key={data.id} // Provide unique key here too
                        imageUrl={data.image}
                        overlayColor="#00000033"
                        title={data.title}
                        subtitle={data.type}
                        onClick={() => this.handleCreatePlaylist(data.id)}
                        />
                    ))}
                    </Box>
                )
            }

            <Box display="flex" justifyContent={"space-between"} mt={6}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center", // Centers content vertically
                        justifyContent: "center", // Centers content horizontally
                        borderBottom: "none",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        width: "207px",
                        height: "50px",
                        textAlign: "center",
                        backgroundColor: "#44403C",
                        color: "white",
                        fontFamily: "Nexa-Heavy",
                        fontWeight:900,
                        fontSize:"18px",

                    }}
                    >
                    { !this.state.selectedPlaylistInfo.title ? "Add" : this.state.selectedPlaylistInfo.title}
                </Box>  
                <Box display={"flex"} gap={1} mb={1}>
                    <Button
                        sx={{
                        ...buttonStyles,
                        border: "1px solid white",
                        color: "white",
                        "&:hover": {
                            border: "1px solid white",
                            color: "white", 
                        }
                        }}
                        variant="outlined"
                        startIcon={<DeleteOutlineIcon />}
                    >
                        Delete playlist
                    </Button>
                    <Button
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807",
                        "&:hover": {
                            background: "#FF0807", 
                        }
                        }}
                        variant="contained"
                        startIcon={<EditIcon />}
                    >
                        Edit My Playlist Info
                    </Button>
                    <Button
                        data-test-id="AddShowsToMyPlaylist"
                        onClick={() => this.handleAddShows()}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807",
                        "&:hover": {
                            background: "#FF0807", 
                        }
                        }}
                        variant="contained"
                        startIcon={<PlaylistAddIcon />}
                    >
                        Add Shows To My Playlist
                    </Button>
                    <Button
                    data-test-id="fullList"
                    onClick={() => this.handleFullList()}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807",
                        "&:hover": {
                            background: "#FF0807", 
                        }
                        }}
                        variant="contained"
                        startIcon={<PlaylistPlayIcon />}
                    >
                        See Full Playlist
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                backgroundColor: "#44403C",
                padding: "16px 24px",
                color: "white",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                marginBottom:"24px"
                }}
            >
                {
                    this.state.selectedPlaylistInfo.songs.length > 0 ?

                    this.state.selectedPlaylistInfo.songs.map((data) => {
                        return (
                            <PlaylistItem
                            imageSrc={data?.cover_image}
                            title={data?.name}
                            subtitle={data?.description}
                            date={data?.publish_date}
                            duration={data?.episode_time}
                            song_added={data.song_added}
                        />
                        )
                    })
                    :
                    <Typography sx={{...webStyles.title, fontSize:"16px", marginBottom:"16px"}}>Select a playlist to view the list of songs. If the selected playlist doesn't contain any songs, please add some</Typography>
                }
                
                
                {/* <PlaylistItem
                    imageSrc={kiddingImg}
                    title="Just Kidding"
                    subtitle="Karen Whilliams"
                    date="Yesterday"
                    duration="1:50:00"
                />
                 <PlaylistItem
                    imageSrc={japaneseImg}
                    title="Japanese Room"
                    subtitle="Hellan Joe"
                    date="July 17th 2024"
                    duration="1:50:00"
                />
                <PlaylistItem
                    imageSrc={coupleImg}
                    title="A couple vino's deep"
                    subtitle="willian & clau Jessey"
                    date="July 17th 2024"
                    duration="1:50:00"
                />
                <PlaylistItem
                    imageSrc={beautyImg}
                    title="Beauty and shade"
                    subtitle="Marie Bones"
                    date="December 17th 2024"
                    duration="1:50:00"
                /> */}
            </Box>
        
            <Footer navigation={this.props.navigation} id={"1"} />
            </div>


            {
                this.state.openNewPlayListModal && 
                <Dialog
                open={this.state.openNewPlayListModal}
                onClose={() => {}}
                slotProps={{
                  backdrop: {
                    sx: {
                      backdropFilter: 'blur(5px)', // Apply blur effect
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  },
                }}
                sx={{
                  "& .MuiDialog-paper": {
                    maxWidth: "800px",
                    width: "600px",
                    background: "#44403C",
                    borderRadius: '8px 8px 32px 8px',
                  },
                }}
                data-test-id="PlaylistModal"
              >
                <Box onClick = {this.handleCloseCreatePlaylist} display={"flex"} justifyContent={"flex-end"} padding={3}>
                    <CloseIcon sx={{color:"white"}} />
                </Box>
                <Box padding={"16px 40px"}>

                <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"16px"}}>New Playlist</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography sx={{...webStyles.title, fontSize:"14px"}} component="label">
                        Name of playlist
                    </Typography>
                    <TextField
                        data-test-id="playlistName"
                        value={this.state.playlistValue}
                        onChange={this.handleInputChange} 
                        variant="outlined"
                        sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: '#030303', 
                            borderRadius: "8px",
                            color:"white",
                            fontFamily: "Nexa-Heavy",
                            fontWeight: 900,
                            fontSize: "14px"
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                      
                        }}
                    />
                </Box>

                <Typography sx={{...webStyles.title, fontSize:"14px",}}>Playlist photo (optional)</Typography>

                <Box display={"flex"} gap={2}>
                {this.state.filePreview && (
                    <Box padding={2} height={"160px"} width={"160px"} borderRadius={"6px"} bgcolor={"#312F2D"}>
                        <Box display={"flex"} justifyContent={"flex-end"} data-test-id="unSelectImage" onClick={this.handleUnselectImage}> 
                            <CloseIcon sx={{color:"white"}} /> 
                        </Box>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                                <img style={{width:"89px", height:"89px", borderRadius:"6px", objectFit:"cover"}} src={this.state.filePreview} alt="selected image" />
                                {this.state.selectedFile && (
                                    <>
                                    <Typography color={"white"} fontFamily={"Inter"} fontSize={"1rem"} fontWeight={400}>{this.state.selectedFile.name}</Typography>
                                    <Typography color={"white"} fontFamily={"Inter"} fontSize={"12px"} fontWeight={400}>{(this.state.selectedFile.size / 1024).toFixed(2)} KB</Typography>
                                    </>
                                )}
                            </Box>
                    </Box>
                )}
                    <Box
                        sx={{
                            width: this.state.filePreview ? "340px" : "600pc",
                            backgroundColor: '#312F2D',
                            color: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            textTransform: 'none',
                            border: "3px dashed white",
                            height: "160px",
                        }}
                        >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            height={"160px"}
                            gap={2}
                        >
                            <img src={upload} alt="upload" style={{ width: "24px", height: "24px" }} />
                            <Box
                            sx={{
                                textAlign: 'center',
                                fontFamily: 'Nexa-Bold',
                                fontWeight: 700,
                                fontSize: '14px',
                            }}
                            >
                            Drag & drop your file here
                            </Box>

                            <Button
                            component="label"
                            sx={{
                                border: "none",
                                color: "white",
                                background: "#FF0807",
                                "&:hover": {
                                background: "#FF0807",
                                },
                                padding: "8px 16px",
                                textTransform: "none",
                            }}
                            
                            >
                            Browse file
                            <input
                                data-test-id="file-input"
                                type="file"
                                hidden
                                onChange={this.handleFileChange}
                            />
                            </Button>
                        </Box>
                    </Box>
                    
                </Box>
                </Box>
                <Divider sx={{ my: 2, borderColor: 'white' }} />

                <Box m={2} display={"flex"} gap={2} padding={2} justifyContent={"flex-end"}>
                    <Button
                    data-test-id="cancel"
                    onClick={this.handleCloseCreatePlaylist}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                    >
                        Cancel
                    </Button>

                    <Button
                    data-test-id="createBtn"
                    onClick={this.handlePlaylistCreate}
                    disabled={!this.state.selectedFile || !this.state.playlistValue}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807" ,
                        height:"56px",
                        "&:hover": {
                            background:  "#FF0807" ,
                        },
                        "&.Mui-disabled": {
                            backgroundColor: "#B2B3B3", // Ensures the disabled color applies consistently
                            color: "white",
                        },
                        }}
                        variant="contained"
                    >
                        { this.state.isLoading ? "Creating..." : "Create"}
                    </Button>

                </Box> 
                </Dialog>
            }
            </>
        );
    }    
}

const webStyles = {
    container: {
      padding:"20px 50px",
      overflowX: "clip"
    } as const,
    title : {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    }
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    height: "34px",
  };
  
  const StyledSlider = styled(Slider)`
  .slick-slide {
    height: auto;
  }
`;
