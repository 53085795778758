import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Grid } from "@mui/material";
import Slider from "react-slick";
import { addBtn, addedBtn, heart, selectedBtn } from "../../../dashboard/src/assets";
import Footer from "../../../../blocks/navigationmenu/src/Footer.web";
import DiscoverController, {
  Props,
} from "./DiscoverController";
import { viewAllCategories, dividerImg } from "../assets"

const MIN_WINDOW_HEIGHT = 900

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class Discover extends DiscoverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    //Merge Engine DefaultContainer
    // Customizable Area Start

    return (

      <>
        <div style={webStyles.container}>
            <Box style={{ height: "auto", width: "100%", marginBottom: "15px" }} data-test-id="continueListeningSection">
              <Typography style={webStyles.title}>Favorite Categories</Typography>
              <Box className="mainContainer">
                <Box sx={webStyles.boxContainer}>
                  {
                    this.state.favorateCategories.map((item: any) => (
                      <Box key={item.id} data-test-id="boxSelectedimg" 
                      
                      >
                        <Box style={webStyles.favCategriesbox}
                        onClick={() => this.props.handleCommonPath("SubCategories")}
                        >
                          <img src={item.src} alt={item.alt} style={webStyles.categoriesImg} />
                        </Box>

                        <Typography sx={{ ...webStyles.label, marginTop: "24px", marginRight: "5px" }}>{item.name.toUpperCase()}</Typography>
                      </Box>
                    ))
                  }
                  <img src={dividerImg} style={webStyles.dividerImg} alt="dividerImg" />
                  <Box sx={{ width: "225px", height: "289", border: "1px", borderRadius: "16px", backgroundColor: "#FF0807", display: "flex", justifyContent: "center", alignItem: "center", flexDirection: "column", textAlign: 'center', cursor: "pointer" }}
                  data-test-id="navigateAllCateries"
                  onClick={() => this.props.handleCommonPath("AllCategories")}
                  >
                    <Box >
                      <img src={viewAllCategories} alt="categries Img" style={{...webStyles.viewAllCategoriesImg}} />
                    </Box>

                    <Box>
                    <Typography sx={webStyles.label}>VIEW ALL</Typography>
                    <Typography sx={webStyles.label}>CATEGORIES</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ height: "auto", width: "100%", marginBottom: "15px" }} data-test-id="trendingPost">
              <Typography style={webStyles.title}>Trending Podcasts</Typography>
              <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap: "wrap", gap: "24px" }}>
                {this.state.trendingPodcasts.map((item: any) => (
                  <div key={item.id}>
                    <Box  sx={{width: "274px", height: "223px", position: "relative"}}>
                      <Box
                        sx={{...webStyles.followingText,
                          backgroundColor: item.followers == "Follow" ? "#FF0807" : "#030303", cursor: "pointer"
                        }}>
                        <Typography style={{ justifySelf: "flex-end", fontWeight:"bold" }}>{item.followers}</Typography>
                      </Box>
                      <img style={{ ...webStyles.image, height: "223px" }} src={item.src} alt={item.alt} />
                    </Box>
                      
                      <div style={webStyles.description}>
                        <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                          {item.name.toUpperCase()}
                        </Typography>
                        <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                          {item.artist}
                        </Typography>
                      </div>
                  </div>
                ))}
              </Box>
            </Box>
          <Box sx={{ position: 'relative', height: "430px", width: "100%", paddingTop: "3%" }} data-test-id="sponsored">
            <Typography style={webStyles.title}>Sponsored</Typography>
            <StyledSponsoredSlider {...this.getSponsoredSlickSettings()}>
              {this.state.sponsoredPodcasts.map((item: any) => (
                <div key={item.id} style={webStyles.sponsoredImgBody}>
                  <img style={webStyles.image} src={item.src} alt={item.alt} />
                  <div style={{ position: "absolute", bottom: "17%", left: "1%" }}>
                    <Typography style={{ ...webStyles.episode }}>
                      {item.episode.toUpperCase()}
                    </Typography>
                    <Typography style={{ ...webStyles.title }}>
                      {item.name.toUpperCase()}
                    </Typography>
                    <button style={webStyles.sponsoredButton}>
                      Listen now
                    </button>
                  </div>
                </div>
              ))}
            </StyledSponsoredSlider>

          </Box>
          <Box sx={webStyles.tredingEpisodesBox} data-test-id="trendingPodcast">
            <Typography style={webStyles.title}>Trending Episodes</Typography>
            <StyledSlider {...this.getSuggestedSlickSettings()}>
              {this.state.suggestedPodcasts.map((item: any) => (
                <div key={item.id} >
                  <div style={webStyles.suggestedImgBody}>
                    <img style={{ ...webStyles.image, height: "223px" }} src={item.src} alt={item.alt} />
                    {item.selected ?
                      <img src={selectedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "10px" }} /> :
                      <img src={heart} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "10px" }} />
                    }
                    {item.added ?
                      <img src={addedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "50px" }} /> :
                      <img src={addBtn} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "50px" }} />
                    }
                    <div style={webStyles.description}>
                      <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                        {item.name.toUpperCase()}
                      </Typography>
                      <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                        {item.artist}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
              
            </StyledSlider>
          </Box>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
        
      </>

    );
    // Customizable Area End
    //Merge Engine End DefaultContainer
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    padding:"20px 50px"
} as const,
  image: {
    objectFit: "cover" as const,
    width: '100%',
    height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
    display: "flex",
    margin: "0 auto",
  },
 
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#fff',
    marginBottom: "10px"
  },
  episode: {
    fontSize: '14px',
    color: "#fff",
    lineHeight: '22px',
    fontWeight: 600
  },
  sponsoredImgBody: {
    width: '100%',
    height: "370px",
    position: 'relative' as const
  },
  sponsoredButton: {
    color: "#fff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  suggestedImgBody: {
    width: '227px',
    height: "223px",
    position: 'relative' as const
  },

  boxContain: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },

  label: {
    display: "flex",
    fontWeight: "webStyles",
    fontSize: "18px",
    color: "white",
    alignItem: "center",
    justifyContent: "center",
  },

  description: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "76px",
    backgroundColor: "#44403c",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "16px"
  } as React.CSSProperties,

  "& .mainContainer": {
    color: "white",
  },
  dividerImg: {
    marginRight: "24px",
    marginLeft: "16px",
    height: "289px"
  },
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItem: "center",

  },
  categoriesImg: {
    height: "40%",
    width: "40%"
  },
  viewAllCategoriesImg: {
    height: "80px",
    width: "80px",
    selfAlign: "center",
  },
  favCategriesbox: {
    background: "#312F2D",
        height: "223px",
        width: "225px",
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin: "8px"
  },
   
  tredingEpisodesBox: {
    position: 'relative',
    height: "430px",
    paddingTop: "3%",
    paddingBottom: "2%"
  },
  followingText: {
    top: "15px",
    right: "15px",
    position: "absolute",
    color: "white",
    borderRadius: "8px",
    padding: "8px"
}
};
const StyledSlider = styled(Slider)`
    .slick-dots {
      bottom: 56px;
    }
    .slick-dots li {
      margin: 0px !important;
    }
  `;
const StyledSponsoredSlider = styled(Slider)`
    .slick-dots {
      bottom: 40px;
    }
    .slick-dots li {
      margin: 0px !important;
    }
    .slick-dots li button:before {
      color: #B2B3B3; 
      opacity: 1;   
      font-size: 10px;
    }
    .slick-dots li.slick-active button:before {
      color: #B2B3B3 !important; 
      font-size: 14px; 
    }
  `;

// Customizable Area End
