// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  openModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface Form {
  resetPassword: string,
  confirmPassword: string
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showNewPassword: false,
      showConfirmPassword: false,
      openModal: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleGetMessagesApiResponse(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  toggleNewPasswordVisibility = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword,
    }))
  }
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }))
  }
  resetPasswordApi = async (values: Form) => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token),
      "content-type": configJSON.validationApiContentType
    };
    let body = {
      new_password: values.resetPassword,
      new_password_confirmation: values.confirmPassword
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPassword
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetMessagesApiResponse = async (from: string, message: Message) => {
    if (this.resetPasswordApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.message === "Password succesfully updated") {
          this.setState({ openModal: true })
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  // Customizable Area End
}
// Customizable Area End
