import React, { Component } from 'react';
import {
    Card,
    CardMedia,
    CardActions, 
    Button
  } from "@mui/material";

interface BuilCardProps {
  imageUrl: string;
  buttonLabel: string;
  buttonClick: () => void
}

class BuildCard extends Component<BuilCardProps> {
  constructor(props: BuilCardProps) {
    super(props);
  }

  render() {
    return (
      <Card data-test-id = "card" sx={{ width: 300, borderRadius: "16px", backgroundColor: "#312F2D", height: "320px" }}>
        <CardMedia
          data-test-id="card-media"  
          sx={{ height: "230px", objectFit: "contain" }}
          image={this.props.imageUrl}
        />
        <CardActions sx={{ backgroundColor: "#312F2D",  justifyContent: "center", padding: "30px" }}>
          <Button
          onClick={this.props.buttonClick}
           data-test-id="card-button"
            sx={{
              backgroundColor: "red",
              color: "white",
              width: "268px",
              padding: "8px",
              textTransform:"capitalize",
              fontSize:"15px",
              fontFamily: "Nexa-Heavy",
              fontWeight:900,
              borderRadius:"8px",
              "&:hover": {
                backgroundColor: "red", 
                color: "white" 
              }
            }}
            size="small"
          >
            {this.props.buttonLabel}
          </Button>
        </CardActions>
      </Card>

    );
  }
}

export default BuildCard;


