// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostLoginController, {
    Props,
} from "./HostLoginController";
import { hostBg } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";

export default class HostLogin extends HostLoginController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <>
                <StyledWrapperSignUp>
                <Box id="loginHost">
                <Box className="container">
                    <img src={hostBg} alt="hostBg" className="image"/>
                    <Box className="subContainer">
                        <Box className="title">Become A Host</Box>
                        <Box className="desc">Start the journey to build, engage and monetize your audience</Box>
                        <Button className="btn" data-test-id = "buttonClick" onClick={()=>{this.props.handleCommonPath("HostPaymentPlan")}}>Begin to Setup My Channel</Button>                        
                    </Box>                    
                </Box>
                </Box>
                </StyledWrapperSignUp>
                <Footer navigation={this.props.navigation} id={"1"} />
            </>
           
        );
    }

}
const StyledWrapperSignUp = styled("div")({
    background: "black",
    padding: "20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .scrollHostLogin":{
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        height: "34rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
    },
    "& .btn" :{
        cursor : "pointer",
        margin : "auto",
        marginBottom : "5%",
        background : "#FF0807",
        color : "white",
        borderRadius : "8px",
        height : "56px",
        width : "272px",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        fontWeight : 900,
        fontSize : "14px",
        fontFamily : "Nexa-Heavy",
        textTransform : "capitalize",
        "&:hover" : {
            background : "#FF0807",        
        }
    },
    "& .desc":{
        margin : "auto",
        textAlign : "center",
        fontFamily : "Nexa-Regular",
        fontSize : "16px",
    },
    "& .title":{
        color : "white",
        fontFamily : "Nexa-Heavy",
        textAlign : "center",
        fontSize : "30px",
        fontWeight : 900
    },
    "& .subContainer":{
        margin : "auto",
        display:"flex",
        flexDirection : "column",
        width:"80%",
        height : "34%",
        marginTop : "2%"
    },
    "& .container":{
    color : "white",
    height:"auto",
    margin:"auto",
    background:"#44403C",
    },
    "& .image":{
        display:"flex",
        height : "50%",
        width : "80%",
        paddingTop : "5%",
        boxSizing: "content-box",
        margin:"auto",       
    }
})   
// Customizable Area End
