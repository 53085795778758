// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button, Grid, TextField
} from "@mui/material";
import HostMonetizationSponsoredPostController, {
    Props,
} from "./HostMonetizationSponsoredPostController";
import { checkbox, checkboxTicked, goBack, sponsoredPost } from "./assets";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Condition } from "../../utilities/src/Condition";

export default class HostMonetizationSponsoredPost extends HostMonetizationSponsoredPostController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
        sponsoredPost: Yup.number()
        .typeError("Please enter a number between 1 to 500.")
        .min(1, "Please enter a number between 1 to 500.")
        .max(500, "Please enter a number between 1 to 500."),
        typeOfPost: Yup.object().shape({
            audio: Yup.boolean(),
            video: Yup.boolean(),
            textOnly: Yup.boolean(),
            graphic: Yup.boolean(),
        }).test('at-least-one-selected', 'At least one post type must be selected', (value) => {
            return value.audio || value.video || value.textOnly || value.graphic
        })
    })
    render() {
        return (
            <>
                {/* <NavigationMenu navigation={this.props.navigation} id='1' /> */}
                <StyledWrapperSponsoredPost>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id="sponsoredPost" style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "60px" }}>
                            <img
                            id="sponsoredPost"
                                src={goBack}
                                alt="goBack"
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="navigate"
                                onClick={() => { this.props.handleCommonPath("HostMonetizationTemplate") }}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" data-test-id="deleteApi" onClick ={()=>{this.deleteMonetizationSponsoredPost()}}style={{ cursor : "pointer",marginLeft: "auto", marginRight: "10px" }}>
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" style={{ marginRight: "70px" }} id="sponsoredPost">
                            Save For Later
                        </Box>
                    </Box>
                    <Box className="container">
                        <Box className="subContainer">
                            <Box>
                                <img src={sponsoredPost} alt="sponsoredPost" style={{ width: "100%", height: "163px" }} />
                            </Box>
                            <Box style={{
                                borderRadius: "15px",
                                backgroundColor: "#44403C",
                            }}>
                                <Box className="upperContent">
                                    <Box className="title">Sponsored Posts</Box>
                                </Box>                          
                                
                                <Formik
                                    initialValues={{
                                        sponsoredPost: this.state.sponsoredPost,
                                        typeOfPost: {
                                            audio: this.state.typeOfPost.audio,
                                            video: this.state.typeOfPost.video,
                                            textOnly: this.state.typeOfPost.textOnly,
                                            graphic: this.state.typeOfPost.graphic
                                        }
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={this.validationSchema}
                                    validateOnMount={true}
                                    onSubmit={async(values) => {
                                        this.updateMonitization(values);
                                    }}
                                    data-test-id = "submitBtn"
                                >
                                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Grid
                                                className="fields"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "40%",
                                                    margin: "auto",
                                                    marginTop: "2%"
                                                }}
                                            >
                                                <Box style={{ marginBottom: "1%" }}>
                                                    <label className="label">
                                                        How many sponsored posts would you like to make available for purchase? (Maximum of 500)
                                                    </label>
                                                </Box>

                                                <TextField
                                                    id="outlined-basic"
                                                    name="sponsoredPost"
                                                    value={values.sponsoredPost}
                                                    onChange={handleChange}
                                                    sx={{
                                                        width: "100%",
                                                        marginTop: "2%",
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "#CBD5E1",
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            color: "#CBD5E1",
                                                            backgroundColor: "black",
                                                            "&:-webkit-autofill": {
                                                                backgroundColor: "black !important",
                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                            },
                                                        },
                                                    }}
                                                />
                                            <div className="errors">{Condition(errors , 'sponsoredPost')}</div>
                                            </Grid>
                                            <Grid
                                                className="fields"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "40%",
                                                    margin: "auto",
                                                    marginTop: "2%"
                                                }}
                                            >
                                                <Box style={{ marginBottom: "1%" }}>
                                                    <label className="label">
                                                        What type of post? (Select all that apply)
                                                    </label>
                                                </Box>

                                                <Box style={{ backgroundColor: "#030303", height: "56px", display: "flex", justifyContent: "space-between", paddingRight: "3%", borderRadius: "8px" }}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginLeft: "3%",
                                                            marginRight: "3%",
                                                            cursor: "pointer",
                                                        }}
                                                        data-test-id="typeOfPost.audio"
                                                        onClick={() => setFieldValue("typeOfPost.audio", !values.typeOfPost.audio)}
                                                    >
                                                        <img
                                                            src={ResolveCondition(values.typeOfPost.audio , checkboxTicked , checkbox)}                                                           
                                                            alt="Audio"
                                                            style={{ marginRight: "8px" }}
                                                        />
                                                        <span style={{ fontFamily: "Nexa-Regular", fontWeight: 100, fontSize: "16px" }}>
                                                            Audio
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginLeft: "3%",
                                                            marginRight: "3%",
                                                            cursor: "pointer",
                                                        }}
                                                        data-test-id="typeOfPost.video"
                                                        onClick={() => setFieldValue("typeOfPost.video", !values.typeOfPost.video)}
                                                    >
                                                        <img
                                                            src={ResolveCondition(values.typeOfPost.video, checkboxTicked , checkbox )}
                                                            alt="Video"
                                                            style={{ marginRight: "8px" }}
                                                        />
                                                        <span style={{ fontFamily: "Nexa-Regular", fontWeight: 100, fontSize: "16px" }}>
                                                            Video
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginLeft: "3%",
                                                            marginRight: "3%",
                                                            cursor: "pointer",
                                                        }}
                                                        data-test-id="typeOfPost.textOnly"
                                                        onClick={() => setFieldValue("typeOfPost.textOnly", !values.typeOfPost.textOnly)}
                                                    >
                                                        <img
                                                            src={ResolveCondition(values.typeOfPost.textOnly ,checkboxTicked , checkbox )}
                                                            alt="text"
                                                            style={{ marginRight: "8px" }}
                                                        />
                                                        <span style={{ fontFamily: "Nexa-Regular", fontWeight: 100, fontSize: "16px" }}>
                                                            Text Only
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginLeft: "3%",
                                                            marginRight: "3%",
                                                            cursor: "pointer",
                                                        }}
                                                        data-test-id="typeOfPost.graphic"
                                                        onClick={() => setFieldValue("typeOfPost.graphic", !values.typeOfPost.graphic)}
                                                    >
                                                        <img
                                                            src={ResolveCondition(values.typeOfPost.graphic ,checkboxTicked ,checkbox )}
                                                            alt="graphic"
                                                            style={{ marginRight: "8px" }}
                                                        />
                                                        <span style={{ fontFamily: "Nexa-Regular", fontWeight: 100, fontSize: "16px" }}>
                                                            Graphic
                                                        </span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                           <> {console.log(errors,"err")
                                            }</>
                                            <Button
                                                className={errors && Object.keys(errors)?.length > 0 ? "btn" : "btn buttonColor"}
                                                disabled={errors && Object.keys(errors)?.length > 0 ? true : false}
                                            data-test-id="dataNavigations"
                                            type="submit"
                                           
                                            >Continue</Button>
                                        </Form>
                                    )}
                                </Formik>

                            </Box>
                        </Box>
                    </Box>
                </StyledWrapperSponsoredPost>
            </>

        );
    }

}
const StyledWrapperSponsoredPost = styled("div")({
    background: "black",
    paddingRight: "3%",
    paddingLeft: "calc(6% + 100px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .errors":{
        fontFamily : "Nexa-Heavy",
        color : "red",
      },
    "& .upperBtn": {
        borderRadius: "8px",
        width: "auto",
        color: "white",
        height: "26px",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        marginRight: "26px",
        fontSize: "16px",
        background: "#312F2D",
    },
    "& .label": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 700,
        fontSize: "14px",
    },
    "& .btn": {
        height: "56px",
        margin: "auto",
        cursor: "pointer",
        marginTop: "3%",
        color: "white",
        marginBottom: "2%",
        background: "#B2B3B3",
        width: "18%",
        justifyContent: "center",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        textTransform: "capitalize",
        fontSize: "14px",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
        }
    },
    "& .buttonColor": {
        background: "#FF0807",
        "&:hover": {
            background: "#FF0807",
        }
    },
    "& .mainTitle": {
        marginLeft: "2%",
        margin: "auto",
        marginBottom: "2%",
        height: "40px",
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        alignItems: "center",
        fontWeight: 900,
        display: "flex",
    },
    "& .upperContent": {
        width: "100%",
        marginBottom: "2%",
        marginTop: "4%",
    },      
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontSize: "30px",
        textAlign: "center",
        textTransform: "capitalize",
        fontWeight: 900,
    },
    "& .subContainer": {
        margin: "auto",
        flexDirection: "column",
        display: "flex",
        height: "auto",
        width: "95%",
        borderRadius: "15px",
    },
    "& .container": {
        margin: "auto",
        color: "white",
        height: "auto",
        marginBottom: "3%",
    },   
})
// Customizable Area End
