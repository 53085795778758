// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { image_art, image_business, image_comedy, image_education, image_family, image_fiction, image_film, image_fitness, image_government, image_history, image_lesiure, image_music, image_news, image_religion, image_science, image_society, image_sports, image_stuffs, image_tech, image_truecrime } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { removeStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface CategoriesStructure {
  id: number;
  type: string;
  attributes: {
    "name": string,
    "image": {
        "url": string
    },
    "created_at":string,
    "updated_at": string
  };
}

interface FormDataStructure {
  id: "6",
  type: "email_account",
  attributes: {
    "first_name": "shaivam",
    "last_name": "chouhan",
    "user_name": "Shivam@31",
    "email": "shivam1@gmail.com",
    "full_phone_number": "919877665544",
    "date_of_birth": "2000-06-29",
    "country_code": 91,
    "user_location": "pune",
    "phone_number": 9877665544,
    "activated": true
  }
}
export interface S {
  data: CategoriesStructure[];
  selectedId: number[]
  formStats: FormDataStructure;
  isModalOpen: boolean,
  errors : []
}

export interface SS {
  id: any;
}

export default class EmailRegistrationCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {

  getQuestionStatsCallId: string = "";
  getCategoriesApi: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    
    this.state = {
      data:[],   
      selectedId: [],
      formStats: {
        id: "6",
        type: "email_account",
        attributes: {
          "first_name": "shaivam",
          "last_name": "chouhan",
          "user_name": "Shivam@31",
          "email": "shivam1@gmail.com",
          "full_phone_number": "919877665544",
          "date_of_birth": "2000-06-29",
          "country_code": 91,
          "user_location": "pune",
          "phone_number": 9877665544,
          "activated": true
        },
      },
      isModalOpen: false,
      errors:[]
    }
  }

  async componentDidMount() {
    this.getApi()    
  }

  getApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForGetApi = async (from: string, message: Message) => {
    if (
      this.getCategoriesApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ data: responseJson.data })
      }           
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleResForGetApi(from, message)
    this.handleResForQuestionStats(from, message)
  }

  postApi = async () => {
    const Registration = await getStorageData("Registration", true)
    const SocialLinks = await getStorageData("SocialLinks", true)    
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    
    const httpBody = {
      "data": {
        "attributes": {
          "first_name": Registration.first_name,
          "last_name": Registration.last_name,
          "user_name": Registration.username,
          "full_phone_number": `+${Registration.full_number}`,
          "date_of_birth": [Registration.year,Registration.month,Registration.day].join("-"),
          "user_location": Registration.location,
          "email": Registration.email_address,
          "password": Registration.password,
          "password_confirmation": Registration.confirm_password,
          "gender" : Registration.gender,
          "race" : Registration.race,
          "social_media_url": {
            "facebook": SocialLinks.facebook,
            "instagram": SocialLinks.instagram,
            "x": SocialLinks.x,
            "linkedin": SocialLinks.linkedin
          },
          "podcast_categories_ids": this.state.selectedId
        },
        "type": "email_account"
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionStatsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuestionStatsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForQuestionStats = async (from: string, message: Message) => {
    if (
      this.getQuestionStatsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson.status == 500,"responseJson");
      
      if (!responseJson.errors) {
        if(responseJson.status !== 500){
          this.setState({ formStats: responseJson })
          this.handleOpenModal()
        }
        removeStorageData("Registration")
        removeStorageData("SocialLinks")
      }else{
       this.setState({
        errors: responseJson.errors
       })
      }
    }
  };
  
  handleBoxes = (item: any) => {
    this.setState((prevState) => {
      const itemId = Number(item.id);
      const isSelected = prevState.selectedId.includes(itemId);
        if (isSelected) {
        return { selectedId: prevState.selectedId.filter((id) => id !== itemId) };
      }
      if (prevState.selectedId.length < 5) {
        return { selectedId: [...prevState.selectedId, itemId] };
      }
      return null;
    });
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
};

handleCloseModal = () => {
    this.setState({ isModalOpen: false });
};
  
}
// Customizable Area End
