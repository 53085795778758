import React from 'react'; 
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import BuildController, { Props }  from "./BuildController.web";
import {
    Box,
    styled,
    Button,
    Card,
    Typography,
    Chip
} from "@mui/material";
import {  mediaCardImg } from "./assets";
import ShareIcon from '@mui/icons-material/Share';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SwitchButton from "./components/SwitchButton.web";
import TvIcon from '@mui/icons-material/Tv';
import MediaManagementCard from "./components/MediaManagementCard.web";

const MIN_WINDOW_HEIGHT = 900

export default class MediaManagement extends BuildController {
    constructor(props: Props) {
      super(props);
    }
  
    render() {
      
      return (
        <>
        <StyledWrapper>
        <Typography fontFamily={"Nexa-Heavy"} paddingBottom={2} fontSize={"30px"} color={"white"}>
            Media Management
        </Typography>
        
        <Box>
            <Box
            height="80px"
            bgcolor="red"
            sx={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
            }}
            >
            </Box>

            <Box bgcolor={"#44403C"} color={"white"} padding={"24px 48px 40px 48px"}>
            <Card sx={{ padding: "16px", display: "flex", justifyContent: "space-between", backgroundColor: "#44403C",  marginBottom:"12px", boxShadow: "0px 22px 70px 4px" }}>
                <Box display={"flex"} gap={2}>
                    <img
                    src={mediaCardImg}
                    alt="sponsored Img"
                    style={{ objectFit: "cover",height:"210px", width: "230px", borderRadius:"8px" }}
                    />

                <Box color={"white"}>
                    <Typography fontFamily={"Nexa-Heavy"} fontSize={"1.875rem"}>The True Podcast</Typography>
                    <Typography fontFamily={"Nexa-Heavy"} fontSize={"2.5rem"}>COST OF WINNING</Typography>
                    <Box display={"flex"} gap={2} mb={1}>
                        <Button
                            variant="outlined"
                            startIcon={<ShareIcon />}
                            sx={{
                                ...buttonStyles,
                                backgroundColor: "#312F2D",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: "#312F2D",
                                    color: "white", 
                                    border: "none",
                                },
                            }}
                        >
                            Share
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<TrendingUpIcon />}
                            sx={{
                                ...buttonStyles,
                                background: "black", 
                                color: "white", 
                                "&:hover": {
                                    background: "black", 
                                    color: "white", 
                                    cursor: "pointer",
                                    border:"none"
                                },
                            }}
                        >
                            Analytics
                        </Button>
                    </Box>

                    <Button
                        variant="contained"
                        size="medium"
                        sx={{
                            ...buttonStyles,
                            background: "white", 
                            color: "black", 
                            "&:hover": {
                                background: "white", 
                                color: "black", 
                            },
                        }}
                    >
                        RSS Feed
                    </Button>

                </Box>
                </Box>

                <Box display={"flex"} marginTop={"1rem"} flexDirection={"column"} gap={3}>
                <Button
                    variant="outlined"
                    size="medium"
                    sx={{
                        textTransform:"capitalize",
                        padding: "8px 40px",
                        color: "white",
                        backgroundColor: "red",
                        fontFamily: "Nexa-Heavy",
                        fontSize: "0.875rem",
                        border: "solid red",
                        borderRadius:"8px",
                        "&:hover": {
                        backgroundColor: "red", 
                        color: "white", 
                        border: "solid red", 
                        },
                    }}
                    onClick={this.handleUploadNewContent}
                    >
                    Upload New Content
                </Button>

                
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                    <Box display={"flex"} alignItems={"center"} color={"white"} gap={1}>
                    <OndemandVideoIcon />
                    <Typography fontFamily={"Nexa-Heavy"} fontSize={"1rem"}>View All Episodes</Typography>
                    </Box>
                    <SwitchButton checked={true} onChange={() => {}} />
                </Box>

                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                    <Box display={"flex"} alignItems={"center"} color={"white"} gap={1}>
                    <TvIcon />
                    <Typography fontFamily={"Nexa-Heavy"} fontSize={"1rem"}>View Premium Episodes</Typography>
                    </Box>
                    <SwitchButton checked={true} onChange={() => {}} />
                </Box>
                </Box>
            </Card>

            <MediaManagementCard
                sponsoredImage={mediaCardImg}
                title="The Cost Of Success"
                subTitle="The True"
                episode="Episode 10"
                publishDate="Sep 20, 2024 9pm"
                status="Draft"
                isPremium={false}
                onShare={() => {}}
                onEdit={() => {}}
                onAnalytics={() => {}}
                onDelete={() => {}}
            />

            <MediaManagementCard
                sponsoredImage={mediaCardImg}
                title="The Cost Of Success"
                subTitle="The True"
                episode="Episode 10"
                publishDate="Sep 20, 2024 9pm"
                status="Published"
                isPremium={false}
                onShare={() => {}}
                onEdit={() => {}}
                onAnalytics={() => {}}
                onDelete={() => {}}
            />

            <MediaManagementCard
                sponsoredImage={mediaCardImg}
                title="The Cost Of Success"
                subTitle="The True"
                episode="Episode 10"
                publishDate="Sep 20, 2024 9pm"
                status="Published"
                isPremium={true}
                onShare={() => {}}
                onEdit={() => {}}
                onAnalytics={() => {}}
                onDelete={() => {}}
            />

            </Box>

        </Box>
        </StyledWrapper>

        <Footer navigation={this.props.navigation} id={"1"} />    
        </>
      );
    }
}

const StyledWrapper = styled("div")({
    background: "black",
    padding:"20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
})

     

    const buttonStyles = {
        borderRadius: "8px",
        fontFamily: "Nexa-Heavy",
        fontSize: "0.875rem",
        textTransform: "capitalize",
        width: "150px", 
        padding: "8px 0", 
        border: "none",
        boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", 
        "&:hover": {
            boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
        },
    };