import React, { Component } from 'react';
import {
    Box,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import { box1Img, box2Img, box3Img } from "../assets";


interface ListeningStatsProps {
    totalListeningTime: string;
    mostedListendTime: string;
    averageListeningTime: string;
    onSavedPostBtnClick: () => void;
}

class ListeningStats extends Component<ListeningStatsProps> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {
        const { totalListeningTime, mostedListendTime, averageListeningTime, onSavedPostBtnClick } = this.props;

        return (
            <Box style={{maxWidth:"1440px" , width:"100%"}}>
                <Box width={"100%"} display={"flex"} gap={"50px"} marginTop={"30px"} justifyContent={"center"} marginLeft={"30px"}>
                    <Box style={{width:"100%" ,maxWidth:"300px" , }}>
                        <Box sx={style.listeningCard}>
                            <Box marginBottom={"10px"}>
                                <img src={box1Img} alt="box1Img" height={"90px"} width={"84px"} />
                            </Box>
                            <Typography style={style.listeningCardTime}>{totalListeningTime}</Typography>
                        </Box>
                        <Box >
                            <Typography sx={style.listeningCardText}>Total Listening Time</Typography>
                        </Box>
                    </Box>
                    <Box style={{maxWidth:"300px" , width:"100%"}}>
                        <Box sx={style.listeningCard}>
                            <Box>
                                <img src={box2Img} alt="Box2Img" height={"114px"} width={"114px"} />
                            </Box>
                            <Typography style={style.listeningCardTime}>{mostedListendTime}</Typography>
                        </Box>
                        <Box >
                            <Typography sx={style.listeningCardText}>Most Listened to Podcast</Typography>
                        </Box>
                    </Box>
                    <Box sx={{maxWidth:"300px" , width:"100%"}}>
                        <Box sx={style.listeningCard}>
                            <Box>
                                <img src={box3Img} alt="box3Img" height={"110px"} width={"90px"} />
                            </Box>
                            <Typography style={style.listeningCardTime}>{averageListeningTime}</Typography>
                        </Box>
                        <Box >
                            <Typography sx={style.listeningCardText}>Average Listening Time per Episode</Typography>
                        </Box>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem style={{ color: "white", background: "white" }} />
                    <Box sx={{width:"100%",maxWidth:"300px" }}>
                        <Box sx={style.listeningCard}>
                            <Typography style={style.listeningLastCardTime}>10</Typography>
                        </Box>
                        <Box >
                            <Typography sx={style.listeningCardText}>Number of podcasts i'm following</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginLeft: "50px", marginBottom: "25px" }}>
                    <Button
                        sx={{
                            "&:hover": { background: "#FF0807" },
                            textTransform: "none",
                            height: "57.86px",
                            fontWeight: 900,
                            fontSize: "13.94px",
                            color: "#FFFFFF",
                            background: "#FF0807",
                            padding: "15.93px",
                            borderRadius: "8px",
                            fontFamily: "Nexa-Heavy",
                        }}
                        onClick={onSavedPostBtnClick}
                        >
                        View My Saved Post
                    </Button>
                </Box>
            </Box>
        );
    }
}

const style = {
    listeningCard: {
        borderRadius: "30px",
        maxWidth: "300px",
        width:"100%",
        height: "184px",
        background: "#312F2D",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: "8px"
    },
    listeningCardTime: {
        fontFamily: "Nexa-Heavy",
        color: "#FFFFFF",
        fontSize: "30px",
        fontWeight: 900,
    },
    listeningLastCardTime: {
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        fontSize: "80px",
        color: "#FFFFFF"
    },
    listeningCardText: {
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        fontSize: "18px",
        lineHeight: "26px",
        color: "#FFFFFF",
        textTransform: "Uppercase",
        textAlign: "center",
        textWrap: "wrap",
        margin: '18px 10px'
    },
}

export default ListeningStats;
