// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { FormikHelpers } from 'formik';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface CategoryStructure {
  "id": "8",
  "type": "category",
  "attributes": {
    "name": "science",
    "image": {
      "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
    },
    "created_at": "2024-09-17T07:22:23.376Z",
    "updated_at": "2024-09-17T07:22:23.391Z"
  }
}

export interface SubCategoryStructure {
  "id": "1",
  "type": "sub_category",
  "attributes": {
    "id": 1,
    "name": "Pre Primary (kg)",
    "created_at": "2024-06-26T23:32:49.745Z",
    "updated_at": "2024-06-26T23:32:49.745Z",
    "categories": [],
    "image": null
  }
}

interface Host {
  id : number | string;
  first_name: string;
  last_name: string;
  email_address: string;
  location: string;
  host_bio: string;
}
interface PodcastFormValues {
  podcastCover: string;
  podcastName: string;
  subtitle: string;
  hostFirstName: string;
  hostLastName: string;
  hostEmail: string;
  hostLocation: string;
  hostBio: string;
  podcastLocation: string;
  state: string;
  city: string;
  podcastCategory: string;
  podcastSubCategory: string;
  firstId : string | number
  hosts: Host[];
}
export interface S {
  files: unknown[],
  imageUrl: string,
  categoryList: CategoryStructure[],
  subCategoryList: SubCategoryStructure[],
  selectedCategory: any,
  selectedId: number | null,
  countryDropdown: Array<string>,
  selectedCountry: string,
  statesDropdown: Array<string>,
  invalidFileMessage: string,
  formValues: PodcastFormValues,
  podcastId: string,
  podcastFile: File | null,
  deletedHosts : string[] | number[],
}

export interface SS {
  id: any;
}

export default class HostSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  podcastCategoryApiCallId: string = "";
  podcastSubCategoryApiCallId: string = "";
  countryApiCallId: string = "";
  stateApiCallId: string = "";
  podcastDetailsCallId: string = "";
  hostSettingsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      deletedHosts : [],
      podcastId: "",
      files: [],
      imageUrl: "",
      categoryList: [],
      subCategoryList: [],
      selectedCategory: 1,
      selectedId: null,
      countryDropdown: [],
      selectedCountry: "",
      statesDropdown: [],
      invalidFileMessage: "", 
      formValues: {
        firstId : "",
        podcastCover: '',
        podcastName: '',
        subtitle: '',
        hostFirstName: '',
        hostLastName: '',
        hostEmail: '',
        hostLocation: '',
        hostBio: '',
        podcastLocation: '',
        state: '',
        city: '',
        podcastCategory: '',
        podcastSubCategory: '',
        hosts: [],
      },
      podcastFile: null
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleResForPodcastCategoryApi(from, message);
    this.handleResForPodcastSubCategoryApi(from, message);
    this.handleResForCountryApi(from, message);
    this.handleResForStatesApi(from, message);
    this.handleGetPodcastApi(from, message);
    this.handleUpdateHostSettings(from,message);
  }


  async componentDidMount() {
    this.categoryApi();
    this.subCategoryApi();
    this.countryApi();
    this.getPodcastDetails();
  }
  handleDrop = (event: React.DragEvent<HTMLDivElement>, setFieldValue: any) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFile = (file: File, setFieldValue: any) => {
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (img.width !== 3000 || img.height !== 3000) {
          this.setState({ invalidFileMessage: "Photo is not 3000x3000, please upload new photo of that size to move forward" });
        }
        else {
          this.setState({ invalidFileMessage: "" });
        }
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        img.src = reader.result as string;
        this.setState({ imageUrl: reader.result as string, podcastFile: file });        
      };
      reader.readAsDataURL(file);
    }
  };

  handleBoxClick = (id: number) => {
    this.setState({ selectedId: id });
  };

  categoryApi = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForPodcastCategoryApi = async (from: string, message: Message) => {
    if (
      this.podcastCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ categoryList: responseJson.data })
      }
    }
  };

  subCategoryApi = async () => {
    let token = await getStorageData("token");
    const selectedCategory = this.state.selectedCategory;
    const header = {
      token: JSON.parse(token),
    };
    let apiEndpoint = configJSON.getSubCategoryDropdown;

    if (selectedCategory && selectedCategory.id) {
      apiEndpoint = `${apiEndpoint}?category_id=${selectedCategory.id}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleResForPodcastSubCategoryApi = async (from: string, message: Message) => {
    if (
      this.podcastSubCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ subCategoryList: responseJson.data })
      }
    }
  };
  countryApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForCountryApi = async (from: string, message: Message) => {
    if (
      this.countryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ countryDropdown: responseJson })
      }
    }
  };
  statesApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stateApiEndPoint}/${this.state.selectedCountry}/states`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForStatesApi = async (from: string, message: Message) => {
    if (
      this.stateApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ statesDropdown: responseJson });
      }
    }
  };
  getPodcastDetails = async () => {
    let token = await getStorageData("token")
    let podcast_id = await getStorageData("podcast_id")
    const header = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPodcastApiEndPoint}/${podcast_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetPodcastApi = async (from: string, message: Message) => {
    if (
      this.podcastDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.podcast) {
        let data1 = responseJson.podcast.data.attributes
        
        this.setState(prevState => ({
          formValues: {
            ...prevState.formValues,
            firstId : data1.hosts[0].id,
            podcastId: responseJson.podcast.data.id,
            podcastCover: data1?.cover_image?.image_link,
            podcastName: data1.name,
            subtitle: data1.name,
            hostFirstName: data1.hosts[0].first_name,
            hostLastName: data1.hosts[0].last_name,
            hostEmail: data1.hosts[0].email,
            hostLocation: data1.hosts[0].host_location.toLowerCase(),
            hostBio: data1.hosts[0].bio,
            podcastLocation: data1.location.toLowerCase(),
            state: '',
            city: '',
            podcastCategory: data1.categories.data[0].id,
            podcastSubCategory: data1.sub_categories[0].id,
            hosts: data1.hosts.slice(1, 4).map((host: any) => ({              
              id : host.id,
              first_name: host.first_name,
              last_name: host.last_name,
              email_address: host.email,
              location: host.host_location,
              host_bio: host.bio,
            }))
          },
          selectedId: data1.host_type == 'solo' ? 1 : 2
        }));
      }
    }
  };  

  hostSettingsUpdate = async (values:any) => {
    const token = JSON.parse(await getStorageData("token"));
    const header = { token };
    const formData = new FormData();
  
    if (this.state.podcastFile) {
      formData.append("podcast[cover_image]", this.state.podcastFile);
    }
    formData.append("podcast[name]", values.podcastName);
    formData.append("podcast[description]", values.hostBio);
    formData.append("podcast[location]", values.podcastLocation);
    formData.append("podcast[host_type]", this.state.selectedId === 1 ? "solo" : "co_host");
    formData.append("podcast[category_ids]", values.podcastCategory);
    formData.append("podcast[sub_category_ids]", values.podcastSubCategory);
    formData.append("podcast[hosts_attributes][0][id]", values.firstId);
    formData.append("podcast[hosts_attributes][0][first_name]", values.hostFirstName);
    formData.append("podcast[hosts_attributes][0][last_name]", values.hostLastName);
    formData.append("podcast[hosts_attributes][0][email]", values.hostEmail);
    formData.append("podcast[hosts_attributes][0][host_location]", values.hostLocation);
    formData.append("podcast[hosts_attributes][0][bio]", values.hostBio);
    
    if (this.state.selectedId === 2) {
      values.hosts.forEach((host:any, index:number) => {
        if (host) {
          const position = index + 1; 
          if (host.id) formData.append(`podcast[hosts_attributes][${position}][id]`, host.id);
          formData.append(`podcast[hosts_attributes][${position}][first_name]`, host.first_name);
          formData.append(`podcast[hosts_attributes][${position}][last_name]`, host.last_name);
          formData.append(`podcast[hosts_attributes][${position}][email]`, host.email_address);
          formData.append(`podcast[hosts_attributes][${position}][host_location]`, host.location);
          formData.append(`podcast[hosts_attributes][${position}][bio]`, host.host_bio);
        }
      });
    }
  
    this.state.deletedHosts.forEach((hostId:any, index:any) => {
      const position = values.hosts.length + index + 1;
      formData.append(`podcast[hosts_attributes][${position}][id]`, hostId.toString());
      formData.append(`podcast[hosts_attributes][${position}][_destroy]`, "true");
    });
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.hostSettingsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.updateHostSettings}/${values.podcastId}/update_podcast`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  handleUpdateHostSettings  = async (from: string, message: Message) => {
    if (
      this.hostSettingsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message == "Podcast updated successfully") {
        this.getPodcastDetails();  
        this.setState({imageUrl : ""})
       
      }
    }
  };
  handleSubmit = async (values: PodcastFormValues, { resetForm, setFieldValue }: FormikHelpers<PodcastFormValues>) => {
    this.hostSettingsUpdate(values);    
  }

}
// Customizable Area End
