// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostMonetizationSponsoredContentPartnershipController, {
    Props,
} from "./HostMonetizationSponsoredContentPartnershipController";
import { goBack } from "./assets";


export default class HostMonetizationSponsoredContentPartnership extends HostMonetizationSponsoredContentPartnershipController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <StyledWrapperSponsoredContentPartnership>                   
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id= "sponsoredContent" style={{ display: "flex", alignItems: "center",marginTop:"10px",marginBottom : "10px",marginLeft: "60px" }}>
                            <img
                                src={goBack}
                                alt="goBack"
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="navigate"
                                onClick={() => { this.props.handleCommonPath("HostMonetizationSetup") }}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" style={{ marginLeft: "auto", marginRight: "10px",cursor : "pointer"}}
                        data-test-id="deleteApiSponsoredPost"
                        onClick={() => {this.deleteMonetization()}}
                        >
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" style={{marginRight : "70px"}} id= "sponsoredContent">
                            Save For Later
                        </Box>
                    </Box>
                    <Box className="scrollHostMonetization" id= "sponsoredContent">
                        <Box className="container">
                            <Box className="subContainer" id= "sponsoredContent">
                                <Box className="upperContent">
                                    <Box className="title">Sponsored Content And Partnerships</Box>
                                    <Box className="desc">Select the features for sponsored content that you want to enable on your channel</Box>
                                </Box>
                                <Box className="lowerContent">
                                    <Box className="mainWrapper">
                                        {this.state.responseData.map((item: any, index: any) => (
                                            <Box
                                                key={index}
                                                className="wrapper"
                                                data-test-id="boxSelectedimg"
                                                onClick={() => this.handleItemClick(item)}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    margin: "15px",
                                                    background: "#312F2D",
                                                    borderRadius: "16px",
                                                    width: "auto",
                                                    marginTop: "3%",
                                                    border: this.state.sponsorIdSelected.map(String).includes(item.id) 
                                                        ? '10px solid #FF0807' 
                                                        : 'none',
                                                }}
                                            
                                            >
                                              
                                                <Box
                                                    style={{
                                                        display: "inline-block",
                                                        width: "100%",
                                                        overflow: "hidden",
                                                        height : "250px"
                                                    }}
                                                >
                                                    <img
                                                        src={item.attributes?.feature_image?.url}
                                                        alt={item.attributes.name}
                                                        style={{
                                                            display: "block",
                                                            width: "100%",
                                                            height: "auto",
                                                        }}
                                                    />
                                                </Box>
                                                <p className="itemTitle">{item.attributes.name}</p>
                                            </Box>
                                        ))}
                                    </Box>

                                </Box>
                                    <Button className={this.state.sponsorIdSelected.length > 0 ? "btn btnColor" : "btn"} 
                                    disabled={this.state.sponsorIdSelected.length > 0 ? false : true} 
                                    data-test-id="dataNavigations"
                                    onClick={()=>{
                                        this.updateSponsoredPartnerMonitization()                                      
                                    }}
                                   >Continue</Button>
                            </Box>
                        </Box>
                    </Box>
                </StyledWrapperSponsoredContentPartnership>
            </>

        );
    }

}
const StyledWrapperSponsoredContentPartnership = styled("div")({
    background: "black",
    padding:"20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .upperBtn": {
        width: "auto",
        height: "26px",
        borderRadius: "8px",
        fontWeight: 400,
        color: "white",
        fontSize: "16px",
        fontFamily: "Nexa-Heavy",
        background: "#312F2D",
        marginRight: "26px"
    },
    "& .btn": {
        height: "56px", 
        margin: "auto",
        cursor: "pointer",
        marginTop : "1%",
        marginBottom : "6%",
        color: "white",
        background: "#B2B3B3",
        width: "30%",
        borderRadius: "8px",
        justifyContent: "center",
        display: "flex",
        fontWeight: 900,
        alignItems: "center",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        textTransform: "capitalize",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
          }
    },
    "& .btnColor":{
        background : "#FF0807",
        "&:hover":{
        background : "#FF0807",
        }
    },
    "& .mainTitle": {
        margin: "auto",
        marginLeft: "2%",
        height: "40px",
        marginBottom: "2%",
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        fontWeight: 900,
        alignItems: "center",
        display: "flex",
    },
    "& .mainWrapper": {
        height: "auto",
        width: "47%",
        display: "flex",
        "@media (max-width: 1400px)": {
            width: "62%",
        },
        "@media (max-width: 1000px)": {
            width: "90%",
        },
        "@media (max-width: 800px)": {
            flexDirection: "column",
        }
    },
    "& .itemTitle": {
        width: "87%",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "18px",
        fontFamily: "Nexa-Heavy",
    },
    "& .lowerContent": {
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: "2%",
    },
    "& .scrollHostMonetization": {
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        paddingRight: "40px",
        overflow: "hidden",
        scrollbarWidth: "thin"
    },
    "& .upperContent": {
        width: "100%",
        marginTop: "2%"
    },   
    "& .desc": {
        margin: "auto",
        marginTop: "1%",
        textAlign: "center",
        fontFamily: "Nexa-Regular",
        fontSize: "15px",
    },
    "& .desc1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "30px",
        fontWeight: 900,
        textTransform: "capitalize"
    },
    "& .subContainer": {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        width: "95%",
        height: "auto",
        backgroundColor: "#44403C",
        borderRadius: "15px"
    },
    "& .container": {
        color: "white",
        height: "auto",
        margin: "auto",
        marginBottom: "3%",
    },
    "& .image": {
        display: "flex",
        height: "40%",
        width: "80%",
        paddingTop: "5%",
        boxSizing: "content-box",
        margin: "auto",
    },
})
// Customizable Area End
