import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

interface PlaylistCardProps {
  borderColor?: string;
  imageUrl: string | undefined;
  overlayColor?: string;
  title: string | undefined;
  subtitle?: string;
  icon?: 'play' | 'control';
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void; 
}

class PlaylistCard extends Component<PlaylistCardProps> {
  constructor(props: PlaylistCardProps) {
    super(props);
  }

  renderIcon() {
    const { icon } = this.props;
  
    if (!icon) {
      return null; 
    }
  
    return icon === 'play' ? (
      <PlayCircleOutlineIcon sx={{ color: 'white', fontSize: '32px' }} />
    ) : (
      <ControlPointIcon sx={{ color: 'white', fontSize: '40px' }} />
    );
  }
  

  render(): JSX.Element  {
    const { borderColor, imageUrl, overlayColor, title, subtitle, onClick } = this.props;

    return (
      <Box
       data-test-id={title}
       onClick={onClick}
        sx={{
          height: '204px',
          width: '165px',
          borderBottomRightRadius: '16px',
          borderBottomLeftRadius: '16px',
          border: `1px solid ${borderColor}`,
          position: 'relative',
          overflow: 'hidden',
          backgroundColor:"#44403C"
        }}
      >
        {/* Image Section with Overlay */}
        <Box
        data-test-id="playlist-image-container"
          sx={{
            height: '145px',
            width: '165px',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
          data-test-id="playlist-image"
            height="145px"
            width="165px"
            style={{ backgroundColor: '#FF0807', objectFit: 'cover' }}
            src={imageUrl}
            alt="Card Thumbnail"
          />
          {/* Overlay */}
          <Box
          data-test-id="playlist-overlay"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: overlayColor,
              zIndex: 1,
            }}
          />
          {/* Icon */}
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '50%',
              zIndex: 2,
            }}
          >
            {this.renderIcon()}
          </Box>
        </Box>
        {/* Text Section */}
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={"10px"}
          color={"white"}
        >
          <Typography data-test-id="playlist-title" fontFamily={"Inter"} fontWeight={700} fontSize={'14px'}>{title}</Typography>
          <Typography data-test-id="playlist-subtitle" fontFamily={"Nexa-Regular"} fontWeight={400} fontSize={'14px'}>{subtitle}</Typography>
        </Box>
      </Box>
    );
  }
}

export default PlaylistCard;
