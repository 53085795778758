import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import BuildController, { Props }  from "./BuildController.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { buildBackImg, handImg, podcastImg, womenImg } from "./assets";
import { Box, Typography, styled} from "@mui/material"
import React from 'react'; 
import BuildCard from "./components/BuildCard.web";

const MIN_WINDOW_HEIGHT = 900

export default class Build extends BuildController {
    constructor(props: Props) {
      super(props);
    }
  
    render() {
      
      return (
        <>
        <StyledWrapper>

        <img src={buildBackImg} alt="backgroundImg" width={"100%"} style={{objectFit:"contain", borderRadius:"8px"}} />

        <Box bgcolor={"#44403C"} pb={"3rem"}>
        <Typography data-test-id="title" fontFamily={"Nexa-Heavy"} padding={"32px 0"} fontSize={"30px"} fontWeight={900} lineHeight={"40px"} textAlign={"center"} color={"white"}>Build</Typography> 

        <Box mb={4} display={"flex"} gap={3} alignItems={"center"} justifyContent={"center"}>

         <BuildCard imageUrl={podcastImg} buttonLabel="Channel Management" buttonClick={ () => this.handleChannelManagement()} />
         <BuildCard imageUrl={handImg} buttonLabel="Media Management" buttonClick={ () => this.handleMediaManagement()} />
         <BuildCard imageUrl={womenImg} buttonLabel="Integrations" buttonClick={() => {}} />
        </Box>
      </Box>
      </StyledWrapper>
        <Footer navigation={this.props.navigation} id={"1"} />    

        </>
      );
    }
  }

  

const StyledWrapper = styled("div")({
  padding: "20px 50px",
  background: "black",
  "& *": {
      boxSizing: "border-box",
  },
})