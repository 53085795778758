import React, { Component } from 'react';
import { Box, Dialog, Typography, Button } from '@mui/material';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

interface PlayListModalProps {
  open: boolean;
  onClose: () => void;
  playlistData: {
    imageUrl: string;
    title: string;
    description: string;
    playListData: Array<{
      imageUrl: string;
      title: string;
      description: string;
      duration: string;
      added: boolean;
    }>;
  } | null;
}


class PlayListModal extends Component<PlayListModalProps> {
  render() {
    const { open, onClose, playlistData } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => {}}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(5px)', // Apply blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional semi-transparent background
            },
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "800px",
            width: "730px",
            background: "none !important"
          },
        }}
        data-test-id="PlaylistModal"
      >
        <Box>
          <Box mb={3} borderRadius={1} bgcolor={"#312F2D"} padding={2} display={"flex"} gap={3} alignItems={"center"}>
            <img src={playlistData?.imageUrl} alt='selectedImg' style={{width:"202px", height:"178px", borderRadius:"5px"}} />
            <Box>
            <Typography style={webStyles.title}> {playlistData?.title} </Typography>
            <Typography style={{...webStyles.title, fontSize:"40px", textTransform:"uppercase"}}> {playlistData?.description}</Typography>
            </Box>
          </Box>
          
          <Box mb={2} padding={2} bgcolor={"#44403C"} borderRadius={1} color={"white"} sx={{ maxHeight: "400px", overflowY: "auto" }}>
            <Box mb={3}>
            {playlistData?.playListData.map((data, index) => (
              <Box
                  data-test-id="playlist-item"
                  key={index}
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  borderBottom="1px solid #F5F5F5"
                  padding={2}
              >
                  <Box display="flex" flex={5} gap={1}>
                  <img
                      alt={data.title}
                      src={data.imageUrl}
                      style={{ width: "40px", height: "40px" }}
                  />
                  <Box>
                      <Typography
                      textOverflow={"ellipsis"}
                      fontFamily={"Nexa-Heavy"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      fontSize="16px"
                      fontWeight={900}
                      >
                      {data.title}
                      </Typography>
                      <Typography
                      fontFamily={"Nexa-Regular"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                      fontWeight={400}
                      color={"#F5F5F5"}
                      fontSize="14px"
                      >
                      {data.description}
                      </Typography>
                  </Box>
                  </Box>

                  <Typography
                  flex={1}
                  fontFamily={"Nexa-Regular"}
                  fontWeight={400}
                  fontSize="14px"
                  >
                  {data.duration}
                  </Typography>

                  {
                    data.added ? 
                      <Button
                        data-test-id="AddShowsToMyPlaylist"
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807",
                        width:"none",
                        height:"none",
                        fontSize:"10px",
                        "&:hover": {
                            background: "#FF0807", 
                        }
                        }}
                        variant="contained"
                        startIcon={<PlaylistAddIcon />}
                      >
                        Add To Playlist
                      </Button>
                      : 
                      <Button
                      data-test-id="AddShowsToMyPlaylist"
                      sx={{
                      ...buttonStyles,
                      border: "none",
                      color: "white",
                      background: "#030303",
                      width:"136px",
                      height:"none",
                      fontSize:"10px",
                      "&:hover": {
                          background: "#030303", 
                      }
                      }}
                      variant="contained"
                      startIcon={<PlaylistAddCheckIcon />}
                    >
                      Added
                      </Button> 
                  }

              </Box>
            ))}
            </Box>
            <Box m={2} display={"flex"} gap={2} justifyContent={"flex-end"}>

            <Button
              data-test-id="cancel"
              onClick={onClose}
                sx={{
                ...buttonStyles,
                border: "none",
                color: "white",
                background: "black",
                "&:hover": {
                    background: "black", 
                }
                }}
                variant="contained"
            >
                Cancel
            </Button>

            <Button
                sx={{
                ...buttonStyles,
                border: "none",
                color: "white",
                background: "#FF0807",
                "&:hover": {
                    background: "#FF0807", 
                }
                }}
                variant="contained"
            >
                Done
            </Button>

            </Box>                                
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default PlayListModal;


const webStyles = {
  title: {
    fontWeight: 900,
    fontSize: "30px",
    lineHeight: "40px",
    letter: "-0.5%",
    color: "#fff",
    fontFamily: "Nexa-Heavy"
},
}

const buttonStyles = {
  borderRadius: "8px",
  fontFamily: "Nexa-Heavy",
  fontSize: "1rem",
  textTransform: "capitalize",
  height: "56px",
  width: "120px",
  fontWeight: 900,
};