import React, { Component } from 'react';
import {
    Box,
    Card,
    Typography,
    Avatar,
    IconButton,
    Divider,
    Menu,
    MenuItem,
    ListItemIcon,
    Collapse,
    CardContent,
} from "@mui/material";

import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BlockIcon from '@mui/icons-material/Block';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface FollowedPodcastersPostsProps {
    currentFollowedPostsData: any[];
    expandedFollowedPostsCardId: number[];
    followedPostsAnchorEl: HTMLElement | null;
    handleFollowedPostsCardClick: (id: number) => void;
    handleFollowedPostsMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handleFollowedPostsMenuClose: () => void;
}

class FollowedPodcastersPosts extends Component<FollowedPodcastersPostsProps> {
    constructor(props: FollowedPodcastersPostsProps) {
        super(props);
    }

    render(): JSX.Element {
        const { currentFollowedPostsData, expandedFollowedPostsCardId, followedPostsAnchorEl, handleFollowedPostsCardClick, handleFollowedPostsMenuOpen, handleFollowedPostsMenuClose } = this.props;

        return (
            <Box width={"100%"}>
                {currentFollowedPostsData.map((card: any, index: any) => (
                    <React.Fragment key={card.id}>
                        <Card
                            key={card.id}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: index % 2 === 0 ? "#44403C" : "#312F2D",
                                borderRadius: "20px 20px 0 0",
                                padding: "20px 20px 0 20px",
                                justifyContent: "space-between",
                                overflow: "hidden",
                                cursor: "pointer",
                                position: "relative",
                                boxShadow: "none"
                            }}
                            onClick={() => handleFollowedPostsCardClick(card.id)}
                        >
                            <Box display={"flex"} justifyContent={"space-between"} gap={"20px"}>
                                <Box>
                                    <Avatar
                                        src={card.avatar}
                                        alt={card.name}
                                        sx={{ width: "48px", height: "48px" }}
                                    />
                                </Box>
                                <Box display={"flex"} gap={"15px"} flexDirection={"column"}>
                                    <Typography variant="body1" style={style.typographyText}>
                                        {card.name}
                                    </Typography>
                                    <CardContent sx={{ padding: 0 }}>
                                        <Typography
                                            variant="body2"
                                            color="grey"
                                            fontSize={"14px"}
                                            fontFamily={"Nexa-Regular"}
                                            fontWeight={100}
                                        >
                                            {card.description}
                                        </Typography>
                                    </CardContent>

                                </Box>
                                <Box position={"absolute"} right={0} marginRight={"20px"}>
                                    <IconButton size="small" sx={{ color: "#fff" }}
                                        aria-label="more options"
                                        onClick={(e) => handleFollowedPostsMenuOpen(e)}>

                                        <MoreHorizIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Card>

                        <Collapse
                            in={expandedFollowedPostsCardId.includes(card.id)}
                            timeout="auto"
                            unmountOnExit
                            sx={{ mt: "-30px" }}
                        >
                            <Box
                                sx={{
                                    padding: 2,
                                    backgroundColor: index % 2 === 0 ? "#44403C" : "#312F2D",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}
                            >
                                <Box textAlign={"center"}>
                                    <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1MCJuxcGsjCT21fKnMVPxxdcVMcgU13jIqA&s"
                                        style={{
                                            width: "729.83px",
                                            height: "308px",
                                            borderRadius: "43px",
                                            margin: "15px 0",
                                        }}
                                    />
                                </Box>

                            </Box>
                        </Collapse>
                        <Box
                            sx={{ borderRadius: "0 0 20px 20px", backgroundColor: index % 2 === 0 ? "#44403C" : "#312F2D", padding: "5px 0px 20px 89px" }}
                            marginBottom={"24px"}
                            alignItems={"center"}
                            display={"flex"}
                            style={style.typographyText}
                            gap={"30px"}
                        >
                            <Typography variant="body2" style={style.typographyText}>{card.date}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ThumbUpAltOutlinedIcon sx={{ marginRight: 1 }} />
                                <Typography variant="body2" style={style.typographyText}>
                                    {card.likes}
                                </Typography>
                            </Box>
                            <Box sx={{ alignItems: "center" , display: "flex", }}>
                                <ChatBubbleOutlineOutlinedIcon sx={{ marginRight: 1 }} />
                                <Typography variant="body2" style={style.typographyText}>
                                    {card.comments}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ShareOutlinedIcon sx={{ marginRight: 1 }} />
                                <Typography variant="body2" style={style.typographyText}>
                                    {card.shares}
                                </Typography>
                            </Box>
                        </Box>

                        <Menu
                            anchorEl={followedPostsAnchorEl}
                            open={Boolean(followedPostsAnchorEl)}
                            onClose={handleFollowedPostsMenuClose}
                            sx={{
                                "& .MuiPaper-root": {
                                    backgroundColor: "#44403C",
                                    color: "#FFFFFF",
                                    border: "1px solid #ffffff",
                                    width: "200px",
                                },
                            }}
                        >
                            <Box sx={{ "&:hover": { backgroundColor: "#FF0807", }, marginTop: "-8px" }}>
                                <MenuItem
                                    sx={{ height: "60px" }}
                                    onClick={handleFollowedPostsMenuClose}
                                >
                                    <ListItemIcon>
                                        <VisibilityOffIcon sx={{  fontSize: "36px",color: "#FFFFFF", }} />
                                    </ListItemIcon>
                                    <span style={{ marginLeft: "8px", fontSize: "14px",  }}>Hide</span>
                                </MenuItem>
                            </Box>
                            <Divider sx={{ borderColor: "#ffffff" }} />
                            <Box sx={{ marginBottom: "-8px", "&:hover": { backgroundColor: "#FF0807", }, }}>
                                <MenuItem
                                    sx={{ height: "60px" }}
                                    onClick={handleFollowedPostsMenuClose}
                                >
                                    <ListItemIcon>
                                        <BlockIcon sx={{ color: "#FFFFFF", fontSize: "36px" }} />
                                    </ListItemIcon>
                                    <span style={{ fontSize: "14px", marginLeft: "8px" }}>Block User</span>
                                </MenuItem>
                            </Box>
                        </Menu>

                    </React.Fragment>
                ))}
            </Box>
        );
    }
}


const style = {
    typographyText: {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        color: "#fff",
        fontSize: "14px",
    }
}

export default FollowedPodcastersPosts;
