import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { telephoneImg } from "./assets";

export interface Props {
    navigation: any;
    id: string;
}

interface SS {
    id: any;
}
  
interface S {
    playlistData: Array<{
        ImageUrl: string;
        title: string;
        subtitle: string;
        description: string;
        episodeNo: number;
    }>;
}

// mock data
const dataList = [
    {
      ImageUrl: telephoneImg,
      title: "The Art of Drink",
      subtitle: "Animated Story",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lectus purus, vulputate in nunc ac, mollis laoreet justo. Nunc ornare nisi mollis justo malesuada, a convallis neque ultricies.",
      episodeNo: 1,
    },
    {
      ImageUrl: "https://via.placeholder.com/118",
      title: "Journey to the Unknown",
      subtitle: "Exploration Saga",
      description:
        "This is the second episode of our fascinating series. Join us as we uncover mysteries and embark on adventures.",
      episodeNo: 2,
    },
    {
      ImageUrl: "https://via.placeholder.com/118",
      title: "Space Odyssey",
      subtitle: "Sci-Fi Classic",
      description:
        "An epic journey through the stars. Discover what lies beyond the horizons of our galaxy.",
      episodeNo: 3,
    },
  ];

export default class FullPlayListController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
        playlistData: []
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    // Update state with dataList when the component mounts
    this.setState({ playlistData: dataList });
  }

  handleBack = () => {
    handleNavigation("Playlist", this.props)
  }

}
