// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostMonetizationSetupController, {
    Props,
} from "./HostMonetizationSetupController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { image_number1, image_number10, image_number2, image_number3, image_number4, image_number5, image_number6, image_number7, image_number8, image_number9 } from "./assets";
const relativeImages = [
    image_number1, image_number2, image_number3, image_number4,
    image_number5, image_number6, image_number7, image_number8,
    image_number9, image_number10
];

export default class HostMonetizationSetup extends HostMonetizationSetupController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <StyledWrapperMonetization>
                    <Box className="scrollHostMonetization" id="hostMonetizationSetup">
                        <Box className="container">
                            <Box className="subContainer">
                                <Box className="upperContent">
                                    <Box className="title">Set up monetization</Box>
                                    <Box className="desc">Select the monetization "Categories" below that you want to leverage for your podcast channel. Your channel dashboard and infrastructure will be customized based on the categories you select.</Box>
                                    <Box className="desc1">You can change these at any time but please note that changes may effect any "in-progress" campaigns or services.</Box>
                                </Box>
                                <Box className="lowerContent">
                                    {this.state.data.map((item:any, index:any) => (
                                        <Box key={index} className="wrapper"                                        
                                        data-test-id="boxSelectedimg"
                                        onClick={() => this.handleItemClick(item)}                                                                                
                                        style={{
                                            border: this.state.selectedMonetizationIds.map(String).includes(String(item.id))
                                                ? '5px solid #FF0807' 
                                                : 'none',
                                                borderRadius: '16px'
                                        }}
                                        >
                                            
                                            <Box className="imageContainer">
                                                <img src={item.attributes?.image?.url} alt={item.attributes.name} className="mainImage" />
                                             
                                                <img
                                                            src={relativeImages[index]} 
                                                            alt={`relative_image_${index}`}
                                                            className="relativeImage"
                                                        />
                                            </Box>
                                            <p className="itemTitle">{item.attributes.name}</p>
                                        </Box>
                                    ))}

                                </Box>                                
                                    <Button className={this.state.selectedMonetizationIds.length > 0 ? "btn btnColor" : "btn"} 
                                    disabled={this.state.selectedMonetizationIds.length > 0 ? false : true} 
                                    data-test-id="dataNavigations"
                                    onClick={()=>{this.handleContinue()}}
                                    >Continue</Button>
                            </Box>                            
                        </Box>
                    <Footer navigation={this.props.navigation} id={"1"} />
                    </Box>
                </StyledWrapperMonetization>
            </>

        );
    }

}
const StyledWrapperMonetization = styled("div")({
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .imageContainer": {
        position: "relative",  
        width: "100%",         
        height: "auto",
    },
    "& .mainImage": {
        width: "100%",       
        height: "auto",
    },
    "& .relativeImage": {
        position: "absolute",
        left: "5%",
        top: "10%",
        width: "12%",
        height: "15%",
    },
    "& .wrapper": {
        marginRight: "24px",
        marginTop:"3%",
        width: "282px",
        height: "289px"
    },
    "& .itemTitle": {
        width: "100%",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "14px",
        fontFamily: "Nexa-Heavy",
    },
    "& .lowerContent": {
        marginLeft:"auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent : "center"
    },
    "& .scrollHostMonetization": {
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        paddingRight: "40px",
        overflow: "hidden",
        scrollbarWidth: "thin",
    },
    "& .upperContent": {
        width: "100%"
    },
    "& .btn": {
        height: "56px", 
        cursor: "pointer",
        margin: "auto",
        marginBottom : "3%",
        marginTop : "3%",
        background: "#B2B3B3",
        color: "white",
        borderRadius: "8px",
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 900,
        fontSize: "14px",
        fontFamily: "Nexa-Heavy",
        textTransform: "capitalize",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
          }
    },
    "& .btnColor":{
        background : "#FF0807",
        "&:hover":{
        background : "#FF0807",
        }
    },
    "& .desc": {
        margin: "auto",
        marginTop: "2%",
        textAlign: "center",
        fontFamily: "Nexa-Regular",
        fontSize: "15px",
    },
    "& .desc1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "30px",
        fontWeight: 900,
        // border: "2px solid white",
        textTransform: "capitalize"
    },
    "& .subContainer": {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "auto",
    },
    "& .container": {
        color: "white",
        height: "auto",
        margin: "auto",
        // border: "2px solid white",
        marginBottom: "3%",
    },
    "& .image": {
        display: "flex",
        height: "40%",
        width: "80%",
        paddingTop: "5%",
        boxSizing: "content-box",
        margin: "auto",
    }
})
// Customizable Area End
