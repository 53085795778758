import React from "react";
// Customizable Area Start
import {
    Button,
    Typography,
    styled,
    Box,
    Paper,
    TextField
} from "@mui/material";
import {
    createTheme,
} from "@mui/material/styles";
import { goBack } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { SketchPicker, ColorResult } from 'react-color';
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
// Customizable Area End
import TemplateColorSchemeController, { Props } from "./TemplateColorSchemeController";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class TemplateColorScheme extends TemplateColorSchemeController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const stepLabelMap: Record<number, string> = {
            1: 'Background',
            2: 'Secondary',
            3: 'Text',
            4: 'Button'
        };

        const currentStepLabel = stepLabelMap[this.state.step];
        return (
            // Customizable Area Start
            <>
                <StyledWrapperSetting>
                    <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "96px", fontWeight: "bold", fontFamily: 'Nexa-Heavy' }}>
                        Choose Color Scheme
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '30px',
                            backgroundColor: '#000',
                            height: 'auto',
                            width: "100%",
                            gap: "40px"
                        }}
                    >
                        <Box display="flex" flexDirection="column" style={{ marginLeft: "22%" }}>
                            <Box display="flex" alignItems="center" mb={3}>
                                <img onClick={this.handleGoBack} src={goBack} width={24} height={24} alt="Go Back" data-test-id="go-back" />
                                <Typography variant="subtitle1" sx={{ color: '#fff', fontWeight: 'bold', marginLeft: '16px', fontFamily: 'Nexa-Heavy' }}>
                                    {ResolveCondition(
                                        this.state.step === 4,
                                        "Primary Button Color",
                                        `Choose a ${currentStepLabel} Color`
                                    )}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mt={-1} mb={2} ml={11}>
                                <Box
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '4px',
                                        backgroundColor: this.resolveStepColor(this.state.step, this.state),
                                        border: '1px solid #fff',
                                        margin: "5% 5% 5% -18%"
                                    }}
                                />
                                <TextField
                                    value={ResolveCondition(
                                        this.state.step === 1,
                                        this.state.backgroundColor,
                                        ResolveCondition(
                                            this.state.step === 2,
                                            this.state.secondaryColor,
                                            ResolveCondition(
                                                this.state.step === 3,
                                                this.state.textColor,
                                                this.state.buttonColor
                                            )
                                        )
                                    )}
                                    placeholder="#Hex code here"
                                    onChange={this.handleColorManually}
                                    variant="outlined"
                                    data-test-id="color-input-field"
                                    sx={{
                                        input: {
                                            color: 'white',
                                            backgroundColor: '#030303',
                                            padding: '8px',
                                            fontFamily: 'Nexa-Heavy',
                                            width: '242px',
                                            height: '20px',
                                            borderRadius: '8px'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#777',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#fff',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box ml={12}>
                                <SketchPicker
                                    color={this.resolveStepColor(this.state.step, this.state)}
                                    onChangeComplete={(color: ColorResult) => this.handleColorChange(color)}
                                    disableAlpha={true}
                                    presetColors={[]}
                                    data-test-id="colorpicker"
                                    styles={{
                                        default: {
                                          picker: {
                                            width: '187px',  
                                            height: '200px', 
                                          }
                                        }
                                      }}
                                />
                            </Box>
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{
                                width: '438.8px',
                                height: "486px",
                                backgroundColor: ResolveCondition(this.state.backgroundColor!== "", this.state.backgroundColor, "#44403C"),
                                padding: '2%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderRadius: '10px',
                                position: 'relative',
                                border: "1px solid white",
                                marginRight: "6%"
                            }}
                        >
                            <Box
                                id="secondary-color-box"
                                style={{
                                    backgroundColor: ResolveCondition(this.state.secondaryColor!== "", this.state.secondaryColor, "#B2B3B3"),
                                    padding: '5px',
                                    borderRadius: '8px',
                                    height: '126px',
                                    marginRight: "2%",
                                    marginLeft: '13.5%',
                                    marginTop: '7%'
                                }}
                            >
                                <Box sx={{ display: 'flex' }}>
                                    {ConditionJsx((this.state.secondaryColor!== "" && this.state.step >= 3), <Box
                                        id="color-box-1"
                                        sx={{
                                            width: '5rem',
                                            height: '5rem',
                                            backgroundColor: '#312F2D',
                                            borderRadius: '4px',
                                            marginRight: '10px',
                                            margin: '4% 3% 3% 4%'
                                        }}
                                    ></Box>, <></>)}
                                    <Box sx={{ width: '180px', marginTop: '23px', marginLeft: '32px' }}>
                                    {ConditionJsx((this.state.secondaryColor!== "" && this.state.step >= 3), <><Box id="text-box-1" sx={{ width: '60%', height: '12px', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box>
                                            <Box id="text-box-2" sx={{ width: '60%', height: '12px', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}> </Box>
                                            <Box id="text-box-3" sx={{ width: '60%', height: '12px', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box></>, <></>)}
                                        {ConditionJsx((this.state.textColor!== "" && this.state.step === 4), <Box id="button-box-1" sx={{ width: '40%', height: '25%', backgroundColor: ResolveCondition(this.state.buttonColor!== "", this.state.buttonColor, "transparent"), borderRadius: '10px', border: ResolveCondition(this.state.buttonColor!== "", "none", "1px dashed #030303") }}></Box>, <></>)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', ml: "-2%", marginRight: "2%" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '68%' }}>
                                    <Box
                                        id="layout-1"
                                        sx={{ display: 'flex', mb: 2, backgroundColor: ResolveCondition(this.state.secondaryColor!== "", this.state.secondaryColor, "#B2B3B3"), padding: '5px', borderRadius: '8px', marginTop: '5%', marginLeft: '22%', width: "80%", height: "8rem" }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            {ConditionJsx((this.state.secondaryColor!== "" && this.state.step >= 3), <Box
                                                id="layout1-color-box"
                                                sx={{
                                                    width: '63px',
                                                    height: '60px',
                                                    backgroundColor: '#C78932',
                                                    borderRadius: '8px',
                                                    margin: '6%',
                                                }}
                                            ></Box>, <></>)}
                                            <Box sx={{ width: '180px', marginTop: ResolveCondition((this.state.textColor!== "" && this.state.step === 4), "7%", "20%") }}>
                                                {ConditionJsx((this.state.textColor!== "" && this.state.step === 4), <><Box id="layout1-button-box-1" sx={{ width: '37%', height: '15%', backgroundColor: ResolveCondition(this.state.buttonColor!== "", this.state.buttonColor, "transparent"), mb: '5px', borderRadius: '10px', ml: '25%', border: ResolveCondition(this.state.buttonColor!== "", "none", "1px dashed #030303") }}> </Box>
                                                    <Box id="layout1-button-box-2" sx={{ width: '37%', height: '15%', backgroundColor: ResolveCondition(this.state.buttonColor!== "", this.state.buttonColor, "transparent"), mb: '5px', borderRadius: '10px', ml: '25%', border: ResolveCondition(this.state.buttonColor!== "", "none", "1px dashed #030303") }}></Box></>, <></>)}
                                                {ConditionJsx((this.state.secondaryColor!== "" && this.state.step >= 3), <><Box id="layout1-text-box-1" sx={{ ml: "-4%", width: '65%', height: '8px', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box>
                                                    <Box id="layout1-text-box-2" sx={{ width: '65%', height: '8px', ml: "-4%", backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}> </Box>
                                                    <Box id="layout1-text-box-3" sx={{ width: '65%', ml: "-4%", height: '8px', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor !== "", "none", "1px dashed #030303") }}></Box>
                                                    <Box id="layout1-text-box-4" sx={{ height: '8px', ml: "-4%", width: '65%', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box>
                                                    <Box id="layout1-text-box-5" sx={{ width: '65%', height: '8px', ml: "-4%", mb: '5px', backgroundColor: ResolveCondition(this.state.textColor!== "", this.state.textColor, "transparent"), border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box></>, <></>)}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="layout-2"
                                        sx={{ display: 'flex', backgroundColor: ResolveCondition(this.state.secondaryColor!== "", this.state.secondaryColor, "#B2B3B3"), padding: '5px', borderRadius: '8px', marginTop: '-1%', marginLeft: '22%', width: "80%", height: "8rem" }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            {ConditionJsx((this.state.secondaryColor!== "" && this.state.step >= 3), <Box
                                                id="layout2-color-box"
                                                sx={{
                                                    width: '63px',
                                                    height: '60px',
                                                    backgroundColor: '#C78932',
                                                    borderRadius: '8px',
                                                    margin: '6%',
                                                }}
                                            ></Box>, <></>)}
                                            <Box sx={{ width: '180px', marginTop: ResolveCondition((this.state.textColor!== "" && this.state.step === 4), "7%", "20%") }}>
                                                {ConditionJsx((this.state.textColor!== "" && this.state.step === 4), <><Box id="layout2-button-box-1" sx={{ width: '37%', height: '15%', backgroundColor: this.state.buttonColor, mb: '5px', ml: '25%', borderRadius: '10px', border: ResolveCondition(this.state.buttonColor!== "", "none", "1px dashed #030303") }}></Box>
                                                    <Box id="layout2-button-box-2" sx={{ width: '37%', height: '15%', backgroundColor: this.state.buttonColor, mb: '5px', borderRadius: '10px', ml: '25%', border: ResolveCondition(this.state.buttonColor!== "", "none", "1px dashed #030303") }}></Box></>, <></>)}
                                                {ConditionJsx((this.state.secondaryColor!== "" && this.state.step >= 3), <><Box id="layout2-text-box-1" sx={{ width: '65%', height: '8px', ml: "-4%", mb: '5px', backgroundColor: this.state.textColor, border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box>
                                                    <Box id="layout2-text-box-2" sx={{ width: '65%', ml: "-4%", height: '8px', backgroundColor: this.state.textColor, mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box>
                                                    <Box id="layout2-text-box-3" sx={{ width: '65%', height: '8px', ml: "-4%", backgroundColor: this.state.textColor, mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}> </Box>
                                                    <Box id="layout2-text-box-4" sx={{ ml: "-4%", width: '65%', height: '8px', backgroundColor: this.state.textColor, mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}> </Box>
                                                    <Box id="layout2-text-box-5" sx={{ width: '65%',ml: "-4%", height: '8px', backgroundColor: this.state.textColor, mb: '5px', border: ResolveCondition(this.state.textColor!== "", "none", "1px dashed #030303") }}></Box></>, <></>)}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    id="side-panel"
                                    style={{
                                        width: "28%",
                                        height: "95.5%",
                                        backgroundColor: ResolveCondition(this.state.secondaryColor!== "", this.state.secondaryColor, "#B2B3B3"),
                                        borderRadius: '8px',
                                        marginTop: '3%'
                                    }}
                                ></Box>
                            </Box>
                            <Box
                                id="circle-box-1"
                                sx={{
                                    position: 'absolute',
                                    top: '15px',
                                    left: '27px',
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: '#FF0807',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-1"
                                sx={{
                                    position: 'absolute',
                                    top: '65px',
                                    left: '27px',
                                    width: '35px',
                                    height: '35px',
                                    backgroundColor: '#312F2D',
                                    borderRadius: '50%',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-2"
                                sx={{
                                    position: 'absolute',
                                    top: '125px',
                                    left: '35px',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-3"

                                sx={{
                                    position: 'absolute',
                                    left: '35px',
                                    top: '157px',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-4"

                                sx={{
                                    position: 'absolute',
                                    top: '187px',
                                    left: '35px',
                                    height: '25px',
                                    width: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-5"

                                sx={{
                                    top: '216px',
                                    left: '35px',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                    position: 'absolute',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-6"
                                sx={{
                                    left: '35px',
                                    top: '247px',
                                    position: 'absolute',
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-7"
                                sx={{
                                    backgroundColor: '#312F2D',
                                    position: 'absolute',
                                    top: '15px',
                                    left: '120px',
                                    width: '30px',
                                    height: '15px',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-8"
                                sx={{
                                    position: 'absolute',
                                    width: '30px',
                                    height: '15px',
                                    top: '15px',
                                    left: '160px',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-9"
                                sx={{
                                    position: 'absolute',
                                    top: '15px',
                                    left: '200px',
                                    backgroundColor: '#312F2D',
                                    height: '15px',
                                    width: '30px',
                                }}
                            ></Box>
                            <Box
                                id="rey-circle-box-10"
                                sx={{
                                    position: 'absolute',
                                    top: '15px',
                                    left: '240px',
                                    backgroundColor: '#312F2D',
                                    width: '90px',
                                    height: '15px',
                                }}
                            ></Box>
                            <Box
                                id="grey-circle-box-11"
                                sx={{
                                    top: '15px',
                                    left: '340px',
                                    width: '30px',
                                    height: '15px',
                                    position: 'absolute',
                                    backgroundColor: '#312F2D',
                                }}
                            ></Box>
                        </Paper>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: "3.5%",
                            width: "108%",
                            marginBottom: "5%"
                        }}
                    >
                        <Button
                            variant="contained"
                            className="continueButton"
                            onClick={this.handleContinue}
                            disabled={this.isButtonDisabled()}
                            data-test-id="continueBtn"
                        >
                            Continue
                        </Button>
                    </Box>
                </StyledWrapperSetting>
                <Footer navigation={this.props.navigation} id={"1"} />
            </>
            //Merge Engine End DefaultContainer
            // Customizable Area Start
        );
    }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
    // margin: "0 auto",
    padding:"0px 50px",
    height: "auto",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .continueButton": {
        backgroundColor: '#FF0807',
        color: '#fff',
        textTransform: 'capitalize',
        width: '22%',
        height: '3rem',
        borderRadius: '8px',
        fontFamily: 'Nexa-Heavy',
        "&:disabled": {
            backgroundColor: "#B2B3B3"
        },
        "&:hover": {
            backgroundColor: '#FF0807',
            borderColor: '#FF0807'
        },
        "&.Mui-disabled": {
            backgroundColor: "#B2B3B3"
        }
    }
});

// Customizable Area End
