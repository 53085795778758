// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation, ResolveCondition } from "../../utilities/src/CustomHelpers";
import moment from 'moment-timezone';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  selectedId: number | null,
}

export interface SS {
  id: any;
}

interface PodcastSecondScreenFormData {
  formattedShow: string,
  explicitLanguage: string,
  restriction: string,
  timezone: string,
  publishTime: string
}
export default class SetUpProfileSecondScreenControllerNoRss extends BlockComponent<
  Props,
  S,
  SS
> {

  abbrs: Set<string> = new Set();
  abbreviationsArray: string[] = [];
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      selectedId: null,
    };

    const allTimezones = moment.tz.names();
    allTimezones.forEach((zone) => {
      const offsetInMinutes = moment.tz(zone).utcOffset(); 
    const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60); 
    const offsetMinutes = Math.abs(offsetInMinutes) % 60; 
    const sign = offsetInMinutes >= 0 ? "+" : "-"; 
    const formattedOffset = `UTC(${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')})`;

    const parts = zone.split('/');
    const region = parts[0]; 
    const city = parts[1]?.replace(/_/g, ' ') || ''; 
    
    let timezone =  `${formattedOffset} ${city}, ${region}`.trim();
      this.abbrs.add(timezone);
    });
    this.abbreviationsArray = [...this.abbrs];
    this.abbreviationsArray.sort((a, b) => {
      const parseUtcOffset = (timezone: any) => {
        const match = timezone.match(/UTC\(([-+]\d{2}:\d{2})\)/);
        if (!match) return 0; 
        const [hours, minutes] = match[1].split(":").map(Number);
        const sign = match[1].startsWith("-") ? -1 : 1;
        return sign * (hours * 60 + minutes); 
      };
    
      const offsetA = parseUtcOffset(a);
      const offsetB = parseUtcOffset(b);
      return offsetA - offsetB; 
    });
  }

  handleBoxClick = (id: number) => {
    this.setState({ selectedId: id }); // update selected box ID
  };
  handleSubmitSecondScreen = async (values: PodcastSecondScreenFormData) => {
    let storageData = await getStorageData('noRssData');
    let noRssData = storageData ? JSON.parse(storageData) : {};
    noRssData = { ...noRssData, ...values };
    noRssData['showHosting'] = ResolveCondition((this.state.selectedId === 1), 'solo', ResolveCondition((this.state.selectedId === 2), 'co_host', ''));
    setStorageData("noRssData", JSON.stringify(noRssData));
    setStorageData("feedName", 'noRss');
    if (this.state.selectedId === 2) {
      try {
        this.props.handleCommonPath("SetUpProfileThirdScreenNoRss");
      } catch (error) {
        console.error("Error navigating:", error);
      }
    }
    else {
      try {
        this.props.handleCommonPath("ChooseTemplate");
      } catch (error) {
        console.error("Error navigating:", error);
      }
    }
  }

}
// Customizable Area End
