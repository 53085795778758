// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Grid, Button, TextField,RadioGroup,Radio,FormControlLabel
} from "@mui/material";
import NewContentController, {
  Props,
} from "./NewContentController.web";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import CloseIcon from '@mui/icons-material/Close';
import { upload } from "./assets";
import AudiotrackIcon from '@mui/icons-material/Audiotrack'; // For audio files (mp3)
import MovieIcon from '@mui/icons-material/Movie'; // For video files (mp4)

const MIN_WINDOW_HEIGHT = 900


export default class NewContentTitle extends NewContentController {
  constructor(props: Props) {
    super(props);
  }
  validationSchema = Yup.object().shape({
    podcastCover: Yup.mixed()
    .required('Podcast cover is required'),
    episodeTitle: Yup.string().required('Episode title is required'),
    description: Yup.string().required('Episode show notes are required'),
    episodeType: Yup.string().required('Please select an episode type'),
});



  render() {
    return (
      <>
          <StyleWrapperSetupPodcastProfile>
            <Box className="mainTitle">New Content</Box>
            <Box >
              <Box className="redContainer"></Box>
              <Grid className="container">
                <Grid className="subContainer">
                  <Formik
                    initialValues={{
                      podcastCover: '',
                      episodeTitle: '',
                      description: '',
                      seasonNumber: '',
                      episodeNumber: "",
                      episodeType: '',
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleSubmit(values,"NewContentAdds")
                    }}
                  >
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                      <Form onSubmit={handleSubmit}>
                        <Grid className="fields">
                          <Box
                            data-test-id="drop"
                            onDrop={(e) => { this.handleDrop(e, setFieldValue); }}
                            onDragOver={(e) => { e.preventDefault(); }}
                            sx={{
                              position: 'relative',
                              margin: "auto",
                              marginTop: "2%",
                              marginBottom: "2%",
                              width: "956px",
                              height: "247px",
                              border: `2px dashed ${this.state.fileerror ? 'red' : '#ccc'}`,
                              borderRadius: '10px',
                              padding: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              backgroundColor: 'transparent',
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              style={{ display: 'none' }}
                              id="file-input"
                              data-test-id="selectFile"
                              onChange={(e) => { this.handleFileSelect(e, setFieldValue); }}
                            />
                            {this.state.selectedFile && (
                              <div style={{
                              }}>
                                <div
                                  style={{
                                    width: '100%',
                                    height: "50%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    display: "flex",
                                    maxWidth: '450px',
                                    borderRadius: '8px',
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: ' translate(-50%, -50%)',
                                    backgroundColor: "#030303",
                                    padding: '10px',
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    {this.state.selectedFile.name.endsWith('.mp3') ? (
                                      <AudiotrackIcon style={{ color: 'red' }} />
                                    ) : (
                                      <MovieIcon style={{ color: 'blue' }} />
                                    )}
                                    <span
                                      style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        color: 'white',
                                      }}
                                    >
                                      {this.getShortFileName(this.state.selectedFile.name)}
                                    </span>
                                  </div>

                                  {/* Close Button */}
                                  <CloseIcon
                                      data-test-id="clearFileSelection"
                                    onClick={() => this.clearFileSelection(setFieldValue)}
                                    sx={{
                                      color: 'white',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            <label htmlFor="file-input">
                              <Box sx={{ marginTop: "5%", lineHeight: 2 }}>
                                {!this.state.imageUrl ? (
                                  <>
                                    <img src={upload} alt="upload" />
                                    <Box className="title">
                                      Drag & Drop Your audio/video file <br />OR
                                    </Box>
                                    <Button className="btnBrowse" component="span">
                                      Browse file
                                    </Button>

                                    {this.state.fileerror && <div style={{
                            color: "red",
                            fontFamily: "Nexa-Heavy",
                            fontWeight: 400,
                            fontSize: "12px",
                            marginBottom: "15px",
                            padding:"10px"
                          }}>We only accept MP3 and MP4 files. Please upload a new file</div>}
                                  </>
                                ) : null}
                              </Box>
                            </label>
                          </Box>
                          <Box>
                            <label className="label">Episode Title<span>*</span></label>
                          </Box>
                          <TextField
                            id="outlined-basic"
                            name="episodeTitle"
                            value={values.episodeTitle}
                            onChange={handleChange}
                            sx={{
                              width: "954px",
                              marginTop: "2%",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#CBD5E1",
                              },
                              "& .MuiInputBase-input": {
                                color: "#CBD5E1",
                                backgroundColor: "black",
                                "&:-webkit-autofill": {
                                  backgroundColor: "black !important",
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#CBD5E1 !important",
                                },
                              },
                            }}
                          />
                          {errors.episodeTitle && <div style={{
                            color: "red",
                            fontFamily: "Nexa-Heavy",
                            fontWeight: 400,
                            fontSize: "12px",
                            marginTop: "6px"
                          }}>{errors.episodeTitle}</div>}

                          <Box style={{ marginTop: "3%" }}>
                            <label className="label">Episode Show Notes<span>*</span></label>
                          </Box>
                          <TextField
                            id="outlined-basic"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            multiline
                            sx={{
                              width: "100%",
                              height: "177px",
                              marginTop: "2%",
                              "& .MuiOutlinedInput-root": {
                                height: "177px",
                                backgroundColor: "black",
                                boxSizing: "border-box",
                                alignItems: "flex-start",
                                padding: "0",
                                "& fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                              },
                              "& .MuiInputBase-root": {
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                              },
                              "& .MuiInputBase-input": {
                                color: "#CBD5E1",
                                padding: "10px",
                                lineHeight: "1.5",
                                height: "100%",
                                boxSizing: "border-box",
                                maxHeight: "177px",
                                overflowY: "auto",
                                display: "block",
                                "&:-webkit-autofill": {
                                  backgroundColor: "black !important",
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#CBD5E1 !important",
                                },
                              },
                            }}
                          />
                          {errors.description && <div style={{
                            color: "red",
                            fontFamily: "Nexa-Heavy",
                            fontWeight: 400,
                            fontSize: "12px",
                            marginTop: "6px"
                          }}>{errors.description}</div>}

                          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <Box sx={{ width: "39%", marginTop: "3%", height: "1.4375em" }}>
                              <label className="label">Episode Type<span>*</span></label>
                              <RadioGroup
                                id="outlined-basic"
                                row
                                aria-labelledby="radio-buttons-group-label"
                                name="episodeType"
                                value={values.episodeType}
                                onChange={handleChange}
                                sx={{ marginTop: "2%", background: "black", gap: "40px", paddingLeft: '18px', borderRadius: "5px", alignItems: "center" }}
                              >
                                <FormControlLabel
                                  value="normal"
                                  control={<Radio sx={{
                                    margin: "0px",
                                    "&:before": {
                                      content: '""',
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                      border: "2px solid #CBD5E1",
                                      background: "black",

                                    },
                                    "&.Mui-checked:before": {
                                      content: '""',
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                      border: "3px solid red",
                                      backgroundColor: "white",

                                    },
                                    "& .css-hyxlzm": { display: "none" }

                                  }} />}
                                  label="Normal"
                                  sx={{ color: "#CBD5E1", paddingLeft: '5px', alignItems: "center", fontFamily: "Nexa-Regular", marginRight: "0px", height: "55px" }}
                                  componentsProps={{
                                    typography: {
                                      sx: {
                                        paddingTop: '3px',
                                      },
                                    },
                                  }}
                                />
                                <FormControlLabel
                                  value="trailer"
                                  control={<Radio sx={{
                                    "&:before": {
                                      content: '""',
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                      border: "2px solid #CBD5E1",
                                      background: "black",

                                    },
                                    "&.Mui-checked:before": {
                                      content: '""',
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                      border: "3px solid red",
                                      backgroundColor: "white",
                                    }, "& .css-hyxlzm": { display: "none" }
                                  }} />}
                                  label="Trailer"
                                  sx={{ color: "#CBD5E1", padding: '5px', alignItems: "center", fontFamily: "Nexa-Regular" }}
                                  componentsProps={{
                                    typography: {
                                      sx: {
                                        paddingTop: '3px',
                                      },
                                    },
                                  }}
                                />
                                <FormControlLabel
                                  value="bonus"
                                  control={<Radio sx={{
                                    "&:before": {
                                      content: '""',
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                      border: "2px solid #CBD5E1",
                                      background: "black",

                                    },
                                    "&.Mui-checked:before": {
                                      content: '""',
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                      border: "3px solid red",
                                      backgroundColor: "white",

                                    }, "& .css-hyxlzm": { display: "none" }
                                  }} />}
                                  label="Bonus"
                                  sx={{ color: "#CBD5E1", paddingRight: '5px', alignItems: "center", fontFamily: "Nexa-Regular" }}
                                  componentsProps={{
                                    typography: {
                                      sx: {
                                        paddingTop: '3px',
                                      },
                                    },
                                  }}
                                />
                              </RadioGroup>
                              {errors.episodeType && <div style={{
                                color: "red",
                                fontFamily: "Nexa-Heavy",
                                fontWeight: 400,
                                fontSize: "12px",
                                marginTop: "6px"
                              }}>{errors.episodeType}</div>}

                            </Box>

                            <Box sx={{
                              width: "25%",
                              marginTop: "3%"
                            }}>
                              <label className="label">Season Number</label>
                              <TextField
                                id="outlined-basic"
                                name="seasonNumber"
                                value={values.seasonNumber}
                                onChange={handleChange}
                                sx={{
                                  marginTop: "2%",
                                  width: "100%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "black",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },

                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    height: "56px",
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                }}
                              />
                            </Box>
                            <Box sx={{
                              width: "25%",
                              marginTop: "3%"
                            }}>
                              <label className="label">Episode Number</label>
                              <TextField
                                id="outlined-basic"
                                name="episodeNumber"
                                value={values.episodeNumber}
                                onChange={handleChange}
                                sx={{
                                  marginTop: "2%",
                                  width: "100%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiInputBase-input": {
                                    backgroundColor: "black",
                                    color: "#CBD5E1",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                    },
                                  },

                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    height: "56px",
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                          <Grid className="fields">
                            <Grid item xs={6}>
                              <Button
                                style={{ color: "white", textTransform: "capitalize" }}
                                data-test-id="submit-btn"
                                className={ResolveCondition(Object.keys(errors || {}).length > 0, "btnSignUp", "btnSelectedSignUp")}
                                type="submit"
                                onClick={() => console.log(values, "diya")}
                              >
                                Continue
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Box>
          </StyleWrapperSetupPodcastProfile>
          <Footer navigation={this.props.navigation} id={"1"} />
      </>

    );
  }

}
const StyleWrapperSetupPodcastProfile = styled('div')({
  height: "auto",
  padding:"20px 50px",
  background: "black",
  "& .subContain": {   
    position: "relative",
    paddingRight: "40px", 
    height: "47rem",
    overflow: "hidden",     
    scrollbarWidth: "thin", 
    overflowY: "scroll",
    scrollbarColor: "#FF0807 #4D4D4D",
  },
  "& .btnSignUp": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "6%",
    background: "#B2B3B3",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "5%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "40%",
    fontSize: "14px",
  },
  "& *": {
    boxSizing: "border-box",
  },
  "& .field": {
    width: "954px",
    height: "56px",
  },

  "& .btnSelectedSignUp": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "6%",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "5%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "40%",
    fontSize: "14px",
    background: "#FF0807",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .title": {
    color: "white",
    fontFamily: "Nexa-Heavy",
    textTransform: "capitalize",
    paddingTop: "1%"
  },
  "& .btnBrowse": {
    height: "26px",
    width: "110px",
    fontFamily: "Nexa-Heavy",
    background: "#FF0807",
    color: "white",
    textTransform: "capitalize",
  },
  "& .subContainer": {
    display: "flex",
    justifyContent: "center",
    height: "auto",
    width: "64%",
    marginTop: "28px",
  },
  "& .redContainer": {
    height: "80px",
    margin: 0,
    borderRadius: "0px 16px 0px 0px",
    backgroundColor: "#FF0807",    
  },
  "& .label": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
  },
  "& .mainTitle": {
    margin: "auto",
    height: "40px",
    color: "white",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "30px",
    marginBottom : "21px"
  },
  "& .container": {
    height: "auto",
    backgroundColor: "#312F2D",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItens: "center",
    margin: "auto",
    borderRadius: "0px 0px 8px 8px"
  },
})

// Customizable Area End
