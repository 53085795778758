// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailNotifications: {
    notifications: boolean,
    likes: boolean,
    comments: boolean,
    mentions: boolean,
    shares: boolean,
    channel: boolean,
    community: boolean,
    recomendations: boolean
  },
  inAppNotifications: {
    notifications: boolean,
    likes: boolean,
    comments: boolean,
    mentions: boolean,
    shares: boolean,
    channel: boolean,
    community: boolean,
    recomendations: boolean
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface Form {
  resetPassword: string,
  confirmPassword: string
}

export default class SettingsNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      emailNotifications: {
        notifications: false,
        likes: false,
        comments: false,
        mentions: false,
        shares: false,
        channel: false,
        community: false,
        recomendations: false
      },
      inAppNotifications: {
        notifications: false,
        likes: false,
        comments: false,
        mentions: false,
        shares: false,
        channel: false,
        community: false,
        recomendations: false
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleGoBack = () => {
    this.props.handleCommonPath("Dashboard")
  }
  // Customizable Area End
}
// Customizable Area End
