// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userId: number | null;
  userEmail: string;
  firstName: string;
  lastName: string;
  password: string;
  bio: string;
  dob: string;
  location: string;
  gender: string;
  race: string;
  socialMedia: {
    facebook: string;
    linkedIn: string;
    instagram: string;
    x: string;
  };
  favoritePodcasts: string[];
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  profilePicture: string;
  initialProfilePicture: string;
  profilePictureFile: File | null;
  previousProfilePictureFile: File | null;
  categoryList: CategoryStructure[];
  categories: CategoryStructure[];
  profileUpdated: boolean;
  isSaved: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface CategoryStructure {
  "id": "8",
  "type": "category",
  "attributes": {
    "name": "science",
    "image": {
      "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
    },
    "created_at": "2024-09-17T07:22:23.376Z",
    "updated_at": "2024-09-17T07:22:23.391Z"
  }
}

export interface AccountInfo {
  emailAddress: string,
  bio: string,
  firstName: string,
  lastName: string,
  dob: string,
  location: string,
  gender: string,
  race: string,
  facebook: string,
  linkedIn: string,
  x: string,
  otherOptions: string,
  favouritePodcastCategories: string[]
}

export default class AccountSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public fileInputRef: React.RefObject<HTMLInputElement>;
  accountsApiCallId: string = "";
  podcastCategoryApiCallId: string = "";
  editProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.fileInputRef = React.createRef<HTMLInputElement>();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userId: null,
      userEmail: "",
      firstName: "",
      lastName: "",
      password: "",
      bio: "",
      dob: "",
      location: "",
      gender: "",
      race: "",
      socialMedia: {
        facebook: "",
        linkedIn: "",
        instagram: "",
        x: ""
      },
      favoritePodcasts: [],
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      profilePicture: "",
      initialProfilePicture: "",
      profilePictureFile: null,
      previousProfilePictureFile: null,
      categoryList: [],
      categories: [],
      profileUpdated: false,
      isSaved: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getAccountsApi();
    this.categoryApi();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleAccountsApi(from, message);
    const favPodcast = this.state.favoritePodcasts;
    this.handleResForPodcastCategoryApi(from, message, favPodcast);
    this.handleEditProfileApi(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.userEmail);
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }))
  }
  handleGoBack = () => {
    this.props.handleCommonPath("Dashboard");
  }
  handleAvatarClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({previousProfilePictureFile: this.state.profilePictureFile})
    const file = event.target.files?.[0];
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ profilePicture: imageUrl, profilePictureFile: file, profileUpdated: true })
    }
  };
  formatDateString = (dateString: string): string => {
    const [year, month, day] = dateString.split('-');
    const dateObj = new Date(Number(year), Number(month) - 1, Number(day));
    const monthName = dateObj.toLocaleString('default', { month: 'long' });
    return `${monthName} - ${day} - ${year}`;
  };

  getAccountsApi = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountsApiType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleAccountsApi = async (from: string, message: Message) => {
    if (this.accountsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.data) {
          let data = responseJson.data;
          this.setState({
            userId: data.id,
            userEmail: data.attributes.email,
            firstName: data.attributes.first_name,
            lastName: data.attributes.last_name,
            bio: data.attributes.bio,
            location: data.attributes.user_location,
            profilePicture: data.attributes.profile_picture,
            initialProfilePicture: data.attributes.profile_picture,
            dob: data.attributes.date_of_birth,
            gender: data.attributes.gender,
            race: data.attributes.race,
            socialMedia: {
              facebook: data.attributes.social_media_url.facebook,
              linkedIn: data.attributes.social_media_url.linkedin,
              instagram: data.attributes.social_media_url.instagram,
              x: data.attributes.social_media_url.x,
            },
            favoritePodcasts: data.attributes.podcast_categories_ids 
          });
        }
      } catch (error) {
        console.error('Error handling GET API response1:', error);
      }
    }
  };
  categoryApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForPodcastCategoryApi = async (from: string, message: Message, favPodcast: string[]) => {
    if (
      this.podcastCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ categories: await responseJson.data })
        const categoryList = favPodcast.reduce((categorieslist: any[], currentCategory: string) => {
          const filteredData = responseJson.data.filter(
            (item: CategoryStructure) => item.attributes.name === currentCategory
          );
          return categorieslist.concat(filteredData);
        }, []);
        if (categoryList) {
          this.setState({ categoryList });
        }
      }
    }
  };

  editProfileApi = async (values: AccountInfo) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token)
    };

    let body = new FormData();
    this.appendIfNotEmpty('data[attributes][first_name]', values.firstName, body);
    this.appendIfNotEmpty('data[attributes][last_name]', values.lastName, body);
    this.appendIfNotEmpty('data[attributes][email]', values.emailAddress, body);
    this.appendIfNotEmpty('data[attributes][user_location]', values.location, body);
    this.appendIfNotEmpty('data[attributes][social_media_url][facebook]', values.facebook, body);
    this.appendIfNotEmpty('data[attributes][social_media_url][instagram]', values.otherOptions, body);
    this.appendIfNotEmpty('data[attributes][social_media_url][x]', values.x, body);
    this.appendIfNotEmpty('data[attributes][social_media_url][linkedin]', values.linkedIn, body);
    this.appendIfNotEmpty('data[attributes][bio]', values.bio, body);
    this.appendIfNotEmpty('data[attributes][gender]', values.gender, body);
    this.appendIfNotEmpty('data[attributes][race]', values.race, body);
    if (this.state.profilePictureFile) {
      body.append('data[attributes][profile_picture]', this.state.profilePictureFile);
    }

    if (Array.isArray(values.favouritePodcastCategories) && values.favouritePodcastCategories.length > 0) {
      const matchedIds = values.favouritePodcastCategories
        .map((name) => {
          const matchedCategory = this.state.categories.find((category) => category.attributes.name === name);
          return matchedCategory ? matchedCategory.id : null;
        })
        .filter((id) => id !== null);

      matchedIds.forEach((id) => {
        if (id !== null) {
          body.append('data[attributes][podcast_categories_ids][]', id);
          body.append('data[attributes][favorite_podcast_categories][]', id);
        }
      });
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editProfileEndpoint}/${this.state.userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileApiType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleEditProfileApi = async (from: string, message: Message) => {
    if (this.editProfileApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.meta) {
          this.setState({ profileUpdated: false, isSaved: true });
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };

  handleFormChange = () => {
    this.setState({ profileUpdated: true });
  };

  appendIfNotEmpty = (key: string, value: string | undefined, body: FormData) => {
  if (value && value.trim() !== "") {
    body.append(key, value);
  }
};

handleEditAccountSubmit = (valuesData: AccountInfo) => {
  this.editProfileApi(valuesData);
}
resetFormChanges = () => {
  this.setState({ 
    profilePicture: this.state.initialProfilePicture, 
    profileUpdated: false,
    profilePictureFile: this.state.previousProfilePictureFile
  });
};
  // Customizable Area End
}
// Customizable Area End
